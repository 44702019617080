<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerTableSA.vue
Description: This file contains the list of the Account Manager associated with the company and add new Account by the access of the superadmin
-->
<template>
  <div class="log-table">
    <layout-toolbar margin-bottom="10px">
    <er-column-selector
      :columns="computedColumns"
      keyToStoreDefaults="activity_log_filters"
      keyToDecideDefaultCol="required"
      :allow-check="true"
      :title="'Column Visibility'"
      @change-selection="handleChangeColumnSelection"
    ></er-column-selector>
    <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcel"
          class="height-auto"
    ></er-download-drop-down>
  </layout-toolbar>
    <ag-grid-vue
      v-loading="getTableLoadingStatus"
      ref="agGridActivityLog"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnsArr"
      :pagination="true"
      :rowData="tableData"
      :frameworkComponents="frameworkComponents"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import agGridDateTimeCompareFilter from "@/components/superadmin/shared/agGridDateTimeCompareFilter";
import getTableColumnsConfig from "./tableColumnsConfig";
import XLSX from "xlsx";

extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  data: function() {
    return {
      loading: false,
      total: 0,
      queryParams: {},
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      },
      gridOptions: {
        defaultColDef: {
          resizable: true
        }
      },
      gridApi: undefined,
      gridColumnApi: undefined
    };
  },
  components: {
    AgGridVue
  },
  computed: {
    ...mapGetters("superadmin/activityLog", {
      getSelectedLogTypes: "getSelectedLogTypes",
      getActivityLogDataBySelectedFilters:
        "getActivityLogDataBySelectedFilters",
      getTableLoadingStatus: "getTableLoadingStatus"
    }),
    computedColumns: function() {
      const columns = this.$lodash.cloneDeep(
        this.columnsArr.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    columnsArr: function() {
      return getTableColumnsConfig(this.$lodash.get(this.getSelectedLogTypes, "value.ft_key")).filter((x) => x.visible());
    },
    tableData() {
      return this.getActivityLogDataBySelectedFilters;
    },
    frameworkComponents: function() {
      return {
        agGridDateTimeCompareFilter: agGridDateTimeCompareFilter
      };
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    handleDownloadExcel() {
      const columns = (this.gridColumnApi.columnModel.gridColumns || []).filter(column => column.visible).map((column) => [
        column.colDef.field,
        column.colDef.headerName,
      ]);
      console.log(columns)
      const xlData = [];
      this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        xlData.push(
          columns.reduce((acc, [field, headerName]) => {
            let cellDate = "";

            if (headerName === "Last Communication") {
              try {
                const data = this.$lodash.get(rowNode.data, field);
                const IstTimeZone = "Asia/Kolkata";
                if (data) {
                  cellDate = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      data,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );
                }
              } catch {
                // cellDate = "";
              }
              acc[headerName] = cellDate;
            } else {
              acc[headerName] = this.$lodash.get(rowNode.data, field);
            }
            return acc;
          }, {})
        );
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, `ActivityLog${this.adm__filterToFormatDate(new Date())}.xlsx`, {
        type: "binary",
        cellDates: true,
      });
    },
    formatDateMethod(UtcDate) {
      return (
        UtcDate &&
        this.adm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          UtcDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          this.getUserTimeZoneString,
          this.getLocaleObj
        )
      );
    },
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnsArr.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridActivityLog &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.log-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  &::v-deep .ag-theme-alpine {
    flex-grow: 1;
    height: 100%;
    margin-top: 10px;
  }
  &::v-deep .ag-grid-datetime-compare-filter {
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 182px;
    }
  }
}
</style>
