<template>
  <layout :no-aside="true" class="careers-table-sa" v-loading="loading">
      <!-- <div class="div_margin"> -->
        <layout-toolbar type="flex" justify="end" class="post-header-right">
          <el-col :span="24">
            <p class="layout-title-careers">
              Applicants
            </p>
          </el-col>
        </layout-toolbar>
        <er-data-tables
          :tableData="tableData"
          :columns="columnsObject1"
          :elTableProps="elTableProps1"
          :actionColumn="false"
          type="white-header-table"
          :key="$i18n.locale"
          uniqueId="carrers_table"
        >
        <template v-slot:resume_link="{ row }">
          <div class="resume-link">
            <a :href="row.data.resume_link" target="_blank">Resume Link</a>
          </div>
        </template>
        <template v-slot:appl_date="{ row }">
          {{ formatDate(row.data.created_at) }}
        </template>
        </er-data-tables>
      <!-- </div> -->
    <!-- <div class="div_margin"> -->
      <er-dialog
          :title="clientActionDialogTitle"
          :visible.sync="careerActionDialogVisible"
          :close-on-click-modal="false"
          :append-to-body="true"
          width="40%"
          custom-class="careerAction_dialog"
      >
      <ValidationObserver ref="dialogListForm">
      <el-form size="small" :model="formCareers" :inline="true">
        <ValidationProvider
          name="Role"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item
            label="Role"
            class="input-name"
            :error="errors[0]"
          >
            <el-input
              v-model="formCareers.role"
              placeholder="Enter Role"
            ></el-input>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="Location"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item
            label="Location"
            class="input-feed-type"
            :error="errors[0]"
          >
          <el-select
            v-model="formCareers.location"
            placeholder="Select Location"
          >
            <el-option
              v-for="item in locationOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="Exp Required"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item
            :error="errors[0]"
            label="Exp Required"
            class="input-feed-type"
          >
            <el-input
              type="number"
              placeholder="Enter Exp Required"
              :min="0"
              v-model="formCareers.experience"
              @change="handleChangeInExp($event)"
            ></el-input>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="Job Description"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item
            :error="errors[0]"
            label="Job Description"
            class="input-feed-type"
          >
            <el-input
              type="textarea"
              :rows="4"
              size="mini"
              autocomplete="off"
              class="careersPageTextarea"
              placeholder="Enter Job Description"
              v-model="formCareers.description"
            ></el-input>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="Department"
          rules="required"
          v-slot="{ errors }"
        >
          <el-form-item
            :error="errors[0]"
            label="Department"
            class="input-feed-type"
          >
          <el-select
            v-model="formCareers.department"
            placeholder="Select Department"
          >
            <el-option
              v-for="item in departmentOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          </el-form-item>
        </ValidationProvider>
        <el-form-item
          label="Status"
          class="input-feed-type"
        >
        <el-select
          v-model="formCareers.status"
          placeholder="Select Status"
        >
          <el-option
            v-for="item in statusListOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      </el-form>
    </ValidationObserver>
        <template slot="footer">
          <el-row type="flex" justify="end" align="middle">
              <er-button
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :loading="saveLoading"
              @click="handleSaveCareer"
              ></er-button>
              <er-button
              btnType="cancel"
              :showLabel="true"
              :loading="saveLoading"
              @click="closeAddClientDialog"
              ></er-button>
          </el-row>
        </template>
      </er-dialog>
      <layout-toolbar type="flex" justify="end" class="post-header-right">
        <el-col :span="24">
          <p class="layout-title-careers">
            Careers
          </p>
        </el-col>
        <er-button
          btnType="add"
          :showIcon="true"
          :showLabel="true"
          @click="handleAddClientClick"
        >Add New</er-button>
      </layout-toolbar>
      <er-data-tables
          :tableData="jobsTableData"
          :columns="columnsObject"
          :elTableProps="elTableProps2"
          :actionColumn="false"
          type="white-header-table"
          :key="$i18n.locale"
          uniqueId="carrers_table"
        >
        <template v-slot:posted_date="{ row }">
          {{ formatDate(row.data.created_at) }}
        </template>
        <template v-slot:closed_date="{ row }">
          {{ row.data.status === 'CLOSED' ? formatDate(row.data.updated_at) : '--' }}
        </template>
        <template v-slot:status="{ row }">
          {{ Status[row.data.status] }}
        </template>
        <template v-slot:job_description="{ row }">
          <el-popover
            placement="top-start"
            width="300"
            trigger="hover"
            style="font-size: 12px;"
            class="job-description-styles"
            :content="row.data.description">
              <span
                slot="reference"
                type="text"
                class="hiding-extra-reply-text"
                >{{ row.data.description }}
              </span>
          </el-popover>
          <!-- <span class="hiding-extra-reply-text">{{ row.data.description }}</span> -->
        </template>
        <template v-slot:actions="{ row }">
          <el-button-group>
            <er-button size="mini" @click="handleChangeStatus($event, 'EDIT', row.data)">
              <span class="material-icons-round"> edit </span>
            </er-button>
            <er-button size="mini" @click="handleChangeStatus($event, 'RESET', row.data)">
              <span class="material-icons-round"> delete </span>
            </er-button>
          </el-button-group>
          <!-- <er-button
            type="text"
            size="mini"
            btnType="edit"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            @click="handleChangeStatus($event, 'EDIT', row.data)"
          ></er-button>
          <el-divider
            direction="vertical"
            style="height: 100%"
          ></el-divider>
          <er-button
            type="text"
            size="mini"
            btnType="delete"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            @click="handleChangeStatus($event, 'RESET', row.data)"
          ></er-button> -->
        </template>
      </er-data-tables>
    <!-- </div> -->
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
// import settingsTable from "@/components/base/settingsTable";
import layout from "@/components/superadmin/shared/layout";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import moment from "moment";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      tableData: [{ created_at: '' }],
      jobsTableData: [{ created_at: '' }],
      total: 0,
      totalJobs: 0,
      formCareers: {
        postedDate: '',
        closedDate: '',
        role: "",
        experience: "",
        description: "",
        location: '',
        status: 'OPEN',
        department: ''
      },
      careerId: '',
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      careerActionDialogVisible: false,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD",
      queryParams: {},
      newClient: {},
      usersList: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name", "email"],
        un_assigned_only: true
      },
      statusListOption: [
        {
          value: "OPEN",
          label: "Open",
        },
        {
          value: "ONHOLD",
          label: "On Hold",
        },
        {
          value: "CLOSED",
          label: "Closed",
        }
      ],
      Status: {
        OPEN: "Open",
        ONHOLD: "On Hold",
        CLOSED: "Closed"
      },
      locationOption: [
        {
          value: "Vijayawada",
          label: "Vijayawada",
        },
        {
          value: "Hyderabad",
          label: "Hyderabad",
        }
      ],
      departmentOption: [
        {
          value: "IT",
          label: "IT",
        },
      ],
    };
  },
  components: {
    // settingsTable,
    layout
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers"
    }),
    columnsObject1: function () {
      return {
        appl_date: {
          label: "Appl Date",
          prop: "created_at",
          required: true
        },
        name: {
          label: "Name",
          prop: "full_name",
          required: true
        },
        email: {
          label: "Email",
          prop: "email",
          required: true
        },
        total_experience: {
          label: "Exp.",
          prop: "total_experience",
          required: true
        },
        mobile: {
          label: "Mobile",
          prop: "phone",
          required: true
        },
        resume_link: {
          label: "Resume Link",
          prop: 'resume_link',
          required: true
        }
      };
    },
    columnsObject: function () {
      return {
      posted_date: {
          label: "Posted Date",
          prop: "created_at",
          required: true,
          sortable: false,
          minWidth: 80
        },
        closed_date: {
          label: "Closed Date",
          prop: "created_at",
          required: true,
          sortable: false,
          minWidth: 80
        },
        role: {
          label: "Role",
          prop: "role",
          required: true,
          sortable: false,
          minWidth: 100
        },
        experience_required: {
          label: "Exp Required",
          prop: "experience_required",
          required: true,
          sortable: false,
          minWidth: 60
        },
        job_description: {
          label: "Job Description",
          prop: "description",
          required: true,
          sortable: false,
          minWidth: 250
        },
        status: {
          label: "Status",
          prop: "status",
          required: true,
          sortable: false,
          minWidth: 50
        },
        Actions: {
          label: "Actions",
          required: true,
          minWidth: 60
        }
      };
    },
    elTableProps1() {
      return {
        height: "165px",
      };
    },
    elTableProps2() {
      return {
        height: "200px",
      };
    },
  },
  mounted() {
    this.handleLoadData();
  },
  methods: {
    handleChangeInExp(data) {
      console.log("data", Number(data).toFixed(1));
      this.formCareers = { ...this.formCareers, experience: Number(data).toFixed(1) };
      return Number(data).toFixed(1);
    },

    ...mapActions("superadmin", {
      getApplicantsData: "getApplicantsData",
      getJobsData: "getJobsData",
      careerDataAddAnsEdit: "careerDataAddAnsEdit"
    }),
    formatDate(data) {
      return moment(data).format("DD MMM, yy");
    },
    async handleChangeStatus(event, action, career) {
      if (action === 'EDIT') {
        this.action = 'EDIT';
        this.careerActionDialogVisible = true;
        this.careerId = career._id;
        this.formCareers = {
          postedDate: '',
          closedDate: '',
          role: career.role,
          experience: career.experience_required,
          description: career.description,
          location: career.location,
          department: career.department,
          status: career.status,
        }
      } else {
        this.action = 'DELETED';
        this.careerId = career._id;
        await this.$confirm('You are Trying to Delete Career, Are you sure?', "Warning", {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }).then(async () => {
            const payload = { action: this.action, career_id: this.careerId, payload: { ...career, status: 'DELETED' } }
            await this.careerDataAddAnsEdit(payload);
            this.$notify({
              type: "success",
              title: "Success",
              message: `Career Deleted Sucuessfully.`
            });
            this.handleLoadData();
          })
      }
    },
    async handleLoadData() {
      try {
        this.loading = true;
        await this.getApplicantsData();
        await this.getJobsData();
        this.tableData = this.$store.getters["superadmin/getAllApplicantsData"].reverse();
        this.jobsTableData = this.$store.getters["superadmin/getAllJobsData"].filter(job => job.status !== 'DELETED').reverse();
        this.total = this.tableData.length;
        this.totalJobs = this.jobsTableData.length;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddClientClick() {
      this.action = "ADD";
      this.clientActionDialogTitle = "Add Career";
      this.formCareers = {
        postedDate: '',
        closedDate: '',
        role: "",
        experience: "",
        description: "",
        location: '',
        status: 'OPEN',
        department: ''
      };
      this.careerActionDialogVisible = true;
    },
    closeAddClientDialog() {
      this.careerActionDialogVisible = false;
      this.$refs.dialogListForm.reset();
      this.action = "";
    },
    async handleSaveCareer() {
      let actionType;
      try {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        this.saveLoading = true;
        let payload, actionType;
        if (this.action === "ADD") {
          payload = { action: this.action, career_id: this.careerId, payload: this.formCareers };
          actionType = "Created";
        } else {
          payload = { action: this.action, career_id: this.careerId, payload: this.formCareers }
          actionType = "Updated";
        }

        await this.careerDataAddAnsEdit(payload);
        this.$notify({
          type: "success",
          title: "Success",
          message: `Career ${actionType} Sucuessfully.`
        });
        this.closeAddClientDialog();
        await this.handleLoadData(this.queryParams);
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Failed",
          message: `Career ${actionType} Faild.`
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.careerId = '';
        this.saveLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.hiding-extra-reply-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  p.layout-title-careers {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    font-weight: 700;
    color: #0a2463;
    padding: 4px;
  }
  .post-header-right {
    padding: 5px;
    border: 1px solid #ebebeb;
  }
</style>
<style lang="scss">
.div_margin {
  margin: 0px 10px 0px 10px;;
}
.el-popover {
  font-size: 12px;
}
.resume-link {
  // margin-top: 12px;
  a {
    width: max-content;
    text-decoration: none;
    color: #409eff;
    border-bottom: 1px solid #409eff;
  }
  a::after {
    text-decoration: none;
    border: none;
  }
}
.careers-table-sa {
  .material-icons-round {
    font-size: 14px;
  }
  // .settings-table .settings-table-scroll {
  //   position: relative;
  //   @include responsiveProperty(height, 80vh, 84vh, 87vh);
  // }
}
.careerAction_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 320px, 357px, 400px);
  }
  .careersPageTextarea {
    @include responsiveProperty(width, 320px, 357px, 400px);
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 100px, 130px, 190px);
  }
}
</style>
