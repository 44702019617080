<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: ShrimpTalkIntakeGraph
Description: This file contains UI components of Shrimp Talk Intake Graph built using Highcharts
-->
<template>
  <el-row class="st-pond-intake-graphs">
    <div v-if="listOfSts.length === 0">
      <center><el-tag>No ShrimpTalks</el-tag></center>
    </div>
    <div
      v-else
      v-for="st in getSortSTByV1Code"
      :key="`${st._id}_${dateRange[0]}_${dateRange[1]}`"
    >
      <er-card :header="st.code">
        <template slot="header">
          <span>{{ `${st.code}(${getStV1Code(st.code)})` }} </span>
          <div @click="handleDownload(st)">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </div>
        </template>
        <Observer @intersect="parseIntersectedStDataWithoutDebounce(st)" />
        <high-charts
          :options="chartOptions"
          :ref="`highcharts_${st.code}`"
          v-loading="objOfStCodeToLoader[st.code]"
        ></high-charts>
      </er-card>
    </div>
  </el-row>
</template>

<script>
import SuperAdminService from "@/services/SuperAdminService";
import Observer from "@/components/superadmin/shared/Observer";
import chartOptions from "./chartOptions";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import XLSX from "xlsx";

import {
  getSTHourlyIntakeResponseData,
  getStUtilization,
  getStAvgUtilization,
  getStAreaAvgUtilization,
  getHourlyAreaAvgUtils,
  getStOcf,
  getStFeedingRate,
  getIntakeResponse,
  getUtilizationPercntError,
  getHourlyStPmsFeed,
  getHourlyResponseCorrelation,
  getStHourlyFeeding,
} from "@/utils/stStatisticsUtils";
import ShrimpTalk from "@/model/shrimptalk";
import { stIntakeGraphColors } from "@/utils/componentColors.js";
export default {
  props: {
    listOfSts: {
      type: Array,
      required: true,
      default: () => [],
    },
    pondId: {
      type: String,
      default: "",
    },
    dateRange: {
      default: () => [],
    },
    userTimeZoneString: {
      required: true,
      default: "UTC",
    },
  },
  inject: ["shrimpTalkView"],
  mixins: [resizeContainerMixin],
  components: {
    Observer,
  },
  data() {
    return {
      objOfStIdToStCodes: {},
      objOfStCodeToSeries: {},
      objOfStCodeToLoader: {},
      objOfStCodeToSignData: {},
      objOfStCodeToStatsData: {},
      pondHourlyFeed: [],
      chartOptions,

      segmentColorMap: stIntakeGraphColors,
      uiState: "INIT",
    };
  },
  watch: {
    listOfSts: async function (newSts) {
      await this.initComponent();
    },
    dateRange: async function (newDateRange) {
      await this.initComponent();
    },
  },
  computed: {
    urlParams: function () {
      return this.shrimpTalkView.urlParams;
    },
    getStV1Code: function () {
      return (stCode) => ShrimpTalk.v1Title(stCode);
    },
    getSortSTByV1Code() {
      return this.$lodash.cloneDeep(this.listOfSts).sort((a, b) => {
        this.$commonUtils.alphaNumericComparator(
          this.getStV1Code(a.code),
          this.getStV1Code(b.code)
        );
      });
    },
  },
  async mounted() {
    await this.initComponent();
    this.uiState = "INIT";
  },
  methods: {
    setObjProperty(obj, key, value) {
      this.$set(obj, key, value);
    },
    initComponent: async function () {
      this.objOfStIdToStCodes = {};
      this.objOfStCodeToSeries = {};
      this.objOfStCodeToLoader = {};
      this.objOfStCodeToStatsData = {};
      this.objOfStCodeToSignData = {};
      this.pondHourlyFeed = [];
      if (!this.listOfSts) return;
      this.objOfStIdToStCodes = this.listOfSts.reduce((acc, st) => {
        acc[st.code] = st._id;
        return acc;
      }, {});
      this.objOfStCodeToSeries = this.listOfSts.reduce((acc, st) => {
        acc[st.code] = {};
        return acc;
      }, {});
      this.objOfStCodeToLoader = this.listOfSts.reduce((acc, st) => {
        acc[st.code] = true;
        return acc;
      }, {});
      this.objOfStCodeToSignData = this.listOfSts.reduce((acc, st) => {
        acc[st.code] = undefined;
        return acc;
      }, {});
      this.objOfStCodeToStatsData = this.listOfSts.reduce((acc, st) => {
        acc[st.code] = undefined;
        return acc;
      }, {});

      this.uiState = "loaded";
    },
    handleDownload(st) {
      const ST_HOURLY = this.objOfStCodeToSeries[st.code].ST_HOURLY.map(
        ([Date, ST_feed_value]) => ({ Date, ST_feed_value })
      ).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "ST Hourly Feeding": indItem.ST_feed_value,
        };
      });
      const PM_HOURLY = this.objOfStCodeToSeries[st.code].PM_HOURLY.map(
        ([Date, HR_feed_value]) => ({ Date, HR_feed_value })
      ).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "PM Hourly Feeding": indItem.HR_feed_value,
        };
      });
      const STATS_INTAKE_AVG_IQR_WITH_OUT_NOISE = this.objOfStCodeToSeries[
        st.code
      ].STATS_INTAKE_AVG_IQR_WITH_OUT_NOISE.map(([Date, STATS_INTAKE]) => ({
        Date,
        STATS_INTAKE,
      })).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "IQR-Hourly Intake Response Noise Removal": indItem.STATS_INTAKE,
        };
      });
      const SIGN_INTAKE_AVG_IQR_WITH_OUT_NOISE = this.objOfStCodeToSeries[
        st.code
      ].SIGN_INTAKE_AVG_IQR_WITH_OUT_NOISE.map(([Date, SIGN_INTAKE]) => ({
        Date,
        SIGN_INTAKE,
      })).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "Signature IQR-Hourly Intake Response Noise Removal":
            indItem.SIGN_INTAKE,
        };
      });
      const STATS_INTAKE_WITH_OUT_NOISE = this.objOfStCodeToSeries[
        st.code
      ].STATS_INTAKE_WITH_OUT_NOISE.map(
        ([Date, STATS_INTAKE_WITH_OUT_NOISE]) => ({
          Date,
          STATS_INTAKE_WITH_OUT_NOISE,
        })
      ).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "Hourly Intake Response Noise Removal":
            indItem.STATS_INTAKE_WITH_OUT_NOISE,
        };
      });

      const STATS_INTAKE_WITH_NOISE = this.objOfStCodeToSeries[
        st.code
      ].STATS_INTAKE_WITH_NOISE.map(([Date, STATS_INTAKE_WITH_NOISE]) => ({
        Date,
        STATS_INTAKE_WITH_NOISE,
      })).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "Hourly Intake Response": indItem.STATS_INTAKE_WITH_NOISE,
        };
      });
      const SIGN_AREA_AVERAGE_UTILS = this.objOfStCodeToSeries[
        st.code
      ].SIGN_AREA_AVERAGE_UTILS.map(([Date, SIGN_AREA_AVERAGE_UTILS]) => ({
        Date,
        SIGN_AREA_AVERAGE_UTILS,
      })).map((indItem) => {
        return {
          Date: new Date(indItem.Date).toUTCString(),
          "Normalised Area": indItem.SIGN_AREA_AVERAGE_UTILS,
        };
      });
      console.log(SIGN_AREA_AVERAGE_UTILS);

      const hourly = ST_HOURLY.map((item, i) =>
        Object.assign(
          {},
          item,
          PM_HOURLY[i],
          STATS_INTAKE_AVG_IQR_WITH_OUT_NOISE[i],
          SIGN_INTAKE_AVG_IQR_WITH_OUT_NOISE[i],
          STATS_INTAKE_WITH_OUT_NOISE[i],
          STATS_INTAKE_WITH_NOISE[i],
          SIGN_AREA_AVERAGE_UTILS[i]
        )
      );

      this.objOfStCodeToSeries[st.code].STATS_INTAKE_RESPONSE_WITH_RESULT =
        this.objOfStCodeToSeries[st.code].STATS_INTAKE_RESPONSE_WITH_RESULT.map(
          (item) => [item.x, item.y]
        );

      this.objOfStCodeToSeries[st.code].SIGN_INTAKE_RESPONSE_WITH_RESULT =
        this.objOfStCodeToSeries[st.code].SIGN_INTAKE_RESPONSE_WITH_RESULT.map(
          (item) => [item.x, item.y]
        );

      const arrayofItems = [
        { item: "Hourly Data", name: "Hourly Data" }, // hourly
        { item: "STATS_UTILIZATION", key: "Utilization", name: "Util" },
        { item: "STATS_OCF", key: "OCF", name: "OCF" },
        {
          item: "STATS_AVG_FEED_UTILIZATION",
          key: "Average Feed Utilization",
          name: "Avg Feed Util",
        },
        {
          item: "SIGN_UTILIZATION",
          key: "Signature Utilization",
          name: "Sig-natr Util",
        },
        {
          item: "SIGN_INTAKE_WITH_OUT_NOISE",
          key: "Signature Hourly Intake Response Noise Removal",
          name: "Sig-natr hour Intk No Noise",
        },
        {
          item: "SIGN_AVG_FEED_UTILIZATION",
          key: "Signature Average Feed Utilization",
          name: "Sig-natr Avg Feed Util",
        },
        {
          item: "HOURLY_AREA_AVG_UTILS",
          key: "Hourly Average Feed Utilization",
          name: "Hourly-natr Avg Feed Util",
        },
        { item: "FEEDING_RATE", key: "Feeding Rate", name: "feeding Rate" },
        {
          item: "STATS_INTAKE_RESPONSE_WITH_RESULT",
          key: "Intake Response",
          name: "Intk Resp",
        },
        {
          item: "SIGN_INTAKE_RESPONSE_WITH_RESULT",
          key: "Signature Intake Response",
          name: "Sig-natr Intk Resp",
        },
        {
          item: "SIGN_AREA_AVERAGE_UTILS",
          key: "Signature Area/Average Utils",
          name: "Sig-natr Area Average Utils",
        },
      ];
      const wb = XLSX.utils.book_new();

      arrayofItems.map((x) => {
        if (x.item === "Hourly Data") {
          const Data = hourly;
          const xlData = Data;
          const ws = XLSX.utils.json_to_sheet(xlData);
          XLSX.utils.book_append_sheet(wb, ws, x.name);
        } else {
          const XData = this.objOfStCodeToSeries[st.code][x.item]
            .map(([Date, feed_value]) => ({ Date, feed_value }))
            .map((indItem) => {
              return {
                Date: new Date(indItem.Date).toUTCString(),
                [x.key]: indItem.feed_value,
              };
            });
          const ws = XLSX.utils.json_to_sheet(XData);
          XLSX.utils.book_append_sheet(wb, ws, x.name);
        }
      });

      XLSX.writeFile(wb, `Shrimptalk Intake Graph (${st.code}).xlsx`, {
        type: "binary",
        cellDates: true,
      });
    },
    async fetchScheduleFeedData() {
      try {
        if (!this.pondId) return;
        const response = await SuperAdminService.fetchPondScheduleHourlyFeed(
          this.pondId,
          {
            from_date: this.dateRange[0],
            to_date: this.dateRange[1],
          }
        );
        this.pondHourlyFeed = response.data.pond_hourly_feed_logs.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        console.log(this.pondHourlyFeed);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchStStatsDetails({ _id: shrimp_talk_id, code: shrimp_talk_code }) {
      try {
        const response = await SuperAdminService.fetchSTIntakeSummary(
          shrimp_talk_id,
          {
            from_date: this.dateRange[0],
            to_date: this.dateRange[1],
          }
        );
        this.objOfStCodeToSignData[shrimp_talk_code] =
          response.data.shrimp_talk_signature_statics.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
        console.log(this.objOfStCodeToSignData[shrimp_talk_code]);
        this.objOfStCodeToStatsData[shrimp_talk_code] =
          response.data.shrimp_talk_statics.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
      } catch (err) {
        console.error(err);
      }
    },
    getStStatsDataOfProp(stCode, prop, dayFreqType) {
      const propToFunction = {
        stats_utilization: getStUtilization,
        stats_avg_feed_utilization: getStAvgUtilization,
        ocf: getStOcf,
        st_hourly: (data, tz) => getStHourlyFeeding(data, tz),
        feeding_rate: getStFeedingRate,
        stats_intake_response_with_result: getIntakeResponse,
        stats_ocf: getStOcf,
        stats_hourly_response_correlation: getHourlyResponseCorrelation,
        stats_utilization_percent_error: getUtilizationPercntError,
        stats_intake_with_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "WITH_NOISE", false, tz),
        stats_intake_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "WITH_OUT_NOISE", false, tz),
        stats_intake_iqr_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "IQR_REPLACE_NOISE", false, tz),
        stats_intake_avg_iqr_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(
            data,
            "AVG_IQR_REPLACE_NOISE",
            false,
            tz
          ),
      };
      if (dayFreqType === "ALL") {
        const allDaysData = (this.objOfStCodeToStatsData[stCode] || [])
          .map((currDateRecords) => {
            return currDateRecords.data;
          })
          .flat(1);
        return propToFunction[prop](
          { data: allDaysData },
          this.userTimeZoneString
        );
      }
      if (dayFreqType === "RAW") {
        return propToFunction[prop](
          this.objOfStCodeToStatsData[stCode] || [],
          this.userTimeZoneString
        );
      }
      return (this.objOfStCodeToStatsData[stCode] || []).map(
        (currDateRecords) => {
          return propToFunction[prop](currDateRecords, this.userTimeZoneString);
        }
      );
    },
    getStSignDataOfProp(stCode, prop, dayFreqType) {
      const propToFunction = {
        sign_utilization: getStUtilization,
        sign_avg_feed_utilization: getStAvgUtilization,
        hourly_area_avg_utils: (data, tz) =>
          getHourlyAreaAvgUtils(data, false, tz),
        sign_ocf: getStOcf,
        sign_feeding_rate: getStFeedingRate,
        sign_intake_response_with_result: getIntakeResponse,
        sign_area_average_utils: getStAreaAvgUtilization,
        sign_hourly_ocf: getStOcf,
        sign_hourly_response_correlation: getHourlyResponseCorrelation,
        sign_utilization_percent_error: getUtilizationPercntError,
        sign_intake_with_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "WITH_NOISE", false, tz),
        sign_intake_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "WITH_OUT_NOISE", false, tz),
        sign_intake_iqr_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(data, "IQR_REPLACE_NOISE", false, tz),
        sign_intake_avg_iqr_with_out_noise: (data, tz) =>
          getSTHourlyIntakeResponseData(
            data,
            "AVG_IQR_REPLACE_NOISE",
            false,
            tz
          ),
      };
      if (dayFreqType === "ALL") {
        const allDaysData = (this.objOfStCodeToSignData[stCode] || [])
          .map((currDateRecords) => {
            return currDateRecords.data;
          })
          .flat(1);
        return propToFunction[prop](
          { data: allDaysData },
          this.userTimeZoneString
        );
      }
      if (dayFreqType === "RAW") {
        return propToFunction[prop](
          this.objOfStCodeToSignData[stCode] || [],
          this.userTimeZoneString
        );
      }
      return (this.objOfStCodeToSignData[stCode] || []).map(
        (currDateRecords) => {
          return propToFunction[prop](currDateRecords, this.userTimeZoneString);
        }
      );
    },
    updateChartOptionsOfSt(stCode, series) {
      const chart = this.$refs[`highcharts_${stCode}`][0].chart;
      series.forEach(({ seriesId, visible }) => {
        const sid = seriesId.toUpperCase();
        const hasSeries = chart.get(sid);

        if (hasSeries) {
          chart.get(sid).setData(this.objOfStCodeToSeries[stCode][sid]);
          chart.get(sid).setVisible(visible);
        }
      });
      chart.setTitle({
        text:
          'Intake Response (<span id="hc" title="Energy Hourly Correlation">HC(E)</span><b>/</b><span id="hc" title="Signature Hourly Correlation">HC(S)</span><b>/</b><span title="Regression Error Metric(Energy)">REM(E)</span><b>/</b><span title="Regression Error Metric(Signature)">REM(S)</span>:' +
          this.objOfStCodeToSeries[stCode].STATS_HOURLY_RESPONSE_CORRELATION +
          "<b> / </b>" +
          this.objOfStCodeToSeries[stCode].SIGN_HOURLY_RESPONSE_CORRELATION +
          "<b> / </b>" +
          this.objOfStCodeToSeries[stCode].STATS_UTILIZATION_PERCENT_ERROR +
          "<b> / </b>" +
          this.objOfStCodeToSeries[stCode].SIGN_UTILIZATION_PERCENT_ERROR +
          ")",
      });
      // console.log(chart);
    },
    generateSeriesOnStStatsData(st) {
      [
        { prop: "stats_utilization", dayFreqType: "SINGLE", flat: 1 },
        { prop: "stats_avg_feed_utilization", dayFreqType: "SINGLE", flat: 1 },

        // { prop: "hourly_ocf", dayFreqType: "SINGLE", flat: 1 },
        { prop: "feeding_rate", dayFreqType: "SINGLE", flat: 1 },
        {
          prop: "stats_intake_response_with_result",
          dayFreqType: "SINGLE",
          flat: 1,
          seriesTransForm: (arrItems) => {
            return arrItems.map((item) => {
              return {
                x: item[0],
                y: item[1],
                result: item[2],
                segmentColor: this.segmentColorMap[item[2]].color,
              };
            });
          },
        },
        { prop: "stats_ocf", dayFreqType: "SINGLE", flat: 1 },
        { prop: "st_hourly", dayFreqType: "SINGLE", flat: 1 },
        { prop: "stats_intake_with_noise", dayFreqType: "SINGLE", flat: 1 },
        { prop: "stats_intake_with_out_noise", dayFreqType: "SINGLE", flat: 1 },
        // {
        //   prop: "stats_intake_iqr_with_out_noise",
        //   dayFreqType: "SINGLE",
        //   flat: 1,
        // },
        {
          prop: "stats_intake_avg_iqr_with_out_noise",
          dayFreqType: "SINGLE",
          flat: 1,
        },
        {
          prop: "stats_utilization_percent_error",
          dayFreqType: "ALL",
          flat: 0,
        },
        {
          prop: "stats_hourly_response_correlation",
          dayFreqType: "RAW",
          flat: 0,
        },
      ].reduce((acc, item) => {
        const values = this.getStStatsDataOfProp(
          st.code,
          item.prop,
          item.dayFreqType
        );
        if (item.flat) {
          const flatedSeries = values.flat(item.flat);
          acc[item.prop.toUpperCase()] = item.seriesTransForm
            ? item.seriesTransForm(flatedSeries)
            : flatedSeries;
          return acc;
        }
        acc[item.prop.toUpperCase()] = item.seriesTransForm
          ? item.seriesTransForm(values)
          : values;
        return acc;
      }, this.objOfStCodeToSeries[st.code]);
    },
    generateSeriesOnStSignData(st) {
      [
        { prop: "sign_utilization", dayFreqType: "SINGLE", flat: 1 },
        { prop: "sign_avg_feed_utilization", dayFreqType: "SINGLE", flat: 1 },
        { prop: "hourly_area_avg_utils", dayFreqType: "SINGLE", flat: 1 },
        {
          prop: "sign_area_average_utils",
          dayFreqType: "SINGLE",
          flat: 1,
        },
        {
          prop: "sign_intake_response_with_result",
          dayFreqType: "SINGLE",
          flat: 1,
          seriesTransForm: (arrItems) => {
            return arrItems.map((item) => {
              return {
                x: item[0],
                y: item[1],
                result: item[2],
                segmentColor: this.segmentColorMap[item[2]].color,
              };
            });
          },
        },
        // { prop: "sign_intake_with_noise", dayFreqType: "SINGLE", flat: 1 },
        { prop: "sign_intake_with_out_noise", dayFreqType: "SINGLE", flat: 1 },
        // {
        //   prop: "sign_intake_iqr_with_out_noise",
        //   dayFreqType: "SINGLE",
        //   flat: 1,
        // },
        {
          prop: "sign_intake_avg_iqr_with_out_noise",
          dayFreqType: "SINGLE",
          flat: 1,
        },
        { prop: "sign_utilization_percent_error", dayFreqType: "ALL", flat: 0 },
        {
          prop: "sign_hourly_response_correlation",
          dayFreqType: "RAW",
          flat: 0,
        },
      ].reduce((acc, item) => {
        const values = this.getStSignDataOfProp(
          st.code,
          item.prop,
          item.dayFreqType
        );
        if (item.flat) {
          const flatedSeries = values.flat(item.flat);
          acc[item.prop.toUpperCase()] = item.seriesTransForm
            ? item.seriesTransForm(flatedSeries)
            : flatedSeries;
          return acc;
        }
        acc[item.prop.toUpperCase()] = item.seriesTransForm
          ? item.seriesTransForm(values)
          : values;
        return acc;
      }, this.objOfStCodeToSeries[st.code]);
    },
    generatePmHourlySeriesOnSt(st) {
      const arrDayFeeds = this.pondHourlyFeed.map((eachDayFeed) =>
        getHourlyStPmsFeed(eachDayFeed, st._id)
      );
      this.objOfStCodeToSeries[st.code]["pm_hourly".toUpperCase()] =
        arrDayFeeds.flat(1);
    },
    parseIntersectedStDataWithoutDebounce: async function (st) {
      this.objOfStCodeToLoader[st.code] = true;
      if (this.pondHourlyFeed.length === 0) {
        await this.fetchScheduleFeedData();
      }
      if (
        !this.objOfStCodeToStatsData[st.code] &&
        !this.objOfStCodeToSignData[st.code]
      ) {
        await this.fetchStStatsDetails(st);
      }
      if (Object.keys(this.objOfStCodeToSeries[st.code]).length > 0) {
        this.objOfStCodeToLoader[st.code] = false;
        return;
      }
      this.generatePmHourlySeriesOnSt(st);
      this.generateSeriesOnStStatsData(st);
      this.generateSeriesOnStSignData(st);
      this.updateChartOptionsOfSt(st.code, [
        { seriesId: "pm_hourly", visible: false },
        { seriesId: "st_hourly", visible: true },
        { seriesId: "stats_ocf", visible: false },
        { seriesId: "feeding_rate", visible: false },
        { seriesId: "stats_intake_with_noise", visible: false },
        // { seriesId: "sign_intake_with_noise", visible: false },
        { seriesId: "stats_intake_with_out_noise", visible: false },
        // { seriesId: "sign_intake_with_out_noise", visible: false },
        // { seriesId: "stats_intake_iqr_with_out_noise", visible: false },
        // { seriesId: "sign_intake_iqr_with_out_noise", visible: false },
        { seriesId: "stats_intake_avg_iqr_with_out_noise", visible: true },
        { seriesId: "sign_intake_avg_iqr_with_out_noise", visible: false },
        { seriesId: "stats_intake_response_with_result", visible: false },
        { seriesId: "sign_intake_response_with_result", visible: false },
        { seriesId: "stats_utilization", visible: false },
        { seriesId: "sign_utilization", visible: false },
        { seriesId: "stats_avg_feed_utilization", visible: false },

        { seriesId: "sign_avg_feed_utilization", visible: false },
        { seriesId: "hourly_area_avg_utils", visible: false },
        { seriesId: "sign_area_average_utils", visible: false },
      ]);
      this.objOfStCodeToLoader[st.code] = false;
    },
    printToConsole(prop, datedSeries) {
      console.group(prop);
      if (!Array.isArray(datedSeries)) {
        console.log(datedSeries);
        console.groupEnd(prop);
        return;
      }
      datedSeries.forEach((seriesWithDate) => {
        console.group(seriesWithDate[0]);
        console.log(seriesWithDate[1]);
        console.groupEnd(seriesWithDate[0]);
      });
      console.groupEnd(prop);
    },
  },
};
</script>

<style lang="scss">
.st-pond-intake-graphs {
  overflow: scroll;
  margin: 0 10px;
  max-height: calc(100vh - 11rem);
  .er-card.er-card--secondary .el-card__header {
    display: flex;
    justify-content: space-between;
  }
  .er-card {
    margin-bottom: 10px;
  }
  .action-icon {
    cursor: pointer;
  }
}
</style>
