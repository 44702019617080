var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dealer-shrimptalk-tab",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"page_loading"}):_vm._e(),_vm._v(" "),_c(_vm.childComponents[_vm.selectedComponent],{tag:"component",attrs:{"dialogVisible":_vm.dialogVisible},on:{"refresh":_vm.handleCloseDialog,"close-dialog":_vm.handleCloseDialog}}),_vm._v(" "),_c('layout-dealer-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"search-property":"device_code","queryParams":_vm.queryParams},on:{"add-item-click":_vm.handleAddUserClick,"loadData":_vm.handleLoadData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"main_header"},[_c('img',{attrs:{"src":require("@/assets/dealer/devices.svg"),"atl":"down arrow"}}),_vm._v(" "),_c('p',{staticStyle:{"margin-left":"10px"}},[_vm._v("\n          "+_vm._s(_vm.$t("Comn_shrimptalk"))+"\n        ")])])]},proxy:true}])},[_vm._v(" "),_c('defaultTable',{staticClass:"add_PondMother",attrs:{"slot":"table","tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"search-property":"device_code","addPadingToSearch":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData},slot:"table",scopedSlots:_vm._u([{key:"email_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"actions_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"status_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"code_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"added_date_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"first_assigned_date_header",fn:function(ref){
var header = ref.header;
return [_c('el-tooltip',{attrs:{"content":_vm.$t(header.sub_label),"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(_vm.$t(header.label)))])])]}},{key:"last_assigned_date_header",fn:function(ref){
var header = ref.header;
return [_c('el-tooltip',{attrs:{"content":_vm.$t(header.sub_label),"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(_vm.$t(header.label)))])])]}},{key:"first_user_assigned_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.first_user_assigned_date)))+"\n      ")]}},{key:"last_user_assigned_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.last_user_assigned_date)))+"\n      ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.getStatus(row.data, "SHRIMP_TALK"))+"\n      ")]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.code)+"\n      ")]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.user_details
            ? row.data.user_details.first_name
            : _vm.getDealerUsername.first_name)+"\n\n        "+_vm._s(row.data.user_details
            ? row.data.user_details.last_name
            : _vm.getDealerUsername.last_name)+"\n        "),_c('br'),_vm._v("\n        "+_vm._s(row.data.user_details
            ? row.data.user_details.email
            : _vm.getDealerUsername.email)+"\n      ")]}},{key:"added_date",fn:function(ref){
            var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__digitPrecision(_vm.adm__filterToFormatDate(row.data.added_date)))+"\n      ")]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini","disabled":!Boolean(row.data.user_details),"title":_vm.$t('dealer.unassign_from_user')},on:{"click":function($event){return _vm.handleDeviceDelete(row.data, 'SHRIMP_TALK')}}},[_c('span',{staticClass:"material-icons-round icon__prefix"},[_vm._v(" delete ")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }