var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"inquiry-log",staticStyle:{"padding":"10px"}},[_c('el-row',{staticClass:"inquiry-log-header",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"lg":19}},[_c('h4',[_vm._v("Inquiry Log")])]),_vm._v(" "),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"lg":4}},[_c('er-date-picker',{attrs:{"value-format":"yyyy-MM-dd","timeZoneString":_vm.getUserTimeZoneString,"format":_vm.upm__getFormatDateString,"type":"daterange","clearable":false,"picker-options":_vm.pickerOptions},on:{"change":_vm.handleDateChange},model:{value:(_vm.dateRangeSelected),callback:function ($$v) {_vm.dateRangeSelected=$$v},expression:"dateRangeSelected"}})],1)],1),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",attrs:{"element-loading-background":"white","tableData":_vm.tableDataArray,"tableProps":_vm.elTableProps,"total":_vm.total,"button":false,"searchable":false,"columns":_vm.columnsFilterByTab,"columnselector_require":false},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData},scopedSlots:_vm._u([{key:"customer_name",fn:function(ref){
var row = ref.row;
return [_c('er-text-tag',{attrs:{"text-to-scroll":("" + (row.data.name)),"threshold-characters":20}},[_vm._v("\n      "+_vm._s(row.data.name)+"\n      ")])]}},{key:"email",fn:function(){return undefined},proxy:true},{key:"mobile",fn:function(){return undefined},proxy:true},{key:"message",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"hiding-extra-text"},[_vm._v(_vm._s(row.data.message))])]}},{key:"requested_on",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.dateFormat(row.data.utc_added_date))+"\n    ")]}},{key:"reply",fn:function(ref){
var row = ref.row;
return [_c('er-button',{staticClass:"hiding-extra-reply-text",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleReplyPopup(row.data)}}},[_vm._v(_vm._s(row.data.replies.length ? row.data.replies[0].reply : "None")+"\n        ")])]}},{key:"replied_on",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.replies.length ? _vm.dateFormat(row.data.replies[0].utc_date) : "--")+"\n    ")]}}])}),_vm._v(" "),_c('el-dialog',{attrs:{"custom-class":"dialog-body-width-50 dialog-body-y-20","title":"Reply","visible":_vm.dialogFormVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.handleDialogCancel}},[_c('div',{staticClass:"msgbox-css"},[(_vm.messageData.length==0)?_c('p',[_vm._v(" No Data Available")]):_vm._e(),_vm._v(" "),_vm._l((_vm.messageData),function(msg,index){return _c('ol',{key:index,staticStyle:{"padding-bottom":"10px"}},[_c('p',[_vm._v("Date : "+_vm._s(_vm.dateFormat(msg.utc_date)))]),_vm._v(" "),_c('p',[_vm._v("Message : "+_vm._s(msg.reply))])])})],2),_vm._v(" "),_c('el-form',{attrs:{"model":_vm.inquiry_form}},[_c('el-form-item',[_c('el-date-picker',{attrs:{"size":"mini","type":"datetime","format":"dd MMM yy HH:mm","value-format":"yyyy-MM-dd[T]HH:mm:ss.SSS[Z]","placeholder":"Pick a day","picker-options":_vm.fromDatePickerOptions,"disabled":"true"},model:{value:(_vm.inquiry_form.form_date),callback:function ($$v) {_vm.$set(_vm.inquiry_form, "form_date", $$v)},expression:"inquiry_form.form_date"}})],1),_vm._v(" "),_c('el-form-item',[_c('el-input',{attrs:{"type":"textarea","rows":2,"size":"mini","maxlength":"300","placeholder":"Message to reply...","autocomplete":"off"},on:{"input":_vm.handleTextareaChange},model:{value:(_vm.inquiry_form.reply),callback:function ($$v) {_vm.$set(_vm.inquiry_form, "reply", $$v)},expression:"inquiry_form.reply"}}),_vm._v(" "),(_vm.error_msg)?_c('div',{staticClass:"error_msg"},[_vm._v("Reply field is required")]):_vm._e()],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true},on:{"click":_vm.submitReplyData}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true},on:{"click":_vm.handleDialogCancel}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }