var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"warranty-table-sa",attrs:{"no-aside":true}},[_c('div',{staticClass:"div_margin"},[_c('er-dialog',{attrs:{"title":_vm.warrantyActionDialogTitle,"visible":_vm.warrantyActionDialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"40%","custom-class":"warrantyAction_dialog"},on:{"update:visible":function($event){_vm.warrantyActionDialogVisible=$event},"close":_vm.closeWarrantyDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dailogLoading),expression:"dailogLoading"}],attrs:{"size":"small","model":_vm.warrantyForm,"inline":true}},[_c('ValidationProvider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Customer","error":errors[0]}},[_c('el-select',{attrs:{"filterable":"","remote":"","disabled":_vm.action !== 'ADD',"reserve-keyword":"","placeholder":"Please enter a keyword","remote-method":_vm.fetchUsersData,"loading":false},on:{"change":_vm.loadingDevicesDetails},model:{value:(_vm.warrantyForm.user_id),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "user_id", $$v)},expression:"warrantyForm.user_id"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Product Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Product Type","error":errors[0]}},[_c('el-select',{attrs:{"disabled":_vm.action !== 'ADD',"filterable":"","placeholder":"Select Product Type"},on:{"change":_vm.handleDeviceTypeChange},model:{value:(_vm.warrantyForm.device_type),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "device_type", $$v)},expression:"warrantyForm.device_type"}},_vm._l((_vm.deviceTypesOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Device ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Device ID","error":errors[0]}},[_c('el-select',{attrs:{"placeholder":"Select Device ID","disabled":_vm.action !== 'ADD',"filterable":""},on:{"change":_vm.handleDeviceIdChange},model:{value:(_vm.warrantyForm.device_id),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "device_id", $$v)},expression:"warrantyForm.device_id"}},_vm._l((_vm.deviceOptions[_vm.warrantyForm.device_type]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Motor ID"}},[_c('el-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.warrantyForm.motor_name),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "motor_name", $$v)},expression:"warrantyForm.motor_name"}})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Failure Part No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":"Failure Part No","error":errors[0]}},[_c('el-select',{attrs:{"placeholder":"Select Failure Part No","disabled":_vm.action !== 'ADD',"filterable":""},on:{"change":_vm.handleChangePartId},model:{value:(_vm.warrantyForm.part_id),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "part_id", $$v)},expression:"warrantyForm.part_id"}},_vm._l(((!_vm.isDealer ? _vm.getAllPartsData : _vm.getAllDealerPartsData)),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.part_id,"value":item._id}})}),1)],1)]}}])}),_vm._v(" "),_c('el-form-item',{attrs:{"label":"Part Name"}},[_c('el-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.warrantyForm.part_name),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "part_name", $$v)},expression:"warrantyForm.part_name"}})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Issue Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"error":errors[0],"label":"Issue Description"}},[_c('el-input',{staticClass:"warrantyPageTextarea",attrs:{"type":"textarea","rows":4,"size":"mini","autocomplete":"off","placeholder":"Enter Issue Description","disabled":_vm.action !== 'ADD'},model:{value:(_vm.warrantyForm.issue),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "issue", $$v)},expression:"warrantyForm.issue"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Product Failure Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"error":errors[0],"label":"Product Failure Date"}},[_c('el-date-picker',{staticClass:"custom-calendar",attrs:{"type":"date","format":"dd MMM, yy","value-format":"yyyy-MM-dd","placeholder":"select a date","size":"small","clearable":false,"disabled":_vm.action !== 'ADD',"picker-options":_vm.pickerOptions},model:{value:(_vm.warrantyForm.failure_date),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "failure_date", $$v)},expression:"warrantyForm.failure_date"}})],1)]}}])}),_vm._v(" "),(_vm.action !== 'ADD' && _vm.warrantyForm.device_type === 'POND_MOTHER')?_c('el-form-item',{staticClass:"input-feed-type",attrs:{"label":"Replaced"}},[_c('el-checkbox',{attrs:{"disabled":_vm.isReplacebyEnable},model:{value:(_vm.warrantyForm.replaced),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "replaced", $$v)},expression:"warrantyForm.replaced"}})],1):_vm._e(),_vm._v(" "),(_vm.warrantyForm.replaced)?_c('ValidationProvider',{attrs:{"name":"Replaced Motor ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Replaced Motor ID","error":errors[0]}},[_c('er-select',{staticClass:"select-devices",attrs:{"value-key":"code","reserve-keyword":true,"filterable":"","placeholder":"Enter Motor ID"},model:{value:(_vm.warrantyForm.replaced_motor_id),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "replaced_motor_id", $$v)},expression:"warrantyForm.replaced_motor_id"}},_vm._l(((_vm.isDealer ? _vm.getAllMotorsDealerData : _vm.getAllMotorsData)),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.motor_id,"value":item._id}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.motor_id))])])}),1)],1)]}}],null,false,1752411124)}):_vm._e(),_vm._v(" "),(_vm.action !== 'ADD')?_c('ValidationProvider',{attrs:{"name":"Claim Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Claim Status","error":errors[0]}},[_c('el-select',{attrs:{"placeholder":"Select Claim Status"},model:{value:(_vm.warrantyForm.claim_status),callback:function ($$v) {_vm.$set(_vm.warrantyForm, "claim_status", $$v)},expression:"warrantyForm.claim_status"}},_vm._l((_vm.getStatusOptionsData),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}],null,false,1518787390)}):_vm._e()],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('el-row',{attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.handleSaveWarranty}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"loading":_vm.saveLoading},on:{"click":_vm.closeWarrantyDialog}})],1)],1)],2),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"tableProps":_vm.elTableProps,"total":_vm.total,"button":true,"columnselector_require":false,"addPadingToSearch":false,"shouldRefreshVisible":false,"searchable":false,"headerCol":18},on:{"loadData":_vm.handleLoadData,"add-item-click":_vm.handleAddClientClick},scopedSlots:_vm._u([{key:"customer",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_id.full_name)+"\n      ")]}},{key:"product_type",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.deviceTypes[row.data.device_type])+"\n      ")]}},{key:"claim_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.claim_date))+"\n      ")]}},{key:"issue_description",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{staticClass:"job-description-styles",staticStyle:{"font-size":"12px"},attrs:{"placement":"top-start","width":"300","trigger":"hover","content":row.data.issue}},[_c('span',{staticClass:"hiding-extra-reply-text",attrs:{"slot":"reference","type":"text"},slot:"reference"},[_vm._v(_vm._s(row.data.issue)+"\n            ")])])]}},{key:"product_failure_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.failure_date))+"\n      ")]}},{key:"l1_review_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.created_at))+"\n      ")]}},{key:"l2_review_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.cs_reviewed_date))+"\n      ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.claimStatus[row.data.claim_status])+"\n      ")]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('er-button',{attrs:{"type":"text","size":"mini","btnType":"edit","showLabel":false,"showIcon":false,"disabled":_vm.updateDisable(row.data)},on:{"click":function($event){return _vm.handleChangeStatus($event, 'EDIT', row.data)}}},[_vm._v("Update")])]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Claim")])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }