<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayListIndividualDetailsSA.vue
Description:this file contains the ItemListSideMenu(list of gateways),basicCustomerDetails(gateway details), GatewayDetails(form to set the gateway configurations),  available networks,singleDeviceSettingsTable(Latest Sensor Settings)
-->
<template>
  <layout
    v-loading="loading"
    element-loading-background="white"
    class="gateway-individual-details-sa"
  >
    <template slot="deviceListMenu">
      <item-list-side-bar
        :item-list="gatewayList"
        :init-item-id="gateway_id"
        item-type="gateways"
        :showProperty="['code', 'v1_code']"
        :property-seperator="(v1, v2) => `${v1} (${v2})`"
        :dataFetchMethod="loadGatewayData"
        search-str-query-param="device_code"
        @loadData="loadGatewayData"
        @cleared="loadGatewayData"
        @itemClicked="handleGatewaySelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </template>
    <basic-customer-details
      v-loading="loading"
      element-loading-background="white"
      :device-details="deviceDetails"
      :user-details="customerDetails"
      @change-edit="handleEditChange"
    >
      <template slot="moreActions">
        <i
          class="material-icons-round"
          :title="syncStatus.message"
          :style="`color:${syncStatus.color};`"
        >
          sync
        </i>
        <er-button
          v-if="userDevicePermissionCheck"
          btn-type="save"
          size="mini"
          @click="handleRemoveCustomer"
          :disabled="!customerDetails"
        >
          <span class="material-icons-round"> person_remove </span>
        </er-button>
      </template>
    </basic-customer-details>

    <gateway-details
      @refresh="handleGatewaySelected"
      :device-details="deviceDetails"
      :isDisabled="isDisabled"
      :customer-details="customerDetails"
    ></gateway-details>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import gatewayDetails from "@/components/superadmin/gateway/gatewayDetails";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  props: ["gateway_id"],
  components: {
    layout,
    basicCustomerDetails,
    itemListSideBar,
    gatewayDetails
  },
  data: function() {
    return {
      data: { gateway_code: "" },
      loading: false,
      gateway_data: "",
      totalItemsCount: 100,
      isDisabled: true
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getGateways: "getGateways",
      getGatewaysObj: "getGatewaysObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj"
    }),
    syncStatus() {
      return this.deviceDetails.settings_changed
        ? { message: "Settings not updated to device", color: "#E31A1C" }
        : { message: "Settings updated to device", color: "#33A02C" };
    },
    deviceDetails() {
      // return this.getGatewaysObj[this.gateway_id];
      const deviceDetails = { ...this.gateway_data };
      deviceDetails.device_type = "gateway";
      return deviceDetails;
    },
    customerDetails() {
      if (this.deviceDetails) {
        console.log("this.deviceDetails", this.deviceDetails);
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: ""
        };
      }
    },
    gatewayList() {
      return this.getGateways;
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userDevicePermissionCheck() {
      return this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER");
    },
  },
  async mounted() {
    await this.initComponent();
    console.log(this.getUsersObj);
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchADeviceData: "fetchADeviceData",
      fetchAllUsers: "fetchAllUsers",
      deleteDevicesToUser: "deleteDevicesToUser"
    }),
    handleEditChange(changedEditStatus) {
      this.isDisabled = changedEditStatus;
    },
    async handleRemoveCustomer() {
      try {
        await this.$confirm(
          "Your are going to unassign the user from the device, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [this.gateway_id],
          device_type: "GATEWAY"
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        await this.initComponent(this.initFromTimeToTime);
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        await this.fetchAllDeviceDetails({
          device_type: "gateway",
          infinite_scroll: true,
          params: { page: 1, limit: 20 }
        });
        // this.totalItemsCount = gatewayResponse.total;
        await this.fetchAllUsers({ get_all: true });
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
        this.callStoreOnGatewayData(this.gateway_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadGatewayData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllDeviceDetails({
          device_type: "gateway",
          infinite_scroll: infinite_scroll,
          params: query
        });
        // this.tableData = this.$store.getters["superadmin/getPondGuards"];
        this.totalItemsCount = response.total;
        // console.log(response);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnGatewayData(gateway_id) {
      try {
        this.gateway_data = await this.fetchADeviceData({
          device_type: "gateway",
          device_id: gateway_id
        });
        console.log(this.gateway_data);
        // this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleGatewaySelected(gateway_id) {
      try {
        this.loading = true;
        this.$router.replace(gateway_id);
        await this.callStoreOnGatewayData(gateway_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.gateway-individual-details-sa{
  .el-main {
    padding: 4px 0px 10px 0px !important;
    overflow: auto !important;
  }
}
</style>
