<template>
  <div class="pm-stats-main-tab">
    <div class="item-container is-filters-set-relative">
      <div class="table-filters-right">
        <!-- :value="getSelectedDeviceTypes"
          :options="getDeviceTypes" @input="handleDeviceTypeChange"-->
        <el-checkbox style="margin-top: 8px;" v-model="checked">Last 24 Hours</el-checkbox>
        <er-select-with-options
          style="width: 10rem;"
          :value="getSoftwareVersionOptions"
          :options="getSoftwareVersionsData"
          class="select-device-type"
          :showSelectAll="true"
          value-type="string"
          size="mini"
          placeholder="Software Version"
          multiple
          reserve-keyword
          collapse-tags
          @input="handleSoftwareVersionChange"
        >
        </er-select-with-options>
        <span v-if="softwareOptions.length > 3" class="error-Msg">Can't Select More than 3 versions</span>
        <!-- :value="selectedPage"
          :options="pageFilterOptions" :disabled="loading" -->
        <er-select-with-options
          style="width: 10rem;"
          :value="hardwareOptions ? hardwareOptions : getSelectedHardwareVersions"
          :options="getHardwareVersionsData"
          value-type="string"
          value-key="label"
          label-key="label"
          placeholder="Hardware Version"
          class="select-md"
          @input="handleHardwareVersionChange"
        >
        <!-- @input="handlePageSelectionChange" -->
        </er-select-with-options>
        <!-- @click="handleSubmit" -->
        <el-button size="mini" @click="handleSubmit">Submit</el-button>
        <er-dropdown
          placement="bottom"
          @item-click="xceldownload"
          trigger="click"
          :listOfItems="[
            'Comn_download_as_excel'
          ]"
        >
          <template
            class="el-dropdown-link download_button"
            slot="suffix"
          >
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{
            $t(item.value)
          }}</template>
        </er-dropdown>
      </div>
      <er-data-tables
        class="pmStats-table"
        :key="$i18n.locale"
        :tableData="tableData"
        :columns="tableColumns"
        :elTableProps="tableProps"
        :action-column="false"
        :sortMethodForColumns="sortMethods()"
        uniqueId="PM-stats-table"
        type="white-header-table"
        v-loading="getLoading"
        layout="table"
        element-loading-background="white"
        ref="PMStatsTable"
      >
      <template v-slot:user_name="{ row }">
        {{ row.data.user_name }}
      </template>
      <template v-slot:software_versions1_header="{ header }">
        <span>{{ softwareVersionHeader(header.label) }}</span>
      </template>
      <template v-slot:software_versions1="{ row }">
        {{ softwareVersion1TableData(row.data.software_versions) }}
      </template>
      <template v-slot:software_versions2_header="{ header }">
        <span>{{ softwareVersionHeader(header.label) }}</span>
      </template>
      <template v-slot:software_versions2="{ row }">
       {{ softwareVersion2TableData(row.data.software_versions) }}
      </template>
      <template v-slot:software_versions3_header="{ header }">
        <span>{{ softwareVersionHeader(header.label) }}</span>
      </template>
       <template v-slot:software_versions3="{ row }">
        {{ softwareVersion3TableData(row.data.software_versions) }}
      </template>
      <template v-slot:hardware_versions_header="{ header }">
        <span>{{ softwareVersionHeader(header.label) }}</span>
      </template>
       <template v-slot:hardware_versions="{ row }">
        {{ hardwareVersionTableData(row.data.hardware_versions) }}
      </template>
      </er-data-tables>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import exportFileDownload from "@/mixins/exportFileDownload";
export default {
  mixins: [adminDatesHandlerMixin, errorHandlerMixin, filtersMixin, exportFileDownload],
  data() {
    return {
      softwareOptions: [],
      hardwareOptions: '',
      checked: false,
    }
  },
  methods: {
    ...mapActions("superadmin/pmStats", {
      changeSoftwareVersions: "changeSoftwareVersions",
      changeHardwareVersions: "changeHardwareVersions",
      fetchVersionValues: "fetchVersionValues",
      fetchPMStatsData: "fetchPMStatsData"
    }),
    sortMethods() {
      return {
        title: this.sortPondWise
      };
    },
    sortPondWise(a, b) {
      return this.$commonUtils.alphaNumericComparator(a, b);
    },
    handleSoftwareVersionChange(softwareVersions) {
      this.softwareOptions = softwareVersions;
      this.softwareOptions = this.getSoftwareVersionOptions;
      // console.log("versions1111", this.softwareOptions);
      // this.changeSoftwareVersions(softwareVersions);
    },
    handleHardwareVersionChange(hardwareVersions) {
      this.hardwareOptions = hardwareVersions;
      this.softwareOptions = [...this.softwareOptions];
      // this.changeHardwareVersions(hardwareVersions);
    },
    softwareVersionHeader(header) {
      if (header === 'Software Version 1') {
        return this.getSelectedSoftwareVersions[0] ? this.getSelectedSoftwareVersions[0] : 'Software Version 1'
      } else if (header === 'Software Version 2') {
        return this.getSelectedSoftwareVersions[1] ? this.getSelectedSoftwareVersions[1] : 'Software Version 2'
      } else if (header === 'Software Version 3') {
        return this.getSelectedSoftwareVersions[2] ? this.getSelectedSoftwareVersions[2] : 'Software Version 3'
      } else if (header === 'Hardware Version') {
        return this.getSelectedHardwareVersions ? this.getSelectedHardwareVersions : 'Hardware Version'
      }
    },
    softwareVersion1TableData(row) {
      return this.getSelectedSoftwareVersions[0] ? row[this.getSelectedSoftwareVersions[0]] : '--';
    },
    softwareVersion2TableData(row) {
      return this.getSelectedSoftwareVersions[1] ? row[this.getSelectedSoftwareVersions[1]] : '--';
    },
    softwareVersion3TableData(row) {
      return this.getSelectedSoftwareVersions[2] ? row[this.getSelectedSoftwareVersions[2]] : '--';
    },
    hardwareVersionTableData(row) {
      if (this.getSelectedHardwareVersions) {
        return row[this.getSelectedHardwareVersions];
      } else {
        return '--';
      }
    },
    async handleSubmit() {
      this.hardwareOptions = (this.hardwareOptions.length === 0) ? this.getHardwareVersionOptions : this.hardwareOptions;
      this.softwareOptions = this.getSoftwareVersionOptions;
      if (this.softwareOptions.length <= 3) {
        await this.changeSoftwareVersions(this.softwareOptions);
        await this.changeHardwareVersions(this.hardwareOptions);
        await this.fetchPMStatsData(this.checked);
      }
    },
    xceldownload() {
      // x.status === "INACTIVE" ? 0 : x.survival_percentage || 100;
      this.exp__Download(
        this.getPmStatsRawData.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        ),
        this.downloadColumns,
        "xcel"
      );
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (column.label) {
          case "Customer Name":
            sums[index] = this.$t("Comn_total");
            break;
          case "Software Version 1":
            // defaultSummaryCalculator(column, index, this.formatedNumericValue);
            sums[index] = `${this.getFeeds(
              data,
              "software_versions", this.getSelectedSoftwareVersions[0]
            )}`;
            break;
          case "Software Version 2":
            sums[index] = `${this.getFeeds(
              data,
              "software_versions", this.getSelectedSoftwareVersions[1]
            )}`;
            break;
          case "Software Version 3":
            sums[index] = `${this.getFeeds(
              data,
              "software_versions", this.getSelectedSoftwareVersions[2]
            )}`;
            break;
          case "Hardware Version":
            sums[index] = `${this.getFeeds(
              data,
              "hardware_versions", this.getSelectedHardwareVersions
            )}`;
            break;
          default:
            sums[index] = "--";
        }
      });

      return sums;
    },
    getFeeds(list, attribute, index) {
      const values = list.map((item) => isNaN(Math.round(Number(item[attribute][index]))) ? 0 : Math.round(Number(item[attribute][index])));
      if (!values.every((value) => isNaN(value))) {
        const sum = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);

        return sum; //   this.formatedNumericValue(sum, this.userCountryCode);
      } else {
        return "--";
      }
    },
    removePreviousVersion(perviousArray) {
      if (perviousArray.length > 3) {
        perviousArray.shift();
        this.softwareOptions = perviousArray;
      }
    }
  },
  mounted() {
   this.fetchVersionValues();
   this.$nextTick(() => {
      if (this.$refs.PMStatsTable) {
        this.$refs.PMStatsTable.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  computed: {
    ...mapGetters("superadmin/pmStats", {
      getSelectedSoftwareVersions: "getSelectedSoftwareVersions",
      getSelectedHardwareVersions: "getSelectedHardwareVersions",
      getHardwareVersionsData: "getHardwareVersionsData",
      getSoftwareVersionsData: "getSoftwareVersionsData",
      getPmStatsData: "getPmStatsData",
      getPmStatsRawData: "getPmStatsRawData",
      getLoading: "getLoading"
    }),
    getSoftwareVersionOptions() {
      this.removePreviousVersion(this.softwareOptions);
      return this.softwareOptions.length > 0 ? this.softwareOptions : this.getSelectedSoftwareVersions
    },
    getHardwareVersionOptions() {
      return this.getSelectedHardwareVersions
    },
    tableData() {
      return this.getPmStatsData
    },
    tableColumns() {
      return {
        user_name: {
          label: "Customer Name",
          prop: "user_name",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left"
        },
        software_versions1: {
          label: "Software Version 1",
          prop: "software_versions",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left"
        },
        software_versions2: {
          label: "Software Version 2",
          prop: "software_versions",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left"
        },
        software_versions3: {
          label: "Software Version 3",
          prop: "software_versions",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left"
        },
        hardware_versions: {
          label: "Hardware Version",
          prop: "hardware_versions",
          default: true,
          sortable: true,
          minWidth: 100,
          defaultFullTable: true,
          align: "left"
        },
      }
    },
    tableProps: function () {
      return {
        size: "mini",
        showSummary: true,
        summaryMethod: this.getSummaries,
        defaultSort: {
          prop: "title",
          order: "ascending"
        },
        height: "calc(100vh - 140px)"
      };
    },
    downloadColumns() {
      return [
        {
          header: "Customer Name",
          dataKey: "user_name",
          emptyValue: "--",
          name: "user_name",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "PondMother Id",
          dataKey: "pond_mother_code",
          emptyValue: "--",
          name: "pond_mother_code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "PM Type",
          dataKey: "feeder_type",
          emptyValue: "--",
          name: "software_versions2",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "Gateway Code",
          dataKey: "gateway_code",
          emptyValue: "--",
          name: "gateway_code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "S/W Version",
          dataKey: "software_version",
          emptyValue: "--",
          name: "software_version",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "H/W Version",
          dataKey: "hardware_version",
          emptyValue: "--",
          name: "hardware_version",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
        {
          header: "Last Communicated At",
          dataKey: "last_communicated_at",
          emptyValue: "--",
          name: "last_communicated_at",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 25
        },
      ]
    },
  }
}
</script>
<style lang="scss">
.error-Msg{
  position: absolute;
  margin-top: 33px;
  margin-right: 20rem;
  font-size: 10px;
  color: #e84d4d;
}
.pm-stats-main-tab {
  .el-main {
      overflow: hidden !important;
  }
  .el-table__body-wrapper {
    height: calc(100vh - 210px) !important;
  }
  .pagination-bar {
    display: none !important;
  }
}
</style>
