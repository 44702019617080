<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dealerTableSA.vue
Description:This file contains the table that contains the details of the dealers associated with the company  and add new dealers   by admin                     -->
<template>
  <layout :no-aside="true" class="dealer-table-sa">
    <div>
      <create-new-customer
        user-type="DEALER"
        :model="formDealer"
        :action="actionToPerform"
        :visible="actionDialogVisible"
        @close="closeActionDialog"
      ></create-new-customer>

      <settings-table
        v-loading="loading"
        element-loading-background="white"
        :tableData="tableData"
        :columns="columnsObject"
        :tableProps="elTableProps"
        :total="total"
        :button="true"
        placeHolderForSearch="Search_username"
        search-property="user_name"
        :addPadingToSearch="true"
        :columnselector_require="false"
        @cell-click="handleCellClick"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
        @add-item-click="handleAddDealerClick"
      >
        <template slot="add-buttton">Add Dealer</template>

        <template v-slot:name="{ row }">
          {{ row.data.first_name }}
          {{ row.data.last_name }}
        </template>

        <template v-slot:actions="{ row }">
          <el-button-group>
            <er-button size="mini" @click="handleDealerEdit(row.data)">
              <span class="material-icons-round"> edit </span>
            </er-button>
          </el-button-group>
        </template>
      </settings-table>
    </div>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import layout from "@/components/superadmin/shared/layout";
import createNewCustomer from "@/components/superadmin/shared/createNewCustomer";
import User from "@/model/user.js";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      tableData: [],
      total: 0,
      formDealer: new User(),
      saveLoading: false,
      actionDialogVisible: false,
      loading: false,
      selectedClient: {},
      queryParams: {},
      newClient: {},
      usersList: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: "ADD"
    };
  },
  components: {
    settingsTable,
    layout,
    createNewCustomer
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getcountries: "getcountries",
      getUserOffSetTime: "getUserOffSetTime"
    }),
    columnsObject: function () {
      return [
        {
          label: "Name",
          prop: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "email",
          label: "Email",
          required: true,
          sortable: true
        },
        {
          prop: "country.name",
          label: "Country",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Phone",
          required: true,
          sortable: true
        },
        {
          prop: "email_verified",
          label: "Email Verification Status",
          required: true,
          sortable: true
        },

        {
          label: "Actions",
          required: true
        }
      ];
    },
    elTableProps() {
      return {
        height: "calc(100vh - 150px)",
      };
    },
  },
  async mounted() {},
  methods: {
    ...mapActions("superadmin", {
      fetchAllDealers: "fetchAllDealers",
      AddDealer: "AddDealer",
      EditDealer: "EditDealer",
      clearDealerDetails: "clearDealerDetails",
      fetchDealerById: "fetchDealerById"
    }),
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllDealers({
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getDealers"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    async handleCellClick(row, column, cell, event) {
      console.log(row);
      if (column.label === this.columnsObject[0].label) {
        this.clearDealerDetails();
        this.$router.push({
          name: "dealerDetails",
          params: { dealer_id: row._id }
        });
      }
    },
    handleAddDealerClick() {
      this.actionToPerform = "ADD";
      this.formDealer = new User();
      this.showActionDialog(true);
    },
    handleDealerEdit(dealerDetails) {
      this.actionToPerform = "EDIT";
      this.formDealer = dealerDetails;
      this.showActionDialog(true);
    },
    showActionDialog(value) {
      this.actionDialogVisible = value;
    },
    async closeActionDialog(message) {
      this.showActionDialog(false);
      if (message === "user_created") {
        await this.handleLoadData(this.queryParams);
      }
    }
  }
};
</script>

<style lang="scss">
.dealer-table-sa {
  .material-icons-round {
    font-size: 14px;
  }

  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 84vh, 87vh);
  }
}
</style>
