<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmSuperAdminSlotWiseFeedGraph.vue
Description: This file is the chart component that displays the feed dispensed by timeslot wise by pondmother. Here x axis is time and y axis represents the amount of feed dispensed.
-->
<template>
  <div class="main-container">
    <div class="main-container__chart">
      <chart-in-card
        :updateChart="acm__updateChart"
        :chartOptions="chartOptions"
        chartTitle="PondMother Slot Wise Feed Graph"
        :date-query="true"
        :time-query="false"
        @dateChanged="acm__handleDateQueryChange"
        @onSeriesClick="handleSeriesClick"
      ></chart-in-card>
    </div>
    <div class="main-container__table">
      <div class="main-container__table__link">
        <span>
          View Changes
        </span>
        </div>
      <el-table :data="timeSlotsContainer" style="width: 100%">
        <el-table-column prop="labelNumber" label="Sch"> </el-table-column>
        <el-table-column prop="start_time" label="From">
        </el-table-column>
        <el-table-column prop="end_time" label="To">
        </el-table-column>
        <el-table-column prop="feed" label="TF"> </el-table-column>
        <el-table-column prop="dFeed" label="DF"> </el-table-column>
      </el-table>
      <div class="main-container__table__note">
        <span class="addr">Note : </span>
        <span class="addr">Sch : Schedules, </span>
        <span class="addr">TF : Total Feed, </span>
        <span class="addr">DF : Dispensed Feed</span>
      </div>
    </div>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { pmScheduleGraph } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: { ...pmScheduleGraph },
      timeSlotsContainer: []
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPmSlotFeedData");
    this.acm__initParameterName("slot_wise_feed");
    this.acm__initComponent();
  },
  methods: {
    handleSeriesClick(event, highChartPointtScope) {
      console.log(
        "event from pmsuper",
        event,
        highChartPointtScope.tooltip_value
      );
      const { timeSlots } = highChartPointtScope.tooltip_value;
      this.timeSlotsContainer = [...timeSlots];
      this.timeSlotsContainer.map((item, index) => {
         item.labelNumber = `S${index + 1}`;
      });
    },
    acm__initComponent() {
      this.acm__initChartSeries("PondMother Slotwise Feed Graph", "line");
    },
    acm__initChartSeries(seriesName, chartType) {
      this.chartOptions.series = this.acm__getStoreData;
      // [
      //   {
      //     name: "Feed",
      //     data: this.acm__getStoreData.hourlyFeed,
      //     type: "line",
      //     visible: true,
      //     pointWidth: 10
      //   },
      //   {
      //     name: "Dispensed Feed",
      //     data: this.acm__getStoreData.dispensedHourlyFeed,
      //     type: "line",
      //     visible: true,
      //     pointWidth: 10
      //   }
      // ];
    }
  }
};
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  justify-content: space-between;
  .main-container__table__note {
    span {
      font-size: 13px !important;
    }
  }
  &__chart {
    width: 60%;
    max-width: 60%;
  }
  &__table {
    width: 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    &__link {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0px;

      span {
        color: #409EFF;
        font-size: 16px;
      }
    }
    .el-table {
      max-height: 70%;
    }
  }
}
</style>
