<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: smallTable.vue
Description:This file contains the table  where the data will be shown
-->
<template>
  <div class="small-table" :class="{ 'padding-10-0': addPadingToSearch }">
    <el-row type="flex">
      <el-col>
        <h3 class="small-table-title">{{ tableName }}</h3>
      </el-col>
      <el-col style="display: flex; justify-content: flex-end">
        <el-input
          v-model="filters[0].value"
          style="width: 200px"
          size="mini"
          :placeholder="`Search by ${searchOnProperty.replace('_', ' ')}`"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="padding-10-0">
      <data-tables
        :data="tableData"
        :tableProps="computedTableProps"
        :page-size="$constants.table['page-size']"
        :pagination-props="$constants.table['small-table-pagination-props']"
        :filters="filters"
        @sort-change="handleSortChange"
      >
        <template v-for="(column, index) in columns">
          <el-table-column
            :key="index"
            :label="column.label"
            :prop="column.prop"
            :min-width="column.minWidth"
            sortable="custom"
            v-if="column.required"
          >
            <template slot-scope="scope">
              <slot
                :name="
                  column.label
                    .toLowerCase()
                    .split(' ')
                    .join('_')
                "
                v-bind:row="{ data: scope.row, path: column.prop }"
              >
                <template
                  v-if="getRowValue(scope.row, column).type === 'array'"
                >
                  <el-tag
                    size="mini"
                    effect="dark"
                    v-for="(val, index) in getRowValue(scope.row, column).value"
                    :key="index"
                    >{{ val }}</el-tag
                  >
                </template>
                <template
                  v-else-if="column.prop === 'utc_last_communicated_at'"
                >
                  <el-tag
                    size="mini"
                    type="success"
                    effect="dark"
                    v-if="
                      getStatusBasedOnDate(
                        scope.row.utc_last_communicated_at,
                        column.device_type
                      )
                    "
                    >{{ getRowValue(scope.row, column).value }}</el-tag
                  >
                  <template v-else>{{
                    getRowValue(scope.row, column).value
                  }}</template>
                </template>
                <template v-else>{{
                  getRowValue(scope.row, column).value
                }}</template>
              </slot>
            </template>
            <slot
              :name="
                `${column.label
                  .toLowerCase()
                  .split(' ')
                  .join('_')}NestedColumn`
              "
            ></slot>
          </el-table-column>
        </template>
        <slot name="actionColumn"></slot>
      </data-tables>
    </el-row>
  </div>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
export default {
  props: [
    "columns",
    "tableData",
    "searchOnProperty",
    "addPadingToSearch",
    "tableName",
    "height",
    "tableProps"
  ],
  data: function() {
    return {
      defaultTableProps: {
        size: "mini",
        border: true
        // height: this.height || "210px",
      },
      searchStr: "",
      filters: [
        {
          value: "",
          filterFn: (row, filter) => {
            return Object.keys(row).some((prop) => {
              if (prop === this.searchOnProperty) {
                return (
                  row[prop].toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              }
            });
          }
        }
      ]
    };
  },
  computed: {
    data() {
      return this.tableData;
    },
    computedTableProps() {
      return {
        ...this.defaultTableProps,
        ...(this.tableProps || {})
      };
    }
  },
  methods: {
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;
      const device_type = columnObject.device_type;
      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        const valueTemp =
          value && value !== "--"
            ? {
                type: "string",
                value: dateUtils.formatDate(
                  new Date(value),
                  this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
                )
              }
            : { type: "string", value: "--" };
        if (path === "utc_last_communicated_at") {
          this.getStatusBasedOnDate(value, device_type);
        }
        return valueTemp;
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    getStatusBasedOnDate(date, device_type) {
      if (!date) {
        return false;
      }

      try {
        const oldDate = new Date(date);
        const currentDate = new Date();

        return (
          (currentDate - oldDate) / 1000 <
          this.getInactiveTimeToCheck(device_type, device_type)
        );
      } catch (err) {
        return false;
      }
    },
    getInactiveTimeToCheck(device_type) {
      let time = 1800;
      switch (device_type) {
        case "pond_mother":
          time = 1800;
          break;
        case "pond_guard":
          time = 1800;
          break;
        case "shrimp_talk":
          time = 900;
          break;
        default:
          time = 1800;
          break;
      }
      return time;
    },
    handleSortChange({ column, prop, order }) {
      const componentColumns = this.columns;
      const arrColumns = Array.isArray(componentColumns)
        ? componentColumns
        : Object.values(componentColumns);
      const arrFilteredColumns = arrColumns.filter((x) => x.prop === prop);
      if (arrFilteredColumns[0].type === "date") {
        this.tableData.sort((a, b) => {
          let first = a[prop];
          let second = b[prop];
          if (order === "descending") {
            first = b;
            second = a;
          }
          return dateUtils.dateComparator(first, second, true);
        });
      } else {
        this.tableData.sort((a, b) => {
          if (order === "descending") {
            return a > b;
          } else {
            return b > a;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.small-table {
  width: 520px;
  height: calc(100vh - 50px) !important;;
  .sc-table {
    div.cell .el-link.el-link--default {
      font-size: 12px;
      letter-spacing: 0.9px;
    }
    .pagination-bar {
      .pagination-eruvaka {
        margin-top: 0.5rem;
        button {
          background-color: #efefef;
          border: none;
          cursor: pointer;
          border: 1px solid #eee5e5;
          border-radius: 2px;
          margin-right: 4px;
          height: 23px;
        }
        .el-pagination__jump {
          font-size: 13px;
          margin-left: 15px !important;
          .el-input__inner {
            padding: 0 15px !important;
            height: 25px;
          }
        }
        .el-pagination__editor {
          margin-left: 5px;
          font-size: 13px;
          .el-pagination__editor.el-input .el-input__inner {
            height: 26px !important;
          }
        }
        .el-pagination__sizes {
          margin-left: 5px;
          .el-select {
            width: 6rem;
            margin-left: 9rem;
            margin-top: 0.5rem;
          }
          .el-input--mini .el-input__inner {
            height: 25px !important;
          }
        }
        .el-pagination__total {
          font-size: 13px;
          margin-top: -1.4rem;
          position: absolute;
          margin-left: 2rem;
        }
        .el-pager li {
          min-width: 1.5rem !important;
          border: 1px solid #eee5e5;
          border-radius: 2px;
          margin-right: 4px;
          line-height: 21px !important;
          height: 23px !important;
        }
        .el-pager li.btn-quicknext {
          line-height: 18px;
          height: 23px !important;
          color: #303133;
        }
        .el-pager {
          line-height: 12px;
          margin-top: 1px;
        }
        .el-pager li.active {
          background-color: #409EFF;
          color: #ffffff;
          cursor: default;
        }
      }
    }
  }
}

.small-table {
  .el-input__inner {
    text-transform: uppercase;
  }
  .el-input__inner::-webkit-input-placeholder {
    text-transform: initial;
  }
  .el-input__inner:-moz-placeholder {
    text-transform: initial;
  }
  .el-input__inner::-moz-placeholder {
    text-transform: initial;
  }
  .el-input__inner:-ms-input-placeholder {
    text-transform: initial;
  }
}

.padding-10-0 {
  padding: 10px 0;
}

.small-table-title {
  font-weight: 500;
  color: #000000cf;
}
.el-table th > .cell {
  font-weight: bold;
  color: #233a73;
  line-height: 120%;
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}

.el-table tr {
  color: #606266;
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.el-table td,
.el-table th {
  padding: 6px 0;
}
.el-table--border,
.el-table--group {
  border: none;
}
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}
</style>
