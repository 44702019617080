<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerTableSA.vue
Description: This file contains the list of the Account Manager associated with the company and add new Account by the access of the superadmin
-->
<template>
  <layout-accountmanager-card
    toolbar-layout=""
    @loadData="handleLoadData"
    v-loading="loading"
    :no-aside="true"
    class="account-table-sa"
  >
    <!-- <div class="page_loading" v-if="loading"></div> -->
    <template v-slot:heading>
      <div style="display: flex; align-items: center">
        <img src="@/assets/dealer/peoplegroup.svg" atl="down arrow" />
        <p style="margin-left: 20px">
          {{ `${$t("skretting-technician")}` }}
        </p>
      </div>
    </template>
    <template slot="table">
      <div
        v-loading="loading"
        v-show="!loading"
        class="skretting-technician-container"
      >
        <create-new-customer
          user-type="SKRETTING_TECHNICIAN"
          :model="formAccountManager"
          :action="actionToPerform"
          :visible="actionDialogVisible"
          @close="closeActionDialog"
        ></create-new-customer>
        <skrettingTechnicianDefaultTable
          v-loading="loading"
          element-loading-background="white"
          :tableData="filterTableData"
          :columns="columnsObject"
          :total="total"
          :button="true"
          :searchable="true"
          :tableProps="tableProps"
          :addPadingToSearch="true"
          :columnselector_require="false"
          placeHolderForSearch="Search_by_technician_name"
          search-property="full_name"
          @cell-click="handleCellClick"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
          @add-item-click="handleAddClientClick"
        >
          <template slot="add-buttton">{{ $t("add-technician") }}</template>
          <template v-slot:technician-name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:comn_email_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:customer-count_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:phone_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:status_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:comn_actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:name="{ row }">
            {{ row.data.first_name }} {{ row.data.last_name }}
          </template>
          <template v-slot:first_name_header="{ header }">
            {{ $t(header.label) }}
          </template>
          <template v-slot:email_verified="{ row }">
            {{ row.data.email_verified }}
          </template>
          <template v-slot:status="{ row }">
            <div
              :class="[
                'user-status-tag',
                getStatus(row.data.status).status === 'ACTIVE' && 'is-active'
              ]"
            >
              <p>{{ getStatus(row.data.status).lang }}</p>
            </div>
          </template>
          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button size="mini" @click="handleEditClientClick(row.data)">
                <span class="material-icons-round"> edit </span>
              </er-button>
              <er-button
                size="mini"
                @click="handleInactiveClientClick(row.data)"
              >
                <span class="material-icons-minimize"
                  ><i class="el-icon-minus"></i>
                </span>
              </er-button>
            </el-button-group>
          </template>
        </skrettingTechnicianDefaultTable>
      </div>
    </template>
  </layout-accountmanager-card>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
// import settingsTable from "@/components/base/settingsTable";
import createNewCustomer from "./createNewCustomer";
// import layout from "@/components/superadmin/shared/layout";
import layoutAccountmanagerCard from "./layoutAccountmanagerCard";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import User from "@/model/skrettingtechnician.js";
import skrettingTechnicianDefaultTable from "./skrettingTechnicianDefaultTable";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, notificationMixin],
  data: function() {
    return {
      searchString: "",
      tableData: [],
      formAccountManager: new User(),
      countries: {},
      user: {},
      formName: "signUpForm",
      countryCode: "",
      loading: true,
      ehm__errMessagesObject: new User(),
      hasError: false,
      total: 0,
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      actionDialogVisible: false,
      dialogVisible: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      newClient: {},
      usersList: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name", "email"],
        un_assigned_only: true
      },
      actionToPerform: "ADD",
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      }
    };
  },
  components: {
    createNewCustomer,
    skrettingTechnicianDefaultTable,
    layoutAccountmanagerCard
  },
  computed: {
    ...mapGetters("superadmin", {
      getCountries: "getCountries"
    }),
    ...mapGetters("accountManager", {
      getSkrettingTechnicians: "getSkrettingTechnicians"
    }),
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        // {
        //   label: this.$t("Comn_country"),
        //   placeholder: this.$t("Usrs_enter_country")
        // },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    },
    columnsObject: function() {
      return [
        {
          label: "technician-name",
          prop: "full_name",
          name: "technician-name",
          required: true,
          sortable: true
        },
        {
          prop: "email",
          name: "Comn_email",
          label: "Comn_email",
          required: true,
          sortable: true
        },
        // {
        //   prop: "country.name",
        //   label: "Country",
        //   required: true,
        //   sortable: true
        // },
        {
          prop: "userCount",
          name: "customer-count",
          label: "customer-count",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          name: "phone",
          label: "phone",
          required: true,
          sortable: true
        },
        {
          prop: "status",
          name: "status",
          label: "status",
          required: true,
          sortable: true
        },
        // {
        //   prop: "email_verified",
        //   label: "Email Verification Status",
        //   required: true,
        //   sortable: true
        // },
        {
          label: "Comn_actions",
          name: "Comn_actions",
          prop: "actions",
          required: true
        }
      ];
    },
    filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.full_name.toLowerCase().includes(this.searchString);
      });
    }
  },
  methods: {
    ...mapActions("accountManager", {
      fetchAllSkrettingTechnicians: "fetchAllSkrettingTechnicians",
      deActivateSkrettingTechnician: "deActivateSkrettingTechnician"
    }),
    ...mapActions("superadmin", {
      fetchCountryList: "fetchCountryList"
    }),
    getStatus(type) {
      if (type === "INACTIVE") {
        return { status: "INACTIVE", lang: this.$t("Comn_inactive") };
      } else {
        return { status: "ACTIVE", lang: this.$t("Comn_active") };
      }
    },
    getNewClient() {
      return {
        title: "",
        users: []
      };
    },
    showActionDialog(value) {
      this.actionDialogVisible = value;
    },
    async closeActionDialog(message) {
      this.showActionDialog(false);
      if (message === "user_created") {
        await this.handleLoadData(this.queryParams);
      }
    },
    handleTabClick(tab) {},
    async handleSelectedComponent(component) {
      this.tabName = component;
      // await this.handleLoadData(this.queryParams);
    },
    // handleAddUsersToClient(clientDetails) {
    //   this.action = "ADD_CLIENT_USERS";
    //   this.clientActionDialogTitle = "Add Client Users";
    //   this.actionDialogVisible = true;
    // },

    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllSkrettingTechnicians({
          infinite_scroll: false,
          params: { ...query, status: 'ACTIVE', order_by: "email", order_type: "asc" }
        });
        this.tableData = this.$store.getters[
          "accountManager/getSkrettingTechnicians"
        ];
        this.tableData.map((item, i) => {
          item.userCount = item.allowed_customers.length;
        });
        this.total = response.data?.sub_users.length;
        // if (!this.query?.full_name) return this.tableData;
        // return this.tableData.filter((data) => {
        //   return data.full_name.toLowerCase().includes(this.this.query?.full_name);
        // });
        // this.filterTableData(query)
        this.searchString = query?.full_name;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    handleCellClick(row, column, cell, event) {
      console.log("row, column", row, column);
      if (column.label === this.columnsObject[0].label) {
        this.$router.push({
          name: "subuserdetails",
          params: { subuser_id: row._id }
        });
      }
    },
    handleAddClientClick() {
      this.actionToPerform = "ADD";
      this.formAccountManager = new User();
      this.clientActionDialogTitle = "Add Technician";
      this.selectedClient = this.getNewClient();
      this.actionDialogVisible = true;
    },
    handleEditClientClick(acManagerDetails) {
      this.actionToPerform = "EDIT";
      this.formAccountManager = acManagerDetails;
      this.clientActionDialogTitle = "Edit Technician";
      this.actionDialogVisible = true;
    },
    async handleInactiveClientClick(acManagerDetails) {
      console.log(acManagerDetails);
      const {
        first_name,
        last_name,
        email,
        phone,
        user_type,
        _id
      } = acManagerDetails;
      const payload = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        user_type: user_type,
        status: "INACTIVE",
        _id: _id
      };
      const res = this.nofm__deleteConfirmation(
        acManagerDetails.first_name,
        "table"
      );
      res
        .then(async (val) => {
          const res = await this.deActivateSkrettingTechnician(payload);
          console.log("res", res);
          if (res?.success) {
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("skretting-technician-deleted"),
              duration: 10000,
              type: "success"
            });
          }
          await this.handleLoadData(this.queryParams);
          // this.tableData = this.$store.getters[
          //   "accountManager/getSkrettingTechnicians"
          // ];
          this.tableData.map((item, i) => {
            item.userCount = item.allowed_customers.length;
          });
        })
        .catch((err) => console.log(err));
    },
    closeAddClientDialog() {
      this.actionDialogVisible = false;
      this.action = "";
    },
    handleAddClientDialogOpen() {
      delete this.userQuery.user_name;
      this.usersList = [];
      // this.$nextTick(() => {
      //   this.$refs.dialogListForm.reset();
      // });
    }
  },
  //   async initComponent() {
  //     await this.fetchCountryList();
  //   }
  // },

  mounted() {
    document.getElementsByClassName("dealer-menu").item(0).style.zIndex = 0;
  }
};
</script>

<style lang="scss">
.settings-table .padding-10-0 {
  padding: 10px 12px;
}
.v-model {
  position: inherit !important;
}
.user-status-tag {
  background: #f2f0f9;
  border-radius: 10px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a2463;
  &:before {
    content: "\2B24";
    color: #0a2463;
    font-size: 13px;
    margin-right: 5px;
    font-size: 8px;
  }
  &.is-active {
    background: #33a02c;
    color: white;
    &:before {
      color: white;
    }
  }
  &.not-active {
    content: "\2B24";
    color: #0a2463;
    font-size: 13px;
    margin-right: 5px;
    font-size: 8px;
  }
  p {
    margin-bottom: 3px;
  }
}
.account-table-sa {
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    position: inherit !important;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 0;
    background-color: white;
    text-align: center;
  }
  .material-icons-round {
    font-size: 14px;
  }
  // .skretting-technician-default-table .settings-table-scroll {
  //   position: relative;
  //   @include responsiveProperty(height, 80vh, 84vh, 87vh, true);
  // }
  .skretting-technician-container {
    // @include responsiveProperty(height, 80vh, 70vh, 76vh);
    height: 100% !important;
    margin-right: 10px;
    // margin-top: 30px;
    .el-dialog {
      border-radius: 10px;
    }
    .skretting-technician-default-table {
      .table-filters-right {
        position: relative;
        top: -63px;
    }
      .padding-10-0 {
        padding: 5px 12px !important;
    }
    }
  }
  .skretting-technician-default-table .settings-table-scroll {
    position: relative;
    top: -40px;
    height: 95% !important;
    // @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
  .el-card__header {
    padding: 10px 10px !important;
  }
}
</style>
