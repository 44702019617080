<template>
  <div class="preview-release-container">
    <template slot="title">
      PondLogs Release {{ previewDataSource.version_number }}</template
    >
    <template>
      <div
        class="preview-feature-container"
        v-if="previewDataSource.features.length > 0"
      >
        <div class="title">
          <div>
            <img
              src="@/assets/help/New Feature.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title-label">
            <span class="label">Features:</span>
          </div>
        </div>
        <ol class="item">
          <li v-for="(item, index) of previewDataSource.features" :key="index">
            {{ item.title }}
          </li>
        </ol>
      </div>
      <div
        class="preview-enhancement-container"
        v-if="previewDataSource.enhancements.length > 0"
      >
        <div class="title">
          <div>
            <img
              src="@/assets/help/Enhancement.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title-label">
            <span class="label">Enhancements:</span>
          </div>
        </div>
        <ol class="item">
          <li
            v-for="(item, index) of previewDataSource.enhancements"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ol>
      </div>
      <div
        class="preview-bug-container"
        v-if="previewDataSource.bugs.length > 0"
      >
        <div class="title">
          <div>
            <img
              src="@/assets/help/Bug.svg"
              style="width: 13px; height: 13px;"
            />
          </div>
          <div class="title-label">
            <span class="label">Bugs:</span>
          </div>
        </div>
        <ol class="item">
          <li v-for="(item, index) of previewDataSource.bugs" :key="index">
            {{ item.title }}
          </li>
        </ol>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["previewData"],
  data() {
    return {
      previewDataSource: this.previewData
    };
  },
  watch: {},
  computed: {
    getReleaseVersion() {
      console.log("this.previewData", this.previewData);
      return this.previewData?.version_number;
    }
  }
};
</script>

<style lang="scss">
// .ql-video {
//   margin-top: 10px;
//   margin-left: 48px !important;
// }
.preview-release-container {
  margin: 6px 0;
  .Preview_Version {
    border-radius: 10px !important;
    overflow: hidden;
    .el-dialog__header {
      background-color: #155dbe;
    }
    .el-dialog__title {
      font-size: 18px !important;
      color: #fff !important;
      font-weight: 500 !important;
      opacity: 0.8 !important;
    }
    .el-dialog__close {
      color: #000 !important;
    }
  }
  .title {
    display: flex;
    color: #0a2463;
    font-weight: 500;
    font-size: 16px;
    margin: 10px 0;
  }
  .title-label {
    margin-left: 5px;
  }
  .item {
    color: #0a2463;
    font-weight: 500;
    font-size: 13px;
    padding: 4px;
    margin-left: 24px;
    line-height: 19px;
  }
  .preview-feature-container,
  .preview-enancement-container,
  .preview-bug-container {
    .title-label {
      font-weight: 600;
      line-height: 20px;
    }
    // margin-bottom: 15px;
  }
  .preview-feature-container {
    .title-label {
      color: #67c23a;
      font-weight: 600;
    }
  }
  .preview-enancement-container {
    .title-label {
      color: #409eff;
    }
  }
  .preview-bug-container {
    .title-label {
      color: #e6a23c;
    }
  }
  .el-dialog__body {
    font-size: 13px !important;
    color: #0a2463;
  }
  .er-button {
    font-size: 13px !important;
  }
}
</style>
