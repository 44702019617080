<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Hopper Empty Height"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { hopperEmptyHightGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard,
  },
  data: function () {
    return {
      chartOptions: hopperEmptyHightGraphConfig,
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPMHopperEmptyHeightData");
    this.acm__initParameterName("hopper_empty_height");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Hopper Empty Height", "line");
    },
  },
};
</script>

<style lang="scss"></style>
