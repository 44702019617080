<template>
  <er-dialog
    width="35%"
    :visible="dialogVisible"
    :modal-append-to-body="false"
    :append-to-body="true"
    :title="ftm__capitalize($t('dealer.assign_shrimptalk'))"
    custom-class="shrimpTalk_dialog"
    :close-on-click-modal="false"
    @open="handleOpenDialog"
    @close="handleCloseDialog"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form size="small" :inline="true">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <el-form-item
            label-width="130px"
            :label="formStrings.selectedSTs.label"
            :error="errors[0]"
          >
            <er-select
              class="select-devices"
              :showSelectAll="true"
              v-model="selectedSTs"
              value-key="_id"
              collapse-tags
              reserve-keyword
              multiple
              filterable
              :placeholder="formStrings.selectedSTs.placeholder"
              remote
              :loading="stListLoading"
              :remote-method="fetchDevicesToAssign"
            >
              <el-option
                v-for="item in unassignedSTs"
                :key="item._id"
                :label="`${item.code}`"
                :value="item"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <el-form-item
            label-width="130px"
            :label="formStrings.selectedUsers.label"
            :error="errors[0]"
          >
            <er-select
              class="select-devices"
              :showSelectAll="false"
              v-model="selectedUsers"
              value-key="_id"
              collapse-tags
              filterable
              :placeholder="formStrings.selectedUsers.placeholder"
              remote
              :loading="userListLoading"
              :remote-method="fetchUsersToAssign"
            >
              <el-option
                v-for="item in unassignedUsers"
                :key="item._id"
                :label="`${item.first_name} ${item.last_name} (${item.email})`"
                :value="item"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
      </el-form>
    </ValidationObserver>
    <template slot="footer">
      <er-button
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="handleAssignDevicesToUser(selectedSTs, 'SHRIMP_TALK')"
      ></er-button>
      <er-button
        btnType="cancel"
        :showLabel="true"
        :disabled="loading"
        @click="handleCloseDialog"
      ></er-button>
    </template>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import dealerDevicesMixin from "../dealerDevicesMixin";

export default {
  mixins: [errorHandlerMixin, filtersMixin, dealerDevicesMixin("DIALOG")],
  props: {
    dialogVisible: {
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      unassignedSTs: [],
      unassignedUsers: [],
      selectedUsers: [],
      selectedSTs: [],
      listLoading: false,
      stListLoading: false,
      userListLoading: false,
      stSearchQuery: {},
      userSearchQuery: {}
    };
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerAssignSts: "getDealerAssignSts",
      getDealerUsers: "getDealerUsers",
      getDealerUnassignSts: "getDealerUnassignSts"
    }),
    formStrings() {
      return {
        selectedUsers: {
          label: this.$t("DL_select_user"),
          placeholder: this.$t("Comn_select")
        },
        selectedSTs: {
          label: this.$t("select_shrimp_talks"),
          placeholder: this.$t("Comn_select")
        }
      };
    }
  },
  async mounted() {
    await this.handleOpenDialog();
  },
  methods: {
    ...mapActions("dealer", {
      fetchAllUnassignedDealerDevices: "fetchAllUnassignedDealerDevices",
      fetchDealerRelatedUsers: "fetchDealerRelatedUsers"
    }),
    async fetchDevicesToAssign(queryString) {
      try {
        if (queryString === "") return;
        this.stSearchQuery.device_code = queryString;
        if (queryString && queryString.toUpperCase().startsWith("ST")) {
          this.stSearchQuery.device_code = queryString.substr(2).trim();
        }
        this.stListLoading = true;
        await this.fetchAllUnassignedDealerDevices({
          infinite_scroll: false,
          device_type: "shrimp_talk",
          params: this.stSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.stListLoading = false;
        this.unassignedSTs = this.getDealerUnassignSts;
      }
    },
    handleOpenDialog(event) {
      this.unassignedSTs = [];
      this.unassignedUsers = [];
      this.initValidationStrings();
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
      this.selectedUsers = [];
      this.selectedSTs = [];
    },
    initValidationStrings() {
      extend("required", {
        ...required,
        message: this.$t("error_msg")
      });
    },
    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.listLoading = true;
        this.userSearchQuery.user_name = queryString;
        await this.fetchDealerRelatedUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.listLoading = false;
        this.unassignedUsers = this.getDealerUsers;
      }
    },
    handleCloseDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style lang="scss" scoped></style>
