<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: generateForm.vue
Description:This file contains the  schema array that generates the form used in all devices except feedblowers
-->
<template>
  <el-row class="generate-form">
    <el-col>
      <slot name="header"></slot>
    </el-col>
    <el-col>
      <ValidationObserver ref="generatedFormValidator">
        <el-form
          size="mini"
          v-bind="elFormOptions"
          :class="computedClass"
          :disabled="disabled"
          class=""
        >
          <ValidationProvider
            v-for="(item, index) in getSortedSchema"
            :key="`${item.model}_form_item_${index}`"
            :name="item.label"
            :vid="item.id || item.model"
            :rules="item.validationRules"
            v-slot="{ errors }"
            class="form-cell"
          >
            <el-form-item :label="item.label" :error="errors[0]">
              <component
                :is="schemaTypeToComponent[item.type].componentName"
                :value="model[item.model]"
                v-bind="schemaTypeToComponent[item.type].props(item.props)"
                v-on="schemaTypeToComponent[item.type].eventMethods(item.model)"
              >
              </component>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>
    </el-col>
    <el-col>
      <slot name="footer"></slot>
    </el-col>
  </el-row>
</template>

<script>
import {
  Button,
  Select,
  Input,
  InputNumber,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  TimePicker,
  Slider,
  Switch as Toggle
} from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import dateUtils from "@/utils/dateUtils";
// time picker format
const TIME_PICKER_FORMAT = "HH:mm:00";

extend("required", {
  ...required
});
extend("validPauseStart", {
  params: ["start_time", "pause_end"],
  validate: function(value, { start_time, pause_end }) {
    if (value === pause_end) {
      return true;
    }
    const value1 = dateUtils.parse(value, TIME_PICKER_FORMAT, new Date());
    const value2 = dateUtils.parse(start_time, TIME_PICKER_FORMAT, new Date());
    const value3 = dateUtils.parse(pause_end, TIME_PICKER_FORMAT, new Date());
    const conditions = [
      dateUtils.isBefore(value2, value1),
      dateUtils.isBefore(value1, value3)
    ];

    if (conditions.every((x) => x)) {
      return true;
    }
    return false;
  },
  message: "Pause Start should be  \u003e  Start Time and \u003c Pause End"
});
extend("validPauseEnd", {
  params: ["end_time", "pause_start"],
  validate: function(value, { end_time, pause_start }) {
    if (value === pause_start) {
      return true;
    }
    const value1 = dateUtils.parse(value, TIME_PICKER_FORMAT, new Date());
    const value2 = dateUtils.parse(end_time, TIME_PICKER_FORMAT, new Date());
    const value3 = dateUtils.parse(pause_start, TIME_PICKER_FORMAT, new Date());
    const conditions = [
      dateUtils.isAfter(value1, value3),
      dateUtils.isAfter(value2, value1)
    ];
    if (conditions.every((x) => x)) {
      return true;
    }
    return false;
  },
  message: "Pause End should be \u003e Pause Start and \u003c End Time"
});
extend("validFormat", {
  params: ["format"],
  validate: function(value, { format }) {
    switch (format) {
      case "JSON":
        try {
          JSON.parse(value);
          return true;
        } catch (e) {
          return false;
        }
      default:
        return false;
    }
  }
});
extend("timeLessThan", {
  params: ["target", "format"],
  validate: function(value, { target, format = TIME_PICKER_FORMAT }) {
    const value1 = dateUtils.parse(value, format, new Date());
    const value2 = dateUtils.parse(target, format, new Date());
    return dateUtils.isBefore(value1, value2);
  },
  message: "{_field_} should be \u003c {target}"
});
extend("timeGreaterThan", {
  params: ["target", "format"],
  validate: function(value, { target, format = TIME_PICKER_FORMAT }) {
    const value1 = dateUtils.parse(value, format, new Date());
    const value2 = dateUtils.parse(target, format, new Date());
    return dateUtils.isAfter(value1, value2);
  },
  message: "{_field_} should be \u003e {target}"
});
extend("timeLessThanOrEquals", {
  params: ["target"],
  validate: function(value, { target }) {
    const value1 = dateUtils.parse(value, TIME_PICKER_FORMAT, new Date());
    const value2 = dateUtils.parse(target, TIME_PICKER_FORMAT, new Date());
    return (
      dateUtils.isBefore(value1, value2) || dateUtils.isEqual(value1, value2)
    );
  },
  message: "{_field_} should be \u003c {target}"
});
extend("timeGreaterThanOrEquals", {
  params: ["target"],
  validate: function(value, { target }) {
    const value1 = dateUtils.parse(value, TIME_PICKER_FORMAT, new Date());
    const value2 = dateUtils.parse(target, TIME_PICKER_FORMAT, new Date());
    return (
      dateUtils.isAfter(value1, value2) || dateUtils.isEqual(value1, value2)
    );
  },
  message: "{_field_} should be \u003e {target}"
});
extend('between', {
  params: ['min', 'max'],
  validate(value, { min, max }) {
    return value >= min && value <= max;
  },
  message: '{_field_} must be between {min} and {max}'
});
export default {
  components: {
    Input,
    InputNumber,
    Select,
    Button,
    Checkbox,
    CheckboxGroup,
    DatePicker,
    TimePicker,
    Slider,
    Toggle
  },
  props: {
    schema: {
      type: Array,
      default: () => []
    },
    model: {
      default: () => ({})
    },
    elFormOptions: {
      default: () => ({ size: "mini", labelPosition: "left", layout: "list" })
    },
    orderFieldsBy: {
      type: String,
      default: "label"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClass() {
      return {
        "er-form--layout-list": this.elFormOptions.layout === "list",
        "er-form--layout-grid": this.elFormOptions.layout === "grid"
      };
    },
    getSortedSchema() {
      if (this.orderFieldsBy !== "DEFAULT") {
        const sortedSchema = this.$lodash
          .cloneDeep(this.schema)
          .sort((a, b) =>
            this.$commonUtils.alphaNumericComparator(
              a[this.orderFieldsBy],
              b[this.orderFieldsBy]
            )
          );
        return sortedSchema;
      }
      return this.$lodash.cloneDeep(this.schema);
    },
    schemaTypeToComponent: function() {
      return {
        input: {
          componentName: "Input",
          props: (props) => ({ ...{ type: "text" }, ...props }),
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        },
        input_number: {
          componentName: "InputNumber",
          props: (props) => ({ ...{ controls: false }, ...props }),
          eventMethods: (path) => ({
            // "keypress.native": $event => { this.isNumber($event); this.handleChange($event, path); },
            input: ($event) => this.handleChange($event, path)
            // change: $event => this.isNumber($event)
          })
        },
        checkbox: {
          componentName: "Checkbox",
          props: (props) => ({ ...{ size: "mini" }, ...props }),
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        },
        switch: {
          componentName: "Toggle",
          props: (props) => ({ ...{ size: "mini" }, ...props }),
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        },
        select: {
          componentName: "erSelectWithOptions",
          props: ({ options, ...props }) =>
            props ? { options, ...props } : { options, ...{ type: "text" } },
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        },
        datePicker: {
          componentName: "DatePicker",
          props: (props) => ({
            ...{
              format: this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR,
              valueFormat: "yyyy-MM-dd"
            },
            ...props
          }),
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        },
        timePicker: {
          componentName: "TimePicker",
          props: (props) => ({
            ...{
              format: TIME_PICKER_FORMAT,
              valueFormat: TIME_PICKER_FORMAT
            },
            ...props
          }),
          eventMethods: (path) => ({
            input: ($event) => this.handleChange($event, path)
          })
        }
      };
    }
  },
  methods: {
    handleChange($event, path) {
      this.$emit("model-change", $event, path);
    },
    isNumber(val) {
      if (val.key === " " || val.key === "." || isNaN(val.key)) {
        return val.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss">
.generate-form {
  .el-input-number,
  .el-date-editor {
    width: 100%;
  }
  .el-form-item__label {
    color: #409effff;
    font-weight: 500;
    text-align: center;
  }
  .el-form-item__error {
    font-size: 10px !important;
  }
  .el-form.er-form--layout-grid {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden;
    flex-flow: wrap;
    column-gap: 16px;
    max-width: 100%;
    .el-form-item {
      .el-form-item__label {
        padding-bottom: 5px;
      }
    }
    .er-button {
      margin-left: 10px;
    }
  }
  .el-form--label-left {
    .el-form-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      grid-auto-flow: row;
      &::before,
      &::after {
        display: none;
      }
      .el-form-item__label {
        text-align: right;
      }
    }
  }
}
</style>
