<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmListIndividualDetailsSA.vue
Description:this file contains the ItemListSideMenu(list of pondmothers),basic details of pondmothers(ex:username,locations),pmDetails and    latest Sesor Values             -->
<template>
  <layout
    v-loading="loading"
    element-loading-background="white"
    class="pm-individual-details-sa"
  >
    <template slot="deviceListMenu">
      <item-list-side-bar
        :item-list="pmList"
        :init-item-id="pm_id"
        :showProperty="['code', 'v1_code']"
        item-type="pondmother"
        :dataFetchMethod="loadPmData"
        search-str-query-param="device_code"
        @loadData="loadPmData"
        @cleared="loadPmData"
        @itemClicked="handlePmSelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </template>
    <basic-customer-details
      :device-details="deviceDetails"
      :user-details="customerDetails"
      :showCustomerDetails="true"
      @change-edit="handleEditChange"
    >
      <template slot="moreActions">
        <i
          class="material-icons-round"
          :title="syncStatus.message"
          :style="`color:${syncStatus.color};`"
        >
          sync
        </i>
        <er-button
          v-if="userDevicePermissionCheck"
          btn-type="save"
          size="mini"
          @click="handleRemoveCustomer"
          :disabled="!customerDetails"
        >
          <span class="material-icons-round"> person_remove </span>
        </er-button>
      </template>
    </basic-customer-details>
    <br />
    <el-scrollbar
      class="pm-details pm-details-scroll"
      viewClass="pm-details-scroll-view"
      wrapClass="pm-details-scroll-wrap"
    >
      <pm-details
        :device-details="deviceDetails"
        :customer-details="customerDetails"
        :isDisabled="isDisabled"
        @refresh="handlePmSelected"
      ></pm-details>
    </el-scrollbar>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import pmDetails from "@/components/superadmin/pond-mother/pmDetails";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  props: ["pm_id"],
  components: {
    layout,
    basicCustomerDetails,
    itemListSideBar,
    pmDetails
  },
  data: function () {
    return {
      data: { pm_code: "" },
      loading: false,
      pond_mother_data: {},
      totalItemsCount: 100,
      isDisabled: true
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondMothers: "getPondMothers",
      getPmsObj: "getPmsObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj"
    }),
    syncStatus() {
      return this.deviceDetails.settings_changed
        ? { message: "Settings not updated to device", color: "#E31A1C" }
        : { message: "Settings updated to device", color: "#33A02C" };
    },
    deviceDetails() {
      // return this.getPmsObj[this.pm_id];
      return this.pond_mother_data;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: ""
        };
      }
    },
    pmList() {
      return this.getPondMothers;
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userDevicePermissionCheck() {
      return this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER");
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      deleteDevicesToUser: "deleteDevicesToUser"
    }),
     handleEditChange(changedEditStatus) {
      this.isDisabled = changedEditStatus;
    },
    async handleRemoveCustomer() {
      try {
        await this.$confirm(
          "Your are going to unassign the user from the device, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [this.pm_id],
          device_type: "POND_MOTHER"
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        await this.initComponent();
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }

        const pmResponse = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          infinite_scroll: true,
          params: { page: 1, limit: 20 }
        });
        this.totalItemsCount = pmResponse.total;
        // await this.fetchAllUsers({ get_all: true });
        await this.callStoreOnPmData(this.pm_id);
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadPmData({ query, infinite_scroll = false }) {
      const response = await this.fetchAllDeviceDetails({
        device_type: "pond_mother",
        infinite_scroll: infinite_scroll,
        params: query
      });

      this.totalItemsCount = response.total;
    },
    async callStoreOnPmData(pond_mother_id) {
      try {
        this.pond_mother_data = await this.fetchADeviceData({
          device_type: "pond_mother",
          device_id: pond_mother_id
        });
        console.log(this.pond_mother_data);
        // this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handlePmSelected(pm_id) {
      try {
        this.loading = true;
        this.$router.replace(pm_id);
        await this.callStoreOnPmData(pm_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pm-individual-details-sa {
  .pm-details {
    &.pm-details-scroll {
      position: relative;
      height: 81vh;
    }
    .pm-details-scroll-view {
      padding-right: 10px;
    }
    .pm-details-scroll-wrap {
      height: 100%;
      overflow-x: auto;
    }
  }
}
</style>
