<template>
  <layout-split-panes
    style="height: 100%"
    :panes="getPanes"
    v-loading="loading"
    class="activity-log-individual-view-sa"
  >
    <template slot="pane-customer-list">
      <item-list-side-bar
        :item-list="getListOfUsers"
        item-type="users"
        :init-item-id="getInitUserId"
        class="side-list-menu"
        :showProperty="['first_name', 'last_name', 'email']"
        :property-seperator="(v1, v2, v3) => `${v1} ${v2} (${v3})`"
        :search="true"
        search-property="user_name"
        :totalItemsCount="totalItemsCount"
        :dataFetchMethod="UserData"
        @loadData="UserData"
        @cleared="UserData"
        @itemClicked="handleUserSelected"
      ></item-list-side-bar>
    </template>
    <template slot="pane-activity-log">
      <template v-if="!loading">
        <toolbar></toolbar>
        <log-table></log-table>
      </template>
    </template>
  </layout-split-panes>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import urlParamsProvideMixin from "@/mixins/urlParamsProvideMixin";
import toolbar from "@/components/superadmin/activity-log/toolbar";
import logTable from "@/components/superadmin/activity-log/logTable";
import layoutSplitPanes from "@/components/superadmin/shared/layoutSplitPanes";
export default {
  mixins: [urlParamsProvideMixin, errorHandlerMixin, adminDatesHandlerMixin],
  components: {
    itemListSideBar,
    toolbar,
    logTable,
    layoutSplitPanes
  },
  data: function() {
    return {
      activeName: "first",
      totalItemsCount: 100,
      actionToPerform: "",
      particularUser: {},
      listOfAllPonds: [],
      selectedPond: "",
      listOfAllLocations: [],
      selectedLocation: "",
      location_id: "",
      loading: false,

      dateRange: []
    };
  },
  watch: {
    getSelectedLocationId: function() {
      this.handleChangeInLocation();
    }
  },
  computed: {
    ...mapGetters("superadmin/activityLog", {
      getUsers: "getUsers",
      getUsersObj: "getUsersObj",
      getAllPondsInSelectedLocation: "getAllPondsInSelectedLocation",
      getAllLocationsofUser: "getAllLocationsofUser",
      getSelectedLocationId: "getSelectedLocationId"
    }),
    getPanes() {
      return [
        {
          id: "customer-list",
          minSize: 10,
          maxSize: 40,
          size: 15
        },
        {
          id: "activity-log"
        }
      ];
    },
    getListOfUsers() {
      return this.getUsers;
    },
    getInitUser() {
      const user = this.getListOfUsers[0];
      return user || {};
    },
    getInitUserId() {
      return this.uppm__urlParams.user_id || this.getInitUser._id;
    },
    getLocations() {
      return this.getAllLocationsofUser;
    },
    getObjLocations() {
      return this.getLocations.reduce((acc, location) => {
        acc[location._id] = location;
        return acc;
      }, {});
    },
    getPondGroupByStatus() {
      const groupByOptions = this.listOfAllPonds.reduce(
        (acc, pond) => {
          acc[pond.status].options.push(pond);
          return acc;
        },
        {
          ACTIVE: { label: "Active", options: [] },
          INACTIVE: { label: "Inactive", options: [] }
        }
      );
      return groupByOptions;
    },
    getPonds() {
      return this.$lodash
        .cloneDeep(this.getAllPondsInSelectedLocation)
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    getPondsObj() {
      return this.getPonds.reduce((acc, pond) => {
        acc[pond._id] = pond;
        return acc;
      }, {});
    },
    getPondId() {
      return this.selectedPond._id;
    },
    getSelectedPondStIds() {
      return this.selectedPond ? this.selectedPond.shrimp_talks : [];
    }
  },
  async mounted() {
    this.initDate();
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin/activityLog", {
      fetchAllUsers: "fetchAllUserDetails",
      fetchAUserDetails: "fetchAUserDetails",
      fetchAllPondsofUser: "fetchAllPondsInLocation",
      fetchAllLocationsofUser: "fetchAllLocationsofUser",
      changeDateRange: "changeDateRange",
      changeLocation: "changeLocation",
      changePond: "changePond",
      clearActivityLogTableData: "clearActivityLogTableData",
      clearActivityLogType: "clearActivityLogType"
    }),
    initDate() {
      // if (!this.uppm__urlParams.from_date) {
      this.uppm__setUrlParams(
        "from_date",
        this.adm__dateUtilsLib.formatDate(
          this.adm__dateUtilsLib.subtract(new Date(), { days: 3 }),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      // }
      // if (!this.uppm__urlParams.to_date) {
      this.uppm__setUrlParams(
        "to_date",
        this.adm__dateUtilsLib.formatDate(
          new Date(),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      // }
      this.$set(this.dateRange, 0, this.uppm__urlParams.from_date);
      this.$set(this.dateRange, 1, this.uppm__urlParams.to_date);
      this.changeDateRange(this.dateRange);
    },
    handleDateRange() {
      this.uppm__setUrlParams("from_date", this.dateRange[0]);
      this.uppm__setUrlParams("to_date", this.dateRange[1]);
    },
    async handleChangePond() {
      this.changePond(this.selectedPond);
      this.uppm__setUrlParams("pond_id", this.selectedPond._id);
    },
    async handleChangeInLocation() {
      if (
        this.getAllLocationsofUser.length === 0 &&
        !this.getSelectedLocationId
      ) {
        this.uppm__deleteUrlParams("location_id");
        this.uppm__deleteUrlParams("pond_id");
      } else if (
        (!this.uppm__urlParams.location_id ||
          !this.getObjLocations[this.uppm__urlParams.location_id]) &&
        this.listOfAllLocations.length
      ) {
        this.selectedLocation = this.listOfAllLocations[0];
        this.uppm__setUrlParams("location_id", this.selectedLocation._id);
        this.changeLocation(this.selectedLocation);
      } else if (
        this.uppm__urlParams.location_id &&
        this.getSelectedLocationId &&
        this.uppm__urlParams.location_id !== this.getSelectedLocationId
      ) {
        this.uppm__setUrlParams("location_id", this.getSelectedLocationId);
      } else if (this.getObjLocations[this.uppm__urlParams.location_id]) {
        const locationId = this.uppm__urlParams.location_id;
        this.selectedLocation = this.getObjLocations[locationId];
        this.changeLocation(this.selectedLocation);
      }
      await this.initPonds();
    },
    async initPonds() {
      await this.fetchAllPondsofUser({
        params: {
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        }
      });
      this.listOfAllPonds = this.getPonds;
      this.selectedPond = undefined;
      if (this.listOfAllPonds.length > 0) {
        if (!this.getPondsObj[this.uppm__urlParams.pond_id]) {
          this.selectedPond =
            this.getPondGroupByStatus.ACTIVE.options[0] ||
            this.getPondGroupByStatus.INACTIVE.options[0];
          // this.handleChangePond();
        }
        this.selectedPond = this.getPondsObj[this.uppm__urlParams.pond_id];
      }
      // this.changePond(this.selectedPond);
    },
    async UserData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllUsers({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleUserSelected(user_id) {
      try {
        this.loading = true;
        const response = await this.fetchAUserDetails({
          user_id: user_id,
          params: {
            include_devices: true
          }
        });
        this.particularUser = response;
        this.uppm__setUrlParams("user_id", this.particularUser._id);
        await this.initLocations();
        await this.clearActivityLogTableData();
        await this.clearActivityLogType();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initLocations() {
      await this.fetchAllLocationsofUser({
        user_id: this.particularUser._id
      });
      this.listOfAllLocations = this.getLocations;
      await this.handleChangeInLocation();
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
        const userresponse = await this.fetchAUserDetails({
          user_id: this.getInitUserId,
          params: {
            include_devices: true
          }
        });
        this.particularUser = userresponse;
        if (!this.getUsersObj[this.uppm__urlParams.user_id]) {
          this.uppm__setUrlParams("user_id", this.particularUser._id);
        }
        await this.initLocations();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-log-individual-view-sa {
  &::v-deep #activity-log {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: calc(100vh - var(--header-height));
  }
}
</style>
