<template>
  <div>
    <dialog-form
      :model="getNewGateway()"
      title="Add Gateway"
      :schema="gatewayFields"
      :showDialog="dialogVisible"
      :loading="dialogBoxLoading"
      :backendValidations="createGatewayFormErrors"
      @close-dialog="closeDialog"
      @save-details="handleSaveNewGateway"
      @clear-validations="handleClearValidations"
      :shouldErrorHighlightShow="true"
      deviceType="GATEWAY"
    ></dialog-form>
    <settings-table
      ref="erDataTables"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :tableProps="elTableProps"
      :addPadingToSearch="true"
      search-property="device_code"
      placeHolderForSearch="Search_device_code"
      :total="total"
      :button="true"
      :columnselector_require="true"
      :selectedColumnHeadersSortable="true"
      @cell-click="handleCellClick"
      @loadData="handleLoadData"
      @reload="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddClick"
      class="is-filters-set-absolute"
    >
      <template slot="add-buttton">Add Gateways</template>
      <template v-slot:customer_name="{ row }">
        <template v-if="row.data.user_data.length > 0">
          {{ customerFullName(row.data) }}
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:customer_timezone="{ row }">
        <template v-if="row.data.user_data.length > 0">
          {{ getCustomerTimezone(row.data) }}
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:gateway_code="{ row }">
        <el-link :href="`/admin/gateways/${row.data._id}`">
          {{ row.data.code }}
        </el-link>
      </template>
      <template v-slot:v1_id="{ row }">
        {{ row.data.v1_id }}
        {{ row.data.v1_code ? ` (${row.data.v1_code})` : "" }}
      </template>
      <template v-slot:wifi_networks="{ row }">
        <template v-if="wifiNetworks(row.data.settings).length > 0">
          <el-tag
            size="mini"
            effect="dark"
            v-for="(network, index) in wifiNetworks(row.data.settings)"
            :key="index"
            >{{ network }}</el-tag
          >

          <br />
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:wifi_passwords="{ row }">
        <template v-if="wifiPasswords(row.data.settings).length > 0">
          <el-tag
            size="mini"
            effect="dark"
            v-for="(password, index) in wifiPasswords(row.data.settings)"
            :key="index"
            >{{ password }}</el-tag
          >

          <br />
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:wifi_security="{ row }">
        <template v-if="wifiSecurity(row.data.settings).length > 0">
          <el-tag
            size="mini"
            effect="dark"
            v-for="(security, index) in wifiSecurity(row.data.settings)"
            :key="index"
            >{{ security }}</el-tag
          >

          <br />
        </template>
        <template v-else>--</template>
      </template>

      <template v-slot:installed_date="{ row }">
        {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at)) }}
      </template>

      <template v-slot:v2_parsing_enabled="{ row }">
        <el-switch
          :value="
            row.data.settings.v2_parsing ? row.data.settings.v2_parsing : false
          "
          @input="handleASettingKeyChange($event, row.data, 'v2_parsing')"
        ></el-switch>
      </template>

      <template v-slot:v1_parsing_enabled="{ row }">
        <el-switch
          :value="
            row.data.settings.v1_parsing ? row.data.settings.v1_parsing : false
          "
          @input="handleASettingKeyChange($event, row.data, 'v1_parsing')"
        ></el-switch>
      </template>

      <template v-slot:last_communication="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.utc_last_communicated_at_status"
        >
          {{
            ftm__formatEmpty(
              adm__filterToFormatDate(row.data.utc_last_communicated_at)
            )
          }}
        </el-tag>
        <template v-else>{{
          ftm__formatEmpty(
            adm__filterToFormatDate(row.data.utc_last_communicated_at)
          )
        }}</template>
      </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import settingsTable from "@/components/base/settingsTable";
import dialogForm from "@/components/superadmin/shared/dialogForm";
import { mapActions } from "vuex";
export default {
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    filtersMixin,
    adminDatesHandlerMixin
  ],
  components: {
    dialogForm,
    settingsTable
  },
  data() {
    return {
      dialogVisible: false,
      dialogBoxLoading: false,
      createGatewayFormErrors: {},
      tableData: [],
      total: 0,
      loading: false
    };
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey"
    }),
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.clearDeviceDetails({
          device_type: "gateway"
        });
        this.$router.push({
          name: "GatewayDetails",
          params: { gateway_id: row._id }
        });
      }
    },
    getNewGateway() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        ),
        settings_interval: 15,
        device_stats_interval: 15
      };
    },
    wifiNetworks(settings) {
      return (settings && settings.wifi_networks) || [];
    },
    wifiPasswords(settings) {
      return (settings && settings.wifi_passwords) || [];
    },
    wifiSecurity(settings) {
      return (settings && settings.wifi_security) || [];
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllDeviceDetails({
          device_type: "gateway",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getGateways"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    async handleASettingKeyChange(value, gateway, settingKey) {
      const confirmMessage = {
        v1_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v1 parsing for ${gateway.code}, Are you sure?`,
          success: `V1 parsing is ${value ? "enabled" : "disabled"} for ${
            gateway.code
          }`
        },
        v2_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v2 parsing for ${gateway.code}, Are you sure?`,
          success: `V2 parsing is ${value ? "enabled" : "disabled"} for ${
            gateway.code
          }`
        }
      };
      const payload = {
        code: gateway.code,
        [settingKey]: value
      };
      try {
        await this.$confirm(confirmMessage[settingKey].warning, "Warning", {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        await this.updateASettingKey({
          device_type: "gateway",
          setting_key: settingKey,
          payload
        });
        this.$notify({
          type: "success",
          title: "Success",
          message: confirmMessage[settingKey].success
        });
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createGatewayFormErrors = {};
          break;
        case "SINGLE":
          delete this.createGatewayFormErrors[indexPosition];
          break;
      }
    },
    closeDialog() {
      this.createGatewayFormErrors = {};
      this.dialogVisible = false;
    },
    handleAddClick() {
      this.dialogVisible = true;
    },
    async handleSaveNewGateway(model, arrGatewayNames) {
      try {
        this.dialogBoxLoading = true;
        const payload = arrGatewayNames.map((gatewayName) => {
          return {
            v1_code: gatewayName,
            on_boarded_at: model.on_boarded_at,
            settings: {
              settings_interval: model.settings_interval * 60,
              device_stats_interval: model.device_stats_interval * 60
            }
          };
        });
        await this.saveListDevicesData({ device_type: "GATEWAY", payload });
        this.$notify({
          title: "Success",
          message: "Created Gateway Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        this.$notify({
          title: "Failed",
          message: "Created Gateway UnSuccessful",
          type: "error"
        });
        if (err.response && err.response.data) {
          this.createGatewayFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
      }
    }
  },
  computed: {
    customerFullName() {
      return (data) => {
        if (data.user_data && data.user_data[0]) {
          if (data.user_data[0].first_name && data.user_data[0].last_name) {
            return `${data.user_data[0].first_name} ${data.user_data[0].last_name}`;
          } else if (data.user_data[0].first_name) {
            return data.user_data[0].first_name;
          }
          return "--";
        }
        return "--";
      };
    },
    getCustomerTimezone() {
      return (data) => {
        if (data.user_data && data.user_data[0]) {
          if (data.user_data[0].timezone) {
            return `${data.user_data[0].timezone.name}`;
          }
          return "--";
        }
        return "--";
      };
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      if (window.innerWidth < 2560 && window.innerWidth > 1537) {
        return "calc(100vh - 210px)";
      } else if (window.innerWidth > 1535 && window.innerWidth < 1920) {
        return "calc(100vh - 200px)";
      } else {
        return "calc(100vh - 150px)";
      }
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          label: this.$t("Comn_gateway_code"),
          required: true,
          sortable: true,
          minWidth: 120,
          fixed: "fixed"
        },
        {
          prop: "title",
          label: this.$t("Alias"),
          required: true,
          minWidth: 100
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: false,
          minWidth: 100
        },
        {
          prop: "_id",
          label: "V2 Id",
          required: false,
          minWidth: 200
        },
        {
          prop: "assigned_users",
          label: "Customer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "user_data.country.name",
          label: "Country",
          required: true,
          minWidth: 120
        },
        {
          prop: "customer_timezone",
          label: "Customer Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.timezone",
          label: "Device Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "connected_devices_count",
          label: "Device Count",
          required: true,
          minWidth: 200
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.settings_interval",
          label: "Settings Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.lora_channel",
          label: "Lora Channel",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.latitude",
          label: "Latitude",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.longitude",
          label: "Longitude",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.wifi_networks",
          label: "Wifi Networks",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.wifi_passwords",
          label: "Wifi Passwords",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.wifi_security",
          label: "Wifi Security",
          required: false,
          minWidth: 100
        },
        {
          prop: "user_data[0].country.name",
          label: "Country",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.mac_address",
          label: "MAC Address",
          required: false,
          minWidth: 150
        },
        {
          prop: "settings.os_version",
          label: "OS Version",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.software_version",
          label: "Software Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.ip_interface",
          label: "IP Interface",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ip_address",
          label: "IP Address",
          required: false,
          minWidth: 120
        },
        {
          prop: "settings.v2_parsing",
          label: "V2 Parsing Enabled",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.v1_parsing",
          label: "V1 Parsing Enabled",
          required: true,
          minWidth: 100
        },
        {
          prop: "on_boarded_at",
          label: "Installed date",
          required: false,
          sortable: true,
          type: "date",
          minWidth: 180
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 150
        }
      ];
    },
    gatewayFields: function() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Settings Interval (mins)",
          model: "settings_interval",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Device Stats Interval (mins)",
          model: "device_stats_interval",
          validationRules: "required"
        }
      ];
    }
  }
};
</script>
<style lang="scss">
// .el-menu--horizontal {
//   position: absolute !important;
//   z-index: 1;
// }
.device-apis-table {
  .el-table {
    // height: calc(100vh - 227px);
    overflow-y: hidden;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1919px) {
  .device_input {
    width: 182px;
    margin-left: 12px;
  }
}
</style>
