<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerIndividualViewSA
Description:This file contains the itemListSideBar(Acccount managers), list of the customers associated with Account manager and add user to the Account manager by admin
-->
<template>
  <el-row>
    <el-row>
      <el-col :span="4">
        <item-list-side-bar
          :item-list="getAccountManagers"
          :init-item-id="accountmanager_id"
          item-type="accountmanagers"
          class="side-list-menu"
          :showProperty="['first_name', 'last_name']"
          :search="true"
          :totalItemsCount="totalItemsCount"
          search-property="user_name"
          :dataFetchMethod="AccountManagerData"
          @loadData="AccountManagerData"
          @cleared="AccountManagerData"
          @itemClicked="handlePmSelected"
        ></item-list-side-bar>
      </el-col>
      <el-col :span="20">
        <AddAccountCustomers
          v-bind:accountmanager_id="accountmanager_id"
        ></AddAccountCustomers>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import AddAccountCustomers from "@/components/superadmin/account-manager/AddAccountCustomers";
export default {
  props: ["accountmanager_id"],
  components: {
    itemListSideBar,

    AddAccountCustomers
  },

  data: function () {
    return {
      activeName: "first",
      dialogVisible: false,
      loading: false,
      state2: "",
      tableData: [],
      totalItemsCount: 100,
      total: 0,
      searchDealer: "",
      selectedDealer: {},
      actionToPerform: "",
      newDealer: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getCustomers: "getUsers",
      getParticularDealer: "getParticularDealer",
      getAccountManagers: "getAccountManagers"
    })
  },

  methods: {
    ...mapActions("superadmin", {
      // fetchAllDealers: "fetchAllDealers",
      // fetchDealerById: "fetchDealerById",
      // alreadyAssignedUsersToDealer: "alreadyAssignedUsersToDealer",
      // fetchAllUnassignedDeviceDetails: "fetchAllUnassignedDeviceDetails",
      // fetchDealer_PondMothers: "fetchDealer_PondMothers",
      // fetchDealer_PondGaurds: "fetchDealer_PondGaurds",
      // fetchDealer_ShrimpTalks: "fetchDealer_ShrimpTalks",
      fetchAlreadyAssingedAccountUsers: "fetchAlreadyAssingedAccountUsers",
      fetchAllAccountManagers: "fetchAllAccountManagers"
    }),

    handleClick(tab, event) {
      console.log(tab, event);
    },

    async AccountManagerData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllAccountManagers({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handlePmSelected(accountmanager_id) {
      this.$router.replace(accountmanager_id);
    },

    handleSelect(item) {
      console.log(item);
    },

    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllAccountManagers({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss"></style>
