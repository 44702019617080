<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: defaultTable.vue
Description: This file comprises of common table component that is used in dealer site
-->
<template>
  <div class="skretting-technician-default-table">
    <div
      :class="{ 'padding-10-0': addPadingToSearch }"
      class="table-filters-right"
    >
      <layout-toolbar type="flex">
        <el-col :span="11">
          <slot name="table-header"> </slot>
        </el-col>

        <el-col :span="4">
          <el-input
            v-if="searchable"
            :placeholder="searchTranslationKeyName"
            :debounce="300"
            v-model="searchString"
            size="small"
            clearable
            @input="handleChange"
            @clear="handleClear"
            class="search-input"
            autofocus="true"
            ref="mainSearch"
          ></el-input>
        </el-col>
        <!-- <er-button
          class="btn-settings-table-refresh"
          @click="handleReload"
          size="small"
          type="default"
          :title="$t('refresh')"
          round
        >
          <i class="material-icons-round">refresh</i></er-button
        > -->

        <er-button
          @click="handleReload"
          class="er-btn-refresh"
          type="text"
          size="small"
        >
          <i class="material-icons-round">refresh</i>
        </er-button>
        <div v-if="columnselector_require">
          <er-column-selector
            key-to-store-defaults="customer-views-admin-table"
            keyToDecideDefaultCol="required"
            :title="`Column ${$t('Comn_visibility')}`"
            :columns="columns"
            @change-selection="handleChangeInColumnSelection"
          ></er-column-selector>
        </div>

        <slot name="table-toolbar-left-slot">
          <er-action-button
            v-if="button"
            @action-btn-click="handleAddButtonClick()"
            :type="'solid'"
          >
            <template v-slot:icon>
              <i class="el-icon-circle-plus-outline"></i>
            </template>
            <template v-slot:label>
              <slot name="add-buttton"></slot>
            </template>
          </er-action-button>
        </slot>
      </layout-toolbar>
    </div>
    <el-scrollbar
      class="settings-table-scroll"
      viewClass="settings-table-scroll-view"
      wrapClass="settings-table-scroll-wrap"
    >
      <data-tables-server
        ref="dataTable"
        :data="tableData"
        :total="total"
        :size="size"
        :page-size="$constants.table['page-size']"
        :pagination-props="$constants.table['pagination-props']"
        :table-props="computedTableProps"
        @query-change="onQueryChange"
        @cell-click="handleCellClick"
        class="device-default-table"
      >
        <template v-for="(column, index) in columnsSelected">
          <el-table-column
            :key="index"
            v-show="column.visible"
            :label="column.label"
            :sortable="column.sortable"
            :prop="column.prop"
          >
            <template v-slot:header="scope">
              <slot
                :name="`${column.name.toLowerCase()}_header`"
                v-bind:header="{
                  column: scope.column,
                  $index: scope.$index,
                  label: column.label,
                  sub_label: column.sub_label || ''
                }"
              >
                {{ column.label }}
                {{ column.sub_label }}
              </slot>
            </template>

            <template slot-scope="scope">
              <slot
                :name="column.prop"
                v-bind:row="{ data: scope.row, path: column.prop }"
              >
                <template
                  v-if="getRowValue(scope.row, column).type === 'array'"
                >
                  <el-tag
                    size="mini"
                    effect="dark"
                    v-for="(val, index) in getRowValue(scope.row, column).value"
                    :key="index"
                    >{{ val }}</el-tag
                  >
                </template>
                <template v-else>
                  {{ getRowValue(scope.row, column).value }}
                </template>
              </slot>
            </template>
          </el-table-column>
        </template>
        <slot name="actionColumn">
          <el-table-column
            :label="$t('accountmanager.Comn_actions')"
            v-if="actionColumn"
          >
            <er-button
              icon="el-icon-setting"
              size="small"
              :disabled="true"
            ></er-button>
          </el-table-column>
        </slot>
        <slot name="expand"></slot>
      </data-tables-server>
    </el-scrollbar>
  </div>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [filtersMixin],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      default: () => [],
      type: Array
    },
    total: {
      default: 0,
      type: Number
    },
    size: {
      default: 0,
      type: Number
    },
    actionColumn: {
      default: false,
      type: Boolean
    },
    searchProperty: {
      default: "code",
      type: String
    },
    height: {
      default: "100%",
      type: String
    },
    placeHolderForSearch: {
      default: "device",
      type: String
    },
    tableProps: {
      default: () => ({}),
      type: Object
    },
    searchable: {
      default: false,
      type: Boolean
    },
    addPadingToSearch: {
      required: false,
      default: () => true
    },
    button: {
      required: true,
      default: false
    },
    columnselector_require: {
      required: true,
      default: false
    }
  },
  data: function() {
    return {
      columnsSelected: this.columns.filter((column) => column.required),
      searchString: "",
      query: {},
      page: 1,
      limit: 30
    };
  },
  created() {
    console.log(this.dataTable);
  },
  computed: {
    searchTranslationKeyName() {
      if (this.placeHolderForSearch && this.$t(this.placeHolderForSearch)) {
        return this.$t(this.placeHolderForSearch);
      }

      return this.$t("Comn_search");
    },
    computedTableProps: function() {
      return {
        height: this.height,
        headerCellClassName: "settings-table-header-cell",
        ...this.tableProps
      };
    }
  },
  mounted() {
    this.handlePaginationHideAndUnhide();
  },
  watch: {
    total: function(val) {
      this.handlePaginationHideAndUnhide();
    }
  },
  methods: {
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName("pagination-bar");
      if (this.total === 0) {
        ele.item(0).style.display = "none";
      } else {
        ele.item(0).style.display = "block";
      }
    },
    async handleClear() {
      await this.$emit("cleared", {});
    },
    handleAddButtonClick() {
      this.$emit("add-item-click");
    },
    handleExpandChange(row) {
      this.$emit("expand-change", row);
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;
      const value =
        typeof this.$lodash.result(object, path) === "undefined" ||
        this.$lodash.result(object, path) === "" ||
        this.$lodash.result(object, path) === null
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        return value && value !== "--"
          ? {
              type: "string",
              value: dateUtils.formatDate(
                new Date(value),
                this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
              )
            }
          : { type: "string", value: "--" };
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case "sort":
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case "size":
        case "page":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "init":
          this.query.page = 1;
          this.query.limit = this.$constants.table["page-size"];
          break;
        default:
          throw new Error(
            "Not implemented onquerychange for " + queryInfo.type
          );
      }
      this.$emit("loadData", this.query);
    },
    sort(property, orderType) {
      this.query.order_by = property;
      if (orderType === "descending") {
        this.query.order_type = "desc";
      } else if (orderType === "ascending") {
        this.query.order_type = "asc";
      } else if (orderType === null) {
        delete this.query.order_type;
        delete this.query.order_by;
      }
    },
    pagination(page, pageSize) {
      this.query.limit = pageSize;
      this.query.page = page;
      this.page = page;
      this.limit = pageSize;
    },
    async handleReload() {
      const options = {
        page: this.page,
        limit: this.limit
      };
      if (this.searchString) {
        options.user_name = this.searchString;
        options.device_code = this.searchString;
      }
      await this.$emit("reload", options);
    },
    async handleChange(string) {
      if (string === "") {
        this.query.page = this.page;
        this.query.limit = this.limit;
        delete this.query[this.searchProperty];
        await this.$emit("loadData", this.query);
      } else {
        this.query[this.searchProperty] = string;
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.$emit("loadData", this.query);
        }, 1000);
      }
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("cell-click", row, column, cell, event);
    }
  }
};
</script>

<style lang="scss">
.skretting-technician-default-table {
  height: 100%;
  .el-table {
    // overflow: unset;
    border-radius: 7px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
    color: #606266;
  }

  .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 75vh, 79vh, 83vh);
  }
  .settings-table-scroll-view {
    height: 100%;
    padding-right: 10px;
  }
  .settings-table-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .padding-10-0 {
    padding: 10px 0;
  }
  div.cell .el-link.el-link--default {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
    // letter-spacing: 0.9px;
    font-weight: 100;
  }
  .settings-table-header-cell {
    color: #333;
    font-weight: 500;
  }
  .btn-settings-table-refresh {
    padding: 8px 12px;
  }
  .er-btn-refresh {
    padding: 0 !important;
    .material-icons-round {
      font-size: 28px;
    }
  }
  // .search-input .el-input__inner {
  //   text-transform: uppercase;
  // }
  // .search-input .el-input__inner::-webkit-input-placeholder {
  //   text-transform: initial;
  // }
  // .search-input .el-input__inner:-moz-placeholder {
  //   text-transform: initial;
  // }
  .search-input .el-input__inner::-moz-placeholder {
    text-transform: initial;
  }
  .el-input--mini .el-input__inner {
    height: 23px;
    line-height: 23px;
  }
  .sc-table .pagination-bar {
    background-color: white;
    // margin-top: 10px;
  }
  .el-table td,
  .el-table th {
    padding: 6px 0px;
    border-bottom: 1.1px solid !important;
    border-bottom-color: #ebeef5 !important;
  }
  .el-table th {
    font-weight: bold;
    color: #233a73;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .device-default-table {
    &.sc-table {
      display: flex;
      flex-direction: column;
      min-height: 0;
      height: 100%;
      .el-table {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .el-table__body-wrapper {
          display: flex;
          flex-direction: column;
          min-height: 0;
          height: 100% !important;
          overflow-y: auto;
        }
      }
    }
    & > .pagination-bar {
      padding: 10px;
    }
    .el-table__row {
      td:first-child {
        cursor: pointer;
      }
    }
    div.cell .el-link.el-link--default {
      font-size: 12px;
      font-weight: 100;
    }
    .settings-table-header-cell {
      color: #333;
      font-weight: 500;
    }
    .el-input--mini .el-input__inner {
      height: 23px;
      line-height: 23px;
    }
    .el-table td,
    .el-table th {
      padding: 6px 0;
    }
    .el-table th > .cell {
      color: #233a73;
      font-weight: 600;
      line-height: 120%;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .el-table .sort-caret.descending {
      bottom: 0px;
    }
    .el-table .sort-caret.ascending {
      top: 0px;
    }

    .el-table .caret-wrapper {
      height: 22px;
      width: 10px;
    }

    .el-table td > .cell {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      align-items: center;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      position: relative;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
  }
}
</style>
