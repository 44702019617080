var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"clients-individual-view-sa"},[_c('template',{slot:"deviceListMenu"},[_c('item-list-side-bar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","item-list":_vm.clientsList,"init-item-id":_vm.client_id,"item-type":"clients","showProperty":"title","totalItemsCount":_vm.totalItemsCount,"dataFetchMethod":_vm.loadClientsData},on:{"loadData":_vm.loadClientsData,"cleared":_vm.loadClientsData,"itemClicked":_vm.handleClientSelected}})],1),_vm._v(" "),_c('er-dialog',{attrs:{"title":_vm.clientActionDialogTitle,"visible":_vm.clientActionDialogVisible,"append-to-body":true,"width":"30%","custom-class":"client-individual-view-sa-dialog"},on:{"update:visible":function($event){_vm.clientActionDialogVisible=$event},"open":_vm.handleAddClientDialogOpen}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"model":_vm.selectedClient}},[_c('ValidationProvider',{attrs:{"name":"Client Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":"Client Name","error":errors[0]}},[_c('el-input',{attrs:{"disabled":['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(_vm.action)},model:{value:(_vm.selectedClient.title),callback:function ($$v) {_vm.$set(_vm.selectedClient, "title", $$v)},expression:"selectedClient.title"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Users","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(
              ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(_vm.action)
            )?_c('el-form-item',{attrs:{"label":"Users","error":errors[0]}},[_c('er-select',{attrs:{"placeholder":_vm.$t('Comn_search'),"loading":_vm.userListLoading,"multiple":"","filterable":"","collapse-tags":"","remote":"","remote-method":_vm.fetchUsersToAssign,"value-key":"_id"},model:{value:(_vm.selectedClient.users),callback:function ($$v) {_vm.$set(_vm.selectedClient, "users", $$v)},expression:"selectedClient.users"}},_vm._l((_vm.usersList),function(item){return _c('el-option',{key:item._id,attrs:{"label":((item.first_name) + " " + (item.last_name)),"value":item}},[_c('p',[_vm._v("\n                  "+_vm._s(item.last_name)+" "+_vm._s(item.first_name)+"\n                  "),_c('span',{staticClass:"user-list-email-field"},[_vm._v("("+_vm._s(item.email)+")\n                  ")])])])}),1)],1):_vm._e()]}}])})],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.handleSaveClient}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"loading":_vm.saveLoading},on:{"click":_vm.closeAddClientDialog}})],1)],2),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:(_vm.client_id + "_" + _vm.getUsersCount),attrs:{"element-loading-background":"white","tableData":_vm.getUsersOfClient,"columns":_vm.columnsObject,"total":_vm.totalUsers,"placeHolderForSearch":"Search_username","search-property":"user_name","addPadingToSearch":true},on:{"loadData":_vm.callStoreOnClientUsersData,"reload":_vm.callStoreOnClientUsersData,"cleared":_vm.callStoreOnClientUsersData},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.first_name)+" "+_vm._s(row.data.last_name)+"\n    ")]}}])},[_vm._v(" "),_c('template',{slot:"table-toolbar-left-slot"},[_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleAddUsersToClient()}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" person_add ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","disabled":_vm.isAvailableToDelete},on:{"click":function($event){return _vm.handleDeleteUsersToClient()}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" person_remove ")])])],1)],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }