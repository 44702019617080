var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pm-stats-main-tab"},[_c('div',{staticClass:"item-container is-filters-set-relative"},[_c('div',{staticClass:"table-filters-right"},[_c('el-checkbox',{staticStyle:{"margin-top":"8px"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("Last 24 Hours")]),_vm._v(" "),_c('er-select-with-options',{staticClass:"select-device-type",staticStyle:{"width":"10rem"},attrs:{"value":_vm.getSoftwareVersionOptions,"options":_vm.getSoftwareVersionsData,"showSelectAll":true,"value-type":"string","size":"mini","placeholder":"Software Version","multiple":"","reserve-keyword":"","collapse-tags":""},on:{"input":_vm.handleSoftwareVersionChange}}),_vm._v(" "),(_vm.softwareOptions.length > 3)?_c('span',{staticClass:"error-Msg"},[_vm._v("Can't Select More than 3 versions")]):_vm._e(),_vm._v(" "),_c('er-select-with-options',{staticClass:"select-md",staticStyle:{"width":"10rem"},attrs:{"value":_vm.hardwareOptions ? _vm.hardwareOptions : _vm.getSelectedHardwareVersions,"options":_vm.getHardwareVersionsData,"value-type":"string","value-key":"label","label-key":"label","placeholder":"Hardware Version"},on:{"input":_vm.handleHardwareVersionChange}}),_vm._v(" "),_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.handleSubmit}},[_vm._v("Submit")]),_vm._v(" "),_c('er-dropdown',{attrs:{"placement":"bottom","trigger":"click","listOfItems":[
          'Comn_download_as_excel'
        ]},on:{"item-click":_vm.xceldownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}])},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2)],1),_vm._v(" "),_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.getLoading),expression:"getLoading"}],key:_vm.$i18n.locale,ref:"PMStatsTable",staticClass:"pmStats-table",attrs:{"tableData":_vm.tableData,"columns":_vm.tableColumns,"elTableProps":_vm.tableProps,"action-column":false,"sortMethodForColumns":_vm.sortMethods(),"uniqueId":"PM-stats-table","type":"white-header-table","layout":"table","element-loading-background":"white"},scopedSlots:_vm._u([{key:"user_name",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.user_name)+"\n    ")]}},{key:"software_versions1_header",fn:function(ref){
        var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.softwareVersionHeader(header.label)))])]}},{key:"software_versions1",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.softwareVersion1TableData(row.data.software_versions))+"\n    ")]}},{key:"software_versions2_header",fn:function(ref){
        var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.softwareVersionHeader(header.label)))])]}},{key:"software_versions2",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n     "+_vm._s(_vm.softwareVersion2TableData(row.data.software_versions))+"\n    ")]}},{key:"software_versions3_header",fn:function(ref){
        var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.softwareVersionHeader(header.label)))])]}},{key:"software_versions3",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.softwareVersion3TableData(row.data.software_versions))+"\n    ")]}},{key:"hardware_versions_header",fn:function(ref){
        var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.softwareVersionHeader(header.label)))])]}},{key:"hardware_versions",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.hardwareVersionTableData(row.data.hardware_versions))+"\n    ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }