/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for the charts in the pondguard tab of  superadmin site
*/
// admin pg charts
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { baseChartConfig } from "@/config/baseChartConfig";
const legend = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled
  };
};

const exporting = function(enabled) {
  return {
    enabled: enabled
  };
};

const title = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled,
    text: undefined
  };
};
const tooltip = {
  default: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  numericXAxisToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small><strong>DOC:{point.x}</strong></small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
  },
  pondScheduleToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name.tooltip}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  hourlyToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmyt
  }
};
const pHGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const disolveOxygenGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const doSensorDisconnectedGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const doSensorNoResponseGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const solarDisconnectedGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const doCalibrationFailedGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const doInvalidReadingGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const salinityGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const temperatureGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const solarVoltageGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const batteryVoltageGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const chargingCurrentGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const errorCodeGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const loadCurrentGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const doSaturationGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const electrodeResistanceGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const temperatureResistanceGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const conductivityGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const tdsGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
const salinitySensorGraphConfig = {
  ...baseChartConfig,
  legend: legend(false),
  exporting: exporting(true),
  tooltip: { ...tooltip.hourlyToolTip, xDateFormat: CHART_DATE_FORMATS.dmyts },
  title: title(true)
};
export {
  pHGraphConfig,
  disolveOxygenGraphConfig,
  salinityGraphConfig,
  temperatureGraphConfig,
  solarVoltageGraphConfig,
  batteryVoltageGraphConfig,
  chargingCurrentGraphConfig,
  errorCodeGraphConfig,
  loadCurrentGraphConfig,
  doSaturationGraphConfig,
  electrodeResistanceGraphConfig,
  temperatureResistanceGraphConfig,
  conductivityGraphConfig,
  tdsGraphConfig,
  salinitySensorGraphConfig,
  doSensorDisconnectedGraphConfig,
  doSensorNoResponseGraphConfig,
  doInvalidReadingGraphConfig,
  doCalibrationFailedGraphConfig,
  solarDisconnectedGraphConfig
};
