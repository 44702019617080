<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: tabLayout.vue
Description:this file creates the layout for the tabs
-->
<template>
  <el-tabs
    @tab-click="tabClickHandler"
    v-model="selectedComponent"
    class="tab-layout"
  >
    <template v-for="(component, index) in componentList">
      <el-tab-pane
        :label="component.label"
        :name="component.name"
        :key="index"
      ></el-tab-pane>
      <slot
        :name="component.name"
        v-if="showComponent === component.name"
      ></slot>
    </template>
  </el-tabs>
</template>

<script>
export default {
  props: {
    componentList: {
      default: () => [],
      required: Array
    },
    showComponent: {
      default: "",
      required: String
    },
    tabClickHandler: {
      default: () => () => {},
      required: Function
    }
  },
  computed: {
    selectedComponent: {
      get() {
        return this.showComponent;
      },
      set(component) {
        this.$emit("selectedComponent", component);
      }
    }
  }
};
</script>

<style lang="scss">
.tab-layout {
  .el-tabs {
    display: grid;
  }
  .el-tabs__header {
    margin: 0px !important;
  }
}
</style>
