<template>
  <layout :no-aside="true" class="warranty-table-sa">
    <div class="div_margin">
      <er-dialog
        :title="warrantyActionDialogTitle"
        :visible.sync="warrantyActionDialogVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="40%"
        @close="closeWarrantyDialog"
        custom-class="warrantyAction_dialog"
      >
        <ValidationObserver ref="dialogListForm">
        <el-form size="small" v-loading="dailogLoading" :model="warrantyForm" :inline="true">
          <ValidationProvider
            name="Customer"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Customer"
              class="input-name"
              :error="errors[0]"
            >
              <el-select
                v-model="warrantyForm.user_id"
                filterable
                remote
                :disabled="action !== 'ADD'"
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="fetchUsersData"
                @change="loadingDevicesDetails"
                :loading="false">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            name="Product Type"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Product Type"
              class="input-name"
              :error="errors[0]"
            >
            <el-select
              v-model="warrantyForm.device_type"
              :disabled="action !== 'ADD'"
              filterable
              placeholder="Select Product Type"
              @change="handleDeviceTypeChange"
            >
              <el-option
                v-for="item in deviceTypesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            name="Device ID"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Device ID"
              class="input-name"
              :error="errors[0]"
            >
            <el-select
              v-model="warrantyForm.device_id"
              placeholder="Select Device ID"
              :disabled="action !== 'ADD'"
              filterable
              @change="handleDeviceIdChange"
            >
              <el-option
                v-for="item in deviceOptions[warrantyForm.device_type]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </ValidationProvider>
          <el-form-item
              label="Motor ID"
              class="input-name"
            >
            <el-input type="text" v-model="warrantyForm.motor_name" disabled></el-input>
          </el-form-item>
          <ValidationProvider
            name="Failure Part No"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Failure Part No"
              :error="errors[0]"
            >
            <el-select
              v-model="warrantyForm.part_id"
              placeholder="Select Failure Part No"
              :disabled="action !== 'ADD'"
              filterable
              @change="handleChangePartId"
            >
              <el-option
                v-for="item in (!isDealer ? getAllPartsData : getAllDealerPartsData)"
                :key="item._id"
                :label="item.part_id"
                :value="item._id"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </ValidationProvider>
            <el-form-item
              label="Part Name"
            >
            <el-input type="text" v-model="warrantyForm.part_name" disabled></el-input>
            </el-form-item>
          <ValidationProvider
            name="Issue Description"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              :error="errors[0]"
              label="Issue Description"
              class="input-feed-type"
            >
              <el-input
                type="textarea"
                :rows="4"
                size="mini"
                class="warrantyPageTextarea"
                autocomplete="off"
                placeholder="Enter Issue Description"
                v-model="warrantyForm.issue"
                :disabled="action !== 'ADD'"
              ></el-input>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            name="Product Failure Date"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              :error="errors[0]"
              label="Product Failure Date"
              class="input-feed-type"
            >
            <el-date-picker
              v-model="warrantyForm.failure_date"
              type="date"
              format="dd MMM, yy"
              value-format="yyyy-MM-dd"
              placeholder="select a date"
              size="small"
              :clearable="false"
              :disabled="action !== 'ADD'"
              :picker-options="pickerOptions"
              class="custom-calendar"
            >
            </el-date-picker>
            </el-form-item>
          </ValidationProvider>
          <el-form-item
            v-if="action !== 'ADD' && warrantyForm.device_type === 'POND_MOTHER'"
            label="Replaced"
            class="input-feed-type"
          >
          <el-checkbox v-model="warrantyForm.replaced" :disabled="isReplacebyEnable"></el-checkbox>
          </el-form-item>
          <ValidationProvider
            v-if="warrantyForm.replaced"
            name="Replaced Motor ID"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Replaced Motor ID"
              class="input-name"
              :error="errors[0]"
            >
             <er-select
                v-model="warrantyForm.replaced_motor_id"
                class="select-devices"
                value-key="code"
                :reserve-keyword="true"
                filterable
                placeholder="Enter Motor ID"
              >
                <el-option
                  v-for="item in (isDealer ? getAllMotorsDealerData : getAllMotorsData)"
                  :key="item._id"
                  :label="item.motor_id"
                  :value="item._id"
                >
                  <span style="white-space: initial">
                    {{ item.motor_id }}</span
                  >
                </el-option>
              </er-select>
              <!-- <el-input
                v-model="warrantyForm.replaced_motor_id"
                placeholder="Enter Replaced Motor ID"
              ></el-input> -->
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            v-if="action !== 'ADD'"
            name="Claim Status"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              label="Claim Status"
              class="input-name"
              :error="errors[0]"
            >
            <el-select
              v-model="warrantyForm.claim_status"
              placeholder="Select Claim Status"
            >
              <el-option
                v-for="item in getStatusOptionsData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
        </ValidationObserver>
        <template slot="footer">
          <el-row type="flex" justify="end" align="middle">
            <er-button
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :loading="saveLoading"
              @click="handleSaveWarranty"
            ></er-button>
            <!-- @click="handleSaveCareer" -->
            <er-button
              btnType="cancel"
              :showLabel="true"
              :loading="saveLoading"
              @click="closeWarrantyDialog"
            ></er-button>
              <!-- @click="closeAddClientDialog" -->
          </el-row>
        </template>
      </er-dialog>
      <settings-table
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :tableProps="elTableProps"
          :total="total"
          :button="true"
          :columnselector_require="false"
          :addPadingToSearch="false"
          :shouldRefreshVisible="false"
          :searchable="false"
          :headerCol="18"
          @loadData="handleLoadData"
          @add-item-click="handleAddClientClick"
      >
        <template slot="add-buttton">Claim</template>
        <template v-slot:customer="{ row }">
            {{ row.data.user_id.full_name }}
        </template>
        <template v-slot:product_type="{ row }">
            {{ deviceTypes[row.data.device_type] }}
        </template>
        <template v-slot:claim_date="{ row }">
          {{ formatDate(row.data.claim_date) }}
        </template>
        <template v-slot:issue_description="{ row }">
          <el-popover
            placement="top-start"
            width="300"
            trigger="hover"
            style="font-size: 12px;"
            class="job-description-styles"
            :content="row.data.issue">
              <span
                slot="reference"
                type="text"
                class="hiding-extra-reply-text"
                >{{ row.data.issue }}
              </span>
          </el-popover>
        </template>
        <template v-slot:product_failure_date="{ row }">
          {{ formatDate(row.data.failure_date) }}
        </template>
        <template v-slot:l1_review_date="{ row }">
          {{ formatDate(row.data.created_at) }}
        </template>
        <template v-slot:l2_review_date="{ row }">
          {{ formatDate(row.data.cs_reviewed_date) }}
        </template>
        <template v-slot:status="{ row }">
          {{ claimStatus[row.data.claim_status] }}
        </template>
          <template v-slot:actions="{ row }">
            <er-button
              type="text"
              size="mini"
              btnType="edit"
              :showLabel="false"
              :showIcon="false"
              :disabled="updateDisable(row.data)"
              @click="handleChangeStatus($event, 'EDIT', row.data)"
            >Update</er-button>
          </template>
      </settings-table>
    </div>
  </layout>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import layout from "@/components/superadmin/shared/layout";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import moment from "moment";
import localStore from "@/utils/localStore";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  props: ['tableData', 'columnsObject', "total", "usersData", "loading", "height"],
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      warrantyActionDialogTitle: "Add",
      saveLoading: false,
      warrantyActionDialogVisible: false,
      warrantyForm: {
        user_id: '',
        device_type: '',
        device_id: '',
        part_id: '',
        motor_id: '',
        motor_name: '',
        part_name: '',
        issue: '',
        failure_date: '',
        claim_status: 'OPEN',
        replaced: false,
        replaced_motor_id: ''
      },
      claimStatusOption: [
        {
          value: "CS_APPROVED",
          label: "CS Approved",
        },
        {
          value: "CS_REJECTED",
          label: "CS Rejected",
        },
        // {
        //   value: "CLOSED",
        //   label: "Closed",
        // },
      ],
      claimStatusFinalOption: [
        {
          value: "CLOSED",
          label: "Closed",
        },
      ],
      claimStatus: {
        OPEN: "Open",
        CS_APPROVED: "CS Approved",
        CS_REJECTED: "CS Rejected",
        QUALITY_APPROVED: "Quality Approved",
        QUALITY_REJECTED: "Quality Rejected",
        CLOSED: "Closed"
      },
      deviceTypesOptions: [
        {
          value: "POND_MOTHER",
          label: "PondMother",
        },
        {
          value: "POND_GUARD",
          label: "PondGuard",
        },
        {
          value: "SHRIMP_TALK",
          label: "ShrimpTalk",
        },
        {
          value: "GATEWAY",
          label: "Gateway",
        },
        // {
        //   value: "SHRIMP_SNAP",
        //   label: "ShrimpSnap",
        // },
        // {
        //   value: "FEED_BLOWER",
        //   label: "FeedBlower",
        // },
      ],
      deviceTypes: {
        POND_GUARD: "PondGuard",
        FEED_BLOWER: "FeedBlower",
        SHRIMP_SNAP: "ShrimpSnap",
        POND_MOTHER: "PondMother",
        SHRIMP_TALK: "ShrimpTalk",
        GATEWAY: "Gateway",
      },
      action: 'ADD',
      query: {
        page: 1,
        size: 30
      },
      options: [],
      deviceOptions: {
        POND_GUARD: [],
        FEED_BLOWER: [],
        SHRIMP_SNAP: [],
        POND_MOTHER: [],
        SHRIMP_TALK: [],
        GATEWAY: [],
      },
      dailogLoading: false,
      PartsOption: [],
      claimId: '',
      claim_status: '',
      isDealer: localStore.getItem("curr-user-type") === 'DEALER',
      isReplacebyEnable: false
    }
  },
  components: {
    settingsTable,
    layout
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getAllPartsData: "getAllPartsData",
      getAllMotorsData: "getAllMotorsData"
    }),
    ...mapGetters("dealer", {
      getUsers: "getUsers",
      getAllDealerPartsData: "getAllPartsData",
      getAllMotorsDealerData: "getAllMotorsData"
    }),
    elTableProps() {
      return {
        height: this.height || "calc(100vh - 128px)",
      };
    },
    pickerOptions() {
      return {
            disabledDate(time) {
              return time.getTime() > Date.now();
            }
          };
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    getStatusOptionsData() {
      if (this.allowCustomerTab.includes("L2") && !this.isDealer && (this.claim_status === 'QUALITY_APPROVED' || this.claim_status === 'QUALITY_REJECTED')) {
        return this.claimStatusFinalOption
      } else if (this.allowCustomerTab.includes("L2") && !this.isDealer) {
        return this.claimStatusOption
      } else {
        return this.claimStatusFinalOption
      }
    }
  },
  mounted() {
    this.isDealer ? this.fetchMotorDetailsDealer() : this.fetchMotorDetails();
  },
  methods: {
    ...mapActions("superadmin", {
      getApplicantsData: "getApplicantsData",
      fetchAllCustomers: "fetchAllUsers",
      getJobsData: "getJobsData",
      careerDataAddAnsEdit: "careerDataAddAnsEdit",
      fetchAUserDetails: "fetchAUserDetails",
      fetchPartDetails: "fetchPartDetails",
      handleSaveClaim: "handleSaveClaim",
      fetchMotorDetails: "fetchMotorDetails",
      handleUpdateClaim: "handleUpdateClaim",
      fetchAUserDetails_FeedBlower: "fetchAUserDetails_FeedBlower"
    }),
    ...mapActions("dealer", {
      fetchDealerRelatedUsers: "fetchDealerRelatedUsers",
      fetchDealerPartDetails: "fetchDealerPartDetails",
      fetchDealerDeviceDetails: "fetchDealerDeviceDetails",
      handleSaveClaimFromDealer: "handleSaveClaimFromDealer",
      handleUpdateClaimFromDealer: "handleUpdateClaimFromDealer",
      fetchMotorDetailsDealer: "fetchMotorDetails",
    }),
    formatDate(data) {
      return data ? moment(data).format("DD MMM, yy") : '--';
    },
    updateDisable(data) {
      return ((this.allowCustomerTab.includes("L1") || this.isDealer) && (data.claim_status === 'OPEN' || data.claim_status === 'CS_APPROVED' || data.claim_status === 'CLOSED')) || data.claim_status === 'CLOSED' || data.claim_status === null;
    },
    handleChangePartId(part_id) {
      this.warrantyForm = { ...this.warrantyForm, part_name: !this.isDealer ? this.getAllPartsData.filter(el => el._id === part_id)[0].part_name : this.getAllDealerPartsData.filter(el => el._id === part_id)[0].part_name }
    },
    handleDeviceIdChange(id) {
      this.warrantyForm = { ...this.warrantyForm, motor_name: this.deviceOptions.POND_MOTHER.filter(x => x.value === id)[0].motor_id.motor_id, motor_id: this.deviceOptions.POND_MOTHER.filter(x => x.value === id)[0].motor_id._id }
    },
    handleDeviceTypeChange(data) {
      !this.isDealer ? this.fetchPartDetails({ device_type: data }) : this.fetchDealerPartDetails({ device_type: data });
      this.warrantyForm = { ...this.warrantyForm, device_id: '', motor_name: '' }
    },
    optionsData(data, type = '') {
      return (data && data.length > 0) ? data.map(device => {
        return type === 'POND_MOTHER' ? {
          value: device._id,
          label: device.code,
          motor_id: device.motor_id
        } : {
          value: device._id,
          label: device.code
        }
      }) : [];
    },
    async loadingDevicesDetails(user_id) {
      try {
        this.dailogLoading = true;
        const response = !this.isDealer ? await this.fetchAUserDetails({
          user_id: user_id,
          params: { include_devices: true }
        })
        : await this.fetchDealerDeviceDetails({
          user_id: user_id,
          params: { include_devices: true }
        });
        // const user_feed_blower_response = await this.fetchAUserDetails_FeedBlower(
        //   {
        //     user_id: user_id
        //   }
        // );
        this.deviceOptions.GATEWAY = await this.optionsData(response.gateways, '');
        this.deviceOptions.POND_GUARD = await this.optionsData(response.pond_guards, '');
        this.deviceOptions.POND_MOTHER = await this.optionsData(response.pond_mothers, "POND_MOTHER");
        this.deviceOptions.SHRIMP_TALK = await this.optionsData(response.shrimp_talks, '');
        // this.deviceOptions.FEED_BLOWER = this.optionsData(user_feed_blower_response.feed_blowers);
        // this.deviceOptions.SHRIMP_SNAP = this.optionsData(response.shrimp_snaps);
      } catch (err) {
        console.error(err);
      } finally {
        this.dailogLoading = false;
      }
    },
    handleAddClientClick() {
      this.action = "ADD";
      this.warrantyActionDialogTitle = "New Claim";
      this.warrantyForm = {
        user_id: '',
        device_type: '',
        device_id: '',
        part_id: '',
        motor_id: '',
        motor_name: '',
        part_name: '',
        issue: '',
        failure_date: '',
        claim_status: 'OPEN',
        replaced: false,
        replaced_motor_id: ''
      };
      this.warrantyActionDialogVisible = true;
    },
    closeWarrantyDialog() {
      this.warrantyActionDialogVisible = false;
      this.$refs.dialogListForm.reset();
      this.action = "";
    },
    handleLoadData(query) {
      this.query = query;
      this.$emit("handleLoadData", query)
    },
    async handleSaveWarranty() {
      this.dailogLoading = true;
      try {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        if (this.action === 'ADD') {
          const payload = { ...this.warrantyForm, failure_date: this.warrantyForm.failure_date + "T00:00:00.000Z", claim_date: moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z" }
          if (payload.motor_id === '') {
            delete payload.motor_id;
          }
          !this.isDealer ? await this.handleSaveClaim(payload) : await this.handleSaveClaimFromDealer(payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: "Claim Registered successfully.",
            duration: 5000,
            type: "success"
          });
        } else {
          const payload = { ...this.warrantyForm };
          if (payload.motor_id === '') {
            delete payload.motor_id;
            delete payload.motor_name;
          }
          if (payload.replaced_motor_id === '') {
            delete payload.replaced_motor_id;
          }
          !this.isDealer ? await this.handleUpdateClaim({ payload: payload, claimId: this.claimId }) : await this.handleUpdateClaimFromDealer({ payload: payload, claimId: this.claimId });
          await this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: "Claim Status Upadated successfully.",
            duration: 5000,
            type: "success"
          });
        }
        await this.$emit("handleLoadData", { page: this.query.page, limit: this.query.limit, order_by: 'created_at' })
        await this.closeWarrantyDialog();
      } catch (err) {
        console.error(err);
        this.$notify({
            title: 'Error',
            message: "Something went wrong. Please try again",
            duration: 5000,
            type: "error"
          });
      } finally {
        this.dailogLoading = false;
      }
    },
    async handleChangeStatus(event, action, claim) {
      if (action === 'EDIT') {
        this.action = 'EDIT';
        this.warrantyActionDialogVisible = true;
        this.claimId = claim._id;
        this.claim_status = claim.claim_status
        if (claim.device_type === 'POND_MOTHER') {
          this.isReplacebyEnable = !this.isDealer ? this.getAllPartsData.filter(el => el._id === claim.part_id._id)[0].part_id !== claim.motor_id.motor_id : this.getAllDealerPartsData.filter(el => el._id === claim.part_id._id)[0].part_id !== claim.motor_id.motor_id;
        }
        this.options = [{ value: claim.user_id._id, label: claim.user_id.full_name + " -- " + claim.user_id.email }]
        this.deviceOptions[claim.device_type] = claim.device_type === 'POND_MOTHER' ? [{ value: claim.device_id._id, label: claim.device_id.code, motor_id: claim.motor_id }] : [{ value: claim.device_id._id, label: claim.device_id.code }];
        this.warrantyForm = {
          user_id: claim?.user_id?._id,
          device_type: claim.device_type,
          device_id: claim?.device_id?._id,
          part_id: claim?.part_id?._id,
          motor_id: claim?.motor_id?._id || '',
          motor_name: claim?.motor_id?.motor_id || '',
          part_name: claim.part_id.part_name,
          issue: claim.issue,
          failure_date: claim.failure_date,
          replaced: false,
          replaced_motor_id: ''
        }
      }
    },
    loadUserOptions() {
      this.options = [];
      if (!this.isDealer) {
          this.$store.getters["superadmin/getUsers"].map(data => {
          this.options.push({
            value: data._id,
            label: data.full_name + " -- " + data.email
          })
        })
      } else {
          this.$store.getters["dealer/getDealerUsers"].map(data => {
          this.options.push({
            value: data._id,
            label: data.first_name + data.last_name + " -- " + data.email
          })
        })
      }
      return this.query.user_name !== undefined ? this.options : [];
    },
    async fetchUsersData(string) {
      if (string === "") {
        this.query.page = 1;
        this.query.limit = 30;
        delete this.query.user_name;
      } else {
        this.query.user_name = string.trim();
        !this.isDealer ? await this.fetchAllCustomers({
          infinite_scroll: false,
          params: this.query
        })
        : this.fetchDealerRelatedUsers({
          infinite_scroll: false,
          params: this.query
        })
        await this.loadUserOptions();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.hiding-extra-reply-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
</style>
<style lang="scss">
.div_margin {
  margin: 10px;
}
.warrantyAction_dialog {
  .warrantyPageTextarea {
    @include responsiveProperty(width, 320px, 357px, 400px);
  }
  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 320px, 357px, 400px);
    background-color: white !important;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 160px, 220px);
  }
}
</style>
