<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgGraphs.vue
Description: This file contains the pondguard graphs built using highcharts
-->
<template>
  <grid-layout
    :layout.sync="layout"
    :col-num="1"
    :row-height="500"
    :is-draggable="draggable"
    :is-resizable="resizable"
    :vertical-compact="true"
    :use-css-transforms="true"
  >
    <grid-item
      v-for="(item, key, index) in componentMapper"
      :static="layout[index].static"
      :x="layout[index].x"
      :y="layout[index].y"
      :w="layout[index].w"
      :h="layout[index].h"
      :i="layout[index].i"
      :key="key"
      dragAllowFrom=".el-card__header"
    >
      <component
        :is="item"
        v-loading="checkGraphLoading(key)"
        :device-details="deviceDetails"
        :timeZoneStr="deviceTimeZoneStr"
        @changedDateQuery="initComponent"
      ></component>
    </grid-item>
  </grid-layout>
</template>

<script>
import pgPHGraph from "./sensor-logs/pgPHGraph";
import pgTemperatureGraph from "./sensor-logs/pgTemperatureGraph";
import pgBatteryVoltageGraph from "./sensor-logs/pgBatteryVoltageGraph.vue";
import pgChargingCurrentGraph from "./sensor-logs/pgChargingCurrentGraph.vue";
import pgDisolveOxygenGraph from "./sensor-logs/pgDisolveOxygenGraph";
// import pgSalinityGraph from "./sensor-logs/pgSalinityGraph";
import pgLoadCurrent from "./sensor-logs/pgLoadCurrentGraph";
import pgSolarVoltageGraph from "./sensor-logs/pgSolarVoltageGraph";
import pgErrorCodeGraph from "./sensor-logs/pgErrorCodeGraph";
import VueGridLayout from "vue-grid-layout";
import pgDoSaturationGraphVue from "./sensor-logs/pgDoSaturationGraph.vue";
import pgElectrodeResistanceGraph from "./sensor-logs/pgElectrodeResistanceGraph.vue";
import pgTemperatureResistanceGraph from "./sensor-logs/pgTemperatureResistanceGraph.vue";
import pgConductivityGraph from "./sensor-logs/pgConductivityGraph.vue";
import pgTdsGraph from "./sensor-logs/pgTdsGraph.vue";
import pgSalinitySensorGraph from "./sensor-logs/pgSalinitySensorGraph.vue";
import pgDoSensorDisconnectedGraphVue from './sensor-logs/pgDoSensorDisconnectedGraph.vue';
import pgDoSensorNoResponseGraphVue from './sensor-logs/pgDoSensorNoResponseGraph.vue';
import pgDoInvalidReadingGraphVue from './sensor-logs/pgDoInvalidReadingGraph.vue';
import pgDoCalibrationFailedGraphVue from './sensor-logs/pgDoCalibrationFailedGraph.vue';
import pgSolarDisconnectedGraphVue from './sensor-logs/pgSolarDisconnectedGraph.vue';
export default {
  components: {
    pgBatteryVoltageGraph,
    pgChargingCurrentGraph,
    pgLoadCurrent,
    pgPHGraph,
    pgErrorCodeGraph,
    pgTemperatureGraph,
    pgDisolveOxygenGraph,
    pgDoSaturationGraphVue,
    pgElectrodeResistanceGraph,
    pgTemperatureResistanceGraph,
    pgConductivityGraph,
    pgTdsGraph,
    pgSalinitySensorGraph,
    // pgSalinityGraph,
    pgSolarVoltageGraph,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    pgDoSensorDisconnectedGraphVue,
    pgDoSensorNoResponseGraphVue,
    pgDoInvalidReadingGraphVue,
    pgDoCalibrationFailedGraphVue,
    pgSolarDisconnectedGraphVue
  },
  props: ["deviceDetails", "loadingGraphName", "loadingGraphValue"],
  data () {
    return {
      layout: [
        { x: 0, y: 0, w: 2, h: 1, i: "0", static: false },
        { x: 0, y: 1, w: 2, h: 1, i: "1", static: false },
        { x: 0, y: 2, w: 2, h: 1, i: "2", static: false },
        { x: 0, y: 3, w: 2, h: 1, i: "3", static: false },
        { x: 0, y: 4, w: 2, h: 1, i: "4", static: false },
        { x: 0, y: 5, w: 2, h: 1, i: "5", static: false },
        { x: 0, y: 6, w: 2, h: 1, i: "6", static: false },
        { x: 0, y: 7, w: 2, h: 1, i: "7", static: false },
        { x: 0, y: 8, w: 2, h: 1, i: "8", static: false },
        { x: 0, y: 9, w: 2, h: 1, i: "9", static: false },
        { x: 0, y: 10, w: 2, h: 1, i: "10", static: false },
        { x: 0, y: 11, w: 2, h: 1, i: "11", static: false },
        { x: 0, y: 12, w: 2, h: 1, i: "12", static: false },
        { x: 0, y: 13, w: 2, h: 1, i: "13", static: false },
        { x: 0, y: 14, w: 2, h: 1, i: "14", static: false },
        { x: 0, y: 15, w: 2, h: 1, i: "15", static: false },
        { x: 0, y: 16, w: 2, h: 1, i: "16", static: false },
        { x: 0, y: 17, w: 2, h: 1, i: "17", static: false },
        { x: 0, y: 18, w: 2, h: 1, i: "18", static: false }
      ],
      draggable: true,
      resizable: true,
      index: 0
    };
  },
  methods: {
    initComponent: function (params, parameter) {
      this.$emit("changedDateQuery", params, parameter);
    }
  },
  computed: {
    checkGraphLoading () {
      return (graphName) => {
        return graphName === this.loadingGraphName
          ? this.loadingGraphValue
          : false;
      };
    },
    deviceTimeZoneStr () {
      return (
        this.customerDetails || {
          timezone: {
            name: "UTC",
            offset: 0
          }
        }
      ).timezone.name;
    },
    customerDetails () {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
          timezone: {
            name: "UTC",
            offset: 0
          }
        };
      }
    },
    componentMapper: function () {
      return {
        ph: "pgPHGraph",
        temperature: "pgTemperatureGraph",
        disolve_oxygen: "pgDisolveOxygenGraph",
        // salinity: "pgSalinityGraph",
        solar_voltage: "pgSolarVoltageGraph",
        battery_voltage: "pgBatteryVoltageGraph",
        charging_current: "pgChargingCurrentGraph",
        error_code: "pgErrorCodeGraph",
        load_current: "pgLoadCurrent",
        do_saturation: "pgDoSaturationGraphVue",
        electrode_resistance: "pgElectrodeResistanceGraph",
        temperature_resistance: "pgTemperatureResistanceGraph",
        conductivity: "pgConductivityGraph",
        tds: "pgTdsGraph",
        salinity: "pgSalinitySensorGraph",
        do_sensor_disconnected: "pgDoSensorDisconnectedGraphVue",
        do_sensor_no_response: "pgDoSensorNoResponseGraphVue",
        do_invalid_reading: "pgDoInvalidReadingGraphVue",
        do_calibration_failed: "pgDoCalibrationFailedGraphVue",
        solar_disconnected: "pgSolarDisconnectedGraphVue"
      };
    }
  }
};
</script>

<style lang="scss">
</style>
