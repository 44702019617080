<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: GatewayTab.vue
Description: This file has table component that displays the gateways alloted to the account manager.
-->
<template>
  <div
    class="account-manager-gateway-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <layout-account-manager-card
      v-show="!loading"
      search-property="device_code"
      toolbarLayout="search"
      :showDownloadDropdown="true"
      :queryParams="queryParams"
      @add-item-click="handleAddDealerClick"
      @loadData="handleLoadData"
      @download-excel="handleDownloadExcel"
      placeHolderForSearch="Device ID"
    >
      <template v-slot:heading>
        <div class="main_header">
          <img src="@/assets/dealer/devices.svg" atl="down arrow" />
          <p style="margin-left: 10px">
            {{ $t("Comn_gateway") }}
          </p>
        </div>
      </template>
      <template v-slot:table>
        <defaultTable
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          class="add_PondMother"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:email_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:first_name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:status_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:code_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:added_date_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:first_assigned_date_header="{ header }">
            <el-tooltip
              :content="$t(header.sub_label)"
              placement="top"
              effect="light"
            >
              <p>{{ $t(header.label) }}</p>
            </el-tooltip>
          </template>
          <template v-slot:last_assigned_date_header="{ header }">
            <el-tooltip
              :content="$t(header.sub_label)"
              placement="top"
              effect="light"
            >
              <p>{{ $t(header.label) }}</p>
            </el-tooltip>
          </template>
          <template v-slot:first_user_assigned_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.first_user_assigned_date)) }}
          </template>
          <template v-slot:last_user_assigned_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.last_user_assigned_date)) }}
          </template>
          <template v-slot:status="{ row }">
            {{ getStatus(row.data, "GATEWAY") }}
          </template>
          <template v-slot:code="{ row }">
            {{ row.data.code }}
          </template>
          <template v-slot:first_name="{ row }">
            {{
              row.data.user_details
                ? row.data.user_details.first_name
                : getDealerUsername.first_name
            }}

            {{
              row.data.user_details
                ? row.data.user_details.last_name
                : getDealerUsername.last_name
            }}
            <!-- <br />
            {{
              row.data.user_details
                ? row.data.user_details.email
                : getDealerUsername.email
            }} -->
          </template>
          <template v-slot:email="{ row }">
            {{
              row.data.user_details
                ? row.data.user_details.email
                : getDealerUsername.email
            }}
          </template>
          <template v-slot:added_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.added_date)) }}
          </template>
        </defaultTable>
      </template>
    </layout-account-manager-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "../defaultTable";
import layoutAccountManagerCard from "../layoutAccountmanagerCard.vue";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import accountManagerDevicesMixin from "./accountManagerDevicesMixin";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    notificationMixin,
    filtersMixin,
    adminDatesHandlerMixin,
    accountManagerDevicesMixin("CONTAINER")
  ],
  props: [],
  data: function () {
    return {
      tableData: [],
      unassignedGWs: [],
      unassignedUsers: [],
      gwSearchQuery: {},
      userSearchQuery: {},
      listLoading: false,
      selectedUsers: [],
      total: 0,
      selectedGws: [],
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      },
      ehm__errMessagesObject: {
        selectedGws: [],
        selectedUsers: ""
      }
    };
  },
  components: {
    defaultTable,
    layoutAccountManagerCard
  },
  computed: {
    ...mapGetters("accountManager", {
      getAccountManagerGws: "getAccountManagerGws"
    }),
    elTableProps() {
      return {
        defaultSort: {
          prop: "code",
          order: "ascending"
        }
      };
    },
    getDealerUsername() {
      return this.$store.getters["dealer/getDealerProfile"];
    },
    formStrings() {
      return {
        selectedUsers: {
          label: this.$t("DL_select_user"),
          placeholder: this.$t("Comn_select")
        },
        selectedGws: {
          label: this.$t("select_gateways"),
          placeholder: this.$t("Comn_select")
        }
      };
    },

    columnsObject: function () {
      return [
        {
          prop: "code",
          name: "code",
          label: "Comn_Device_ID",
          required: true,
          sortable: true
        },

        {
          prop: "first_name",
          name: "first_name",
          label: "DL_assigned_customer",
          required: true,
          sortable: false
        },
        {
          prop: "email",
          name: "email",
          label: "Comn_email_id",
          required: true,
          sortable: false
        },
        {
          prop: "first_user_assigned_date",
          name: "first_assigned_date",
          label: "DL_first_assigned_date",
          sub_label: "DL_first_assigned_date_content",
          required: true,
          sortable: false
        },
        {
          prop: "last_user_assigned_date",
          name: "last_assigned_date",
          label: "DL_last_assigned_date",
          sub_label: "DL_last_assigned_date_content",
          required: true,
          sortable: false
        },
        // {
        //   prop: "updated_at",
        //   name: "added_date",
        //   label: "DL_assigned_date",
        //   required: true,
        //   sortable: false
        // },
        {
          name: "status",
          prop: "status",
          label: "device_status",
          required: true,
          sortable: false
        }
      ];
    },
    downloadColumnsObject: function () {
      return [
        {
          prop: "code",
          name: "code",
          label: "Comn_Device_ID",
          required: true,
          sortable: true
        },

        {
          prop: "first_name",
          name: "first_name",
          label: "DL_assigned_customer",
          required: true,
          sortable: false
        },
        {
          prop: "email",
          name: "email",
          label: "Comn_email_id",
          required: true,
          sortable: false
        },
        // {
        //   prop: "first_user_assigned_date",
        //   name: "first_assigned_date",
        //   label: "DL_first_assigned_date",
        //   sub_label: "DL_first_assigned_date_content",
        //   required: true,
        //   sortable: false
        // },
        // {
        //   prop: "last_user_assigned_date",
        //   name: "last_assigned_date",
        //   label: "DL_last_assigned_date",
        //   sub_label: "DL_last_assigned_date_content",
        //   required: true,
        //   sortable: false
        // },
        // {
        //   prop: "updated_at",
        //   name: "added_date",
        //   label: "DL_assigned_date",
        //   required: true,
        //   sortable: false
        // },
        {
          name: "status",
          prop: "status",
          label: "device_status",
          required: true,
          sortable: false
        }
      ];
    }
  },
  methods: {
    ...mapActions("accountManager", {
      fetchAllManagerDevices: "fetchAllManagerDevices"
    }),
    async handleLoadData(query) {
      try {
        this.queryParams = query;
        this.loading = true;
        const response = await this.fetchAllManagerDevices({
          params: query,
          infinite_scroll: false,
          device_type: "gateway"
        });
        this.tableData = this.getAccountManagerGws;
        this.total = response.total;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
   async handleDownloadExcel() {
      // fetch all gateway device for account manager
      this.loading = true;
      const response = await this.fetchAllManagerDevices({
          params: { get_all: true },
          infinite_scroll: false,
          device_type: "gateway"
        });
        console.log('response', response);
        this.handleDownloadExcelMixin(response?.gateways.length > 0 ? response.gateways : [], this.downloadColumnsObject, "GATEWAY");
        this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.account-manager-gateway-table {
  height: 100%;
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .main_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 70vh, 76vh);
  }
}
.gateway_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 260px, 300px, 350px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 180px, 210px);
  }
}
</style>
