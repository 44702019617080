<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkIntake.vue
Description:  This file contains UI components of Shrimp Talk Intake which includes customer details and ShrimpTalk Intake graph
-->
<template>
  <el-row>
    <el-col :span="4">
      <item-list-side-bar
        :item-list="getListOfUsers"
        item-type="users"
        :init-item-id="getInitUserId"
        class="side-list-menu"
        :showProperty="['first_name', 'last_name']"
        :search="true"
        search-property="user_name"
        :totalItemsCount="totalItemsCount"
        :dataFetchMethod="UserData"
        @loadData="UserData"
        @cleared="UserData"
        @itemClicked="handleUserSelected"
      ></item-list-side-bar>
    </el-col>
    <el-col :span="20">
      <basic-customer-details
        v-loading="loading"
        :showDeviceCode="false"
        :userDetails="this.particularUser"
        :showCustomerDetails="false"
        :showDealerDetails="false"
        :hideDeviceDetails="true"
        :allowAddCustomer="false"
        :allowCustomerLogin="false"
      >
        <template slot="moreDetails">
          <div>
            <el-row class="details__heading">Customer Name</el-row>
            <el-row class="details__heading__value">
              <el-input
                size="mini"
                :value="particularUser.first_name"
              ></el-input>
            </el-row>
          </div>

          <!-- <div>
            <el-row class="details__heading">Location</el-row>
            <el-row class="details__heading__value">
              {{ (this.particularUser.country || { name: "" }).name }}
            </el-row>
          </div> -->

          <div>
            <el-row class="details__heading">Pond Locations</el-row>
            <er-select
              size="mini"
              v-model="selectedLocation"
              @change="handleChangeInLocation"
              value-key="_id"
            >
              <el-option
                v-for="(location, key) in listOfAllLocations"
                :key="`location-${key}`"
                :value="location"
                :label="location.name"
                >{{ location.name }}
              </el-option>
            </er-select>
          </div>
          <div>
            <el-row class="details__heading">Ponds</el-row>
            <er-select
              size="mini"
              v-model="selectedPond"
              value-key="_id"
              :filterable="true"
              @change="handleChangePond"
            >
              <el-option-group
                v-for="group in getPondGroupByStatus"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="(pond, key) in group.options"
                  :key="`pond-${key}`"
                  :value="pond"
                  :label="pond.title"
                >
                  {{ pond.title }}
                </el-option>
              </el-option-group>
            </er-select>
          </div>
          <div>
            <el-row class="details__heading">Select Date Range</el-row>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              unlink-panels
              size="mini"
              format="dd-MMM-yy"
              value-format="yyyy-MM-dd"
              :availableInterval="-1"
              @change="handleDateRange"
            ></el-date-picker>
          </div>
        </template>
      </basic-customer-details>
      &nbsp;
      <ShrimpTalkIntakeGraph
        :list-of-sts="getSelectedPondStIds"
        :date-range="dateRange"
        :pond-id="getPondId"
        :userTimeZoneString="getParticularUserTimeZoneString"
      ></ShrimpTalkIntakeGraph>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import ShrimpTalkIntakeGraph from "./ShrimpTalkIntakeGraph";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
export default {
  inject: ["shrimpTalkView"],
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  components: {
    itemListSideBar,
    basicCustomerDetails,
    ShrimpTalkIntakeGraph
  },
  data: function() {
    return {
      activeName: "first",
      totalItemsCount: 100,
      actionToPerform: "",
      particularUser: {},
      listOfAllPonds: [],
      selectedPond: "",
      listOfAllLocations: [],
      selectedLocation: "",
      location_id: "",
      loading: false,
      dateRange: []
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getUsersObj: "getUsersObj",
      getParticularDealer: "getParticularDealer",
      getAllPondsofUser: "getAllPondsofUser",
      getAllLocationsofUser: "getAllLocationsofUser"
    }),
    ...mapGetters("user", {
      userLocations: "getUserLocationsObj",
      getCurrUserLocation: "getCurrUserLocation",
      getProfileUrl: "getProfileUrl"
    }),
    getParticularUserTimeZoneString() {
      if (this.particularUser.timezone) {
        return this.particularUser.timezone.name;
      }
      return "UTC";
    },
    getListOfUsers() {
      return this.getUsers;
    },
    getInitUser() {
      const user = this.getListOfUsers[0];
      return user || {};
    },
    getInitUserId() {
      return this.urlParams.user_id || this.getInitUser._id;
    },
    getLocations() {
      return this.getAllLocationsofUser;
    },
    getObjLocations() {
      return this.getLocations.reduce((acc, location) => {
        acc[location._id] = location;
        return acc;
      }, {});
    },
    getPondGroupByStatus() {
      const groupByOptions = this.listOfAllPonds.reduce(
        (acc, pond) => {
          acc[pond.status].options.push(pond);
          return acc;
        },
        {
          ACTIVE: { label: "Active", options: [] },
          INACTIVE: { label: "Inactive", options: [] }
        }
      );
      return groupByOptions;
    },
    getPonds() {
      return this.$lodash
        .cloneDeep(this.getAllPondsofUser)
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    getPondsObj() {
      return this.getPonds.reduce((acc, pond) => {
        acc[pond._id] = pond;
        return acc;
      }, {});
    },
    getPondId() {
      return this.selectedPond._id;
    },
    getSelectedPondStIds() {
      return this.selectedPond ? this.selectedPond.shrimp_talks : [];
    },
    urlParams: function() {
      return this.shrimpTalkView.urlParams;
    }
  },
  async mounted() {
    this.initDate();
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllUsers: "fetchAllUsers",
      fetchAUserDetails: "fetchAUserDetails",
      fetchAllPondsofUser: "fetchAllPondsofUser",
      fetchAllLocationsofUser: "fetchAllLocationsofUser"
    }),
    initDate() {
      if (!this.urlParams.from_date) {
        this.setUrlParams(
          "from_date",
          this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(new Date(), { days: 3 }),
            "yyyy-MM-dd"
          )
        );
      }
      if (!this.urlParams.to_date) {
        this.setUrlParams(
          "to_date",
          this.adm__dateUtilsLib.formatDate(new Date(), "yyyy-MM-dd")
        );
      }
      this.$set(this.dateRange, 0, this.urlParams.from_date);
      this.$set(this.dateRange, 1, this.urlParams.to_date);
    },
    handleDateRange() {
      this.setUrlParams("from_date", this.dateRange[0]);
      this.setUrlParams("to_date", this.dateRange[1]);
    },
    setUrlParams(property, value) {
      this.$set(this.shrimpTalkView.urlParams, property, value);
    },
    deleteUrlParams(property) {
      this.$delete(this.shrimpTalkView.urlParams, property);
    },
    async handleChangePond() {
      console.log('hello', this.selectedPond);
      if (this.selectedPond !== undefined && this.selectedPond !== null) {
      this.setUrlParams("pond_id", this.selectedPond._id);
      }
    },
    async handleChangeInLocation() {
      if (!this.selectedLocation) {
        this.listOfAllPonds = [];
        this.selectedPond = undefined;
        this.deleteUrlParams("pond_id");
        return;
      }
      const locationId = this.selectedLocation._id;
      this.setUrlParams("location_id", locationId);
      await this.initPonds();
    },
    async initPonds() {
      await this.fetchAllPondsofUser({
        params: {
          get_all: true,
          location_id: this.urlParams.location_id
        }
      });
      this.listOfAllPonds = this.getPonds;
      this.selectedPond = undefined;
      if (this.listOfAllPonds.length > 0) {
        if (!this.getPondsObj[this.urlParams.pond_id]) {
          this.selectedPond =
            this.getPondGroupByStatus.ACTIVE.options[0] ||
            this.getPondGroupByStatus.INACTIVE.options[0];
          this.handleChangePond();
        }
        this.selectedPond = this.getPondsObj[this.urlParams.pond_id];
      }
    },
    async UserData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllUsers({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleUserSelected(user_id) {
      try {
        this.loading = true;
        const response = await this.fetchAUserDetails({
          user_id: user_id
        });
        console.log('this.urlParams', this.urlParams);
        this.particularUser = response;
        this.setUrlParams("user_id", this.particularUser._id);
        await this.initLocations();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initLocations() {
      await this.fetchAllLocationsofUser({
        user_id: this.particularUser._id
      });
      this.listOfAllLocations = this.getLocations;
      this.selectedLocation = undefined;
      if (this.listOfAllLocations.length > 0) {
        if (!this.getObjLocations[this.urlParams.location_id]) {
          this.selectedLocation = this.listOfAllLocations[0];
          await this.handleChangeInLocation();
        }
        this.selectedLocation = this.getObjLocations[
          this.urlParams.location_id
        ];
        await this.initPonds();
      }
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
        const userresponse = await this.fetchAUserDetails({
          user_id: this.getInitUserId
        });
        this.particularUser = userresponse;
        if (!this.getUsersObj[this.urlParams.user_id]) {
          this.setUrlParams("user_id", this.particularUser._id);
        }
        await this.initLocations();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
