var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-scrollbar',{staticClass:"user-table-scroll user-list-table",attrs:{"viewClass":"user-table-scroll-view","wrapClass":"user-table-scroll-wrap"}},[_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale + '1',attrs:{"tableData":_vm.getOtherUserTableData,"columns":_vm.tableColumns,"el-table-props":_vm.tableProps,"action-column":false,"type":"white-header-table","layout":"table","unique-id":"user-access-managment__table","searchOnTableData":_vm.userSearch},on:{"row-click":_vm.handleRowClick},scopedSlots:_vm._u([{key:"first_name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"table-header-text captalize"},[_vm._v("\n          "+_vm._s(((row.data.first_name) + " " + (row.data.last_name)))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"table-main-text"},[_vm._v(_vm._s(row.data.email))])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{class:[
          'user-status-tag',
          row.data.status === 'ACTIVE' && 'is-active'
        ]},[_c('p',[_vm._v(_vm._s(_vm.getStatus(row.data)))])])]}},{key:"department",fn:function(ref){
        var row = ref.row;
return [_c('p',{staticClass:"table-main-text"},[_vm._v("\n        "+_vm._s(_vm.departmentNameHandler(row.data.department))+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }