<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name:DealerDetailsTable.vue
Description:This file contains combination of the basicCustomerDetails(dealer details) , AddCustomers,AddPondMother,AddPondGuard,AddShrimpTalk,AddGateway,itemListSideBar(dealer's List)  of these components
-->
<template>
  <el-row>
    <el-row>
      <el-col :span="4">
        <item-list-side-bar
          :item-list="getDealers"
          :init-item-id="dealer_id"
          item-type="dealers"
          class="side-list-menu"
          :showProperty="['first_name', 'last_name']"
          :search="true"
          search-property="user_name"
          :totalItemsCount="totalItemsCount"
          :dataFetchMethod="DealerData"
          @loadData="DealerData"
          @cleared="DealerData"
          @itemClicked="handlePmSelected"
        ></item-list-side-bar>
      </el-col>

      <el-col :span="20">
        <el-row style="display: flex; align-items: center">
          <el-col :span="20">
            <basic-customer-details
              :showDeviceCode="false"
              :userDetails="this.getParticularDealer"
              :showDealerDetails="true"
              :hideDeviceDetails="true"
              :allowAddCustomer="false"
              :allowCustomerLogin="false"
            >
              <template slot="moreDetails">
                <center>
                  <el-row class="details__heading">Location</el-row>
                  <el-row class="details__heading__value">
                    {{
                      (this.getParticularDealer.country || { name: "" }).name
                    }}
                  </el-row>
                </center>
              </template>
            </basic-customer-details>
          </el-col>
        </el-row>

        <div>
          <el-row>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="Customers" name="first"
                ><AddCustomers v-bind:dealer_id="dealer_id"></AddCustomers
              ></el-tab-pane>
              <el-tab-pane label="Pond Mothers" name="second"
                ><AddPondMother v-bind:dealer_id="dealer_id"></AddPondMother>
              </el-tab-pane>
              <el-tab-pane label="Pond Guards" name="third"
                ><AddPondGuard v-bind:dealer_id="dealer_id"></AddPondGuard>
              </el-tab-pane>
              <el-tab-pane label="Shrimp Talks" name="fourth"
                ><AddShrimpTalk v-bind:dealer_id="dealer_id"></AddShrimpTalk>
              </el-tab-pane>
              <el-tab-pane label="Gateway" name="fifth"
                ><AddGateway v-bind:dealer_id="dealer_id"></AddGateway>
              </el-tab-pane>
              <el-tab-pane label="Reports" name="sixth">Reports</el-tab-pane>
            </el-tabs>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import AddCustomers from "@/components/superadmin/dealer/AddCustomers";
import AddPondMother from "@/components/superadmin/dealer/AddPondMother";
import AddShrimpTalk from "@/components/superadmin/dealer/AddShrimpTalk";
import AddPondGuard from "@/components/superadmin/dealer/AddPondGuard";
import AddGateway from "@/components/superadmin/dealer/AddGateway";
export default {
  props: ["dealer_id"],
  components: {
    itemListSideBar,
    basicCustomerDetails,
    AddCustomers,
    AddPondMother,
    AddGateway,
    AddPondGuard,
    AddShrimpTalk
  },

  data: function () {
    return {
      activeName: "first",
      dialogVisible: false,
      state2: "",
      totalItemsCount: 100,
      searchDealer: "",
      selectedDealer: {},
      actionToPerform: "",
      newDealer: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirm_password: "",
        country: {
          name: "",
          code: ""
        },
        timezone: {
          name: "",
          offset: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getCustomers: "getUsers",
      getDealers: "getDealers",
      getParticularDealer: "getParticularDealer"
    })
  },

  methods: {
    ...mapActions("superadmin", {
      fetchAllDealers: "fetchAllDealers",
      fetchDealerById: "fetchDealerById",
      alreadyAssignedUsersToDealer: "alreadyAssignedUsersToDealer",
      fetchAllUnassignedDeviceDetails: "fetchAllUnassignedDeviceDetails",
      fetchDealer_PondMothers: "fetchDealer_PondMothers",
      fetchDealer_PondGaurds: "fetchDealer_PondGaurds",
      fetchDealer_ShrimpTalks: "fetchDealer_ShrimpTalks",
      fetchDealer_Gateways: "fetchDealer_Gateways"
    }),

    handleClick(tab, event) {
      console.log(tab, event);
    },

    async DealerData({ query, infinite_scroll = false }) {
      try {
        const response = await this.fetchAllDealers({
          infinite_scroll: infinite_scroll,
          params: query
        });
        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },

    async handlePmSelected(dealer_id) {
      this.$router.replace(dealer_id);
      await this.fetchDealerById(dealer_id);
    },

    handleSelect(item) {
      console.log(item);
    },

    async initComponent() {
      try {
        this.loading = true;
        await this.fetchDealerById(this.dealer_id);
        await this.fetchAllDealers({
          infinite_scroll: false,
          params: {
            page: 1,
            limit: 19,
            order_by: "first_name",
            order_type: "asc"
          }
        });
        // await this.initDialogForAddDevices();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss"></style>
