<script>
import { mapActions, mapGetters } from "vuex";
import { ADMIN_USER_UI_STATES } from "@/constants/uiStates";
import adminUserAccessMgmtUpdatePassword from "./adminUserAccessMgmtUpdatePassword.vue";
import adminUserDetails from "./adminUserDetails.vue";
import adminUserDetailsForm from "./adminUserDetailsForm.vue";
import adminNoUserSelected from "./adminNoUserSelected.vue";
import loader from "@/components/base/Loader";
export default {
  components: {
    adminUserDetailsForm,
    adminUserAccessMgmtUpdatePassword,
    adminUserDetails,
    loader,
    adminNoUserSelected
  },
  data() {
    return {
      ADMIN_USER_UI_STATES,
      loading: undefined
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getSidePanelState: "getSidePanelState"
    }),
    getSidePanelUIState() {
      return this.getSidePanelState.UI_STATE;
    }
  },
  render(h) {
    const uiStateToView = {
      [this.ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS]: this.getUserDetailsView,
      [this.ADMIN_USER_UI_STATES.UPDATE_SUB_USER_PASSWORD]: this
        .getSubUserUpdatePasswordView,
      [this.ADMIN_USER_UI_STATES.CREATE_NEW_SUB_USER_DETAILS]: this
        .getSubUserAddNewView,
      [this.ADMIN_USER_UI_STATES.EDIT_SUB_USER_DETAILS]: this
        .getSubUserUpdateDetailsView,
      [this.ADMIN_USER_UI_STATES.NO_USER_SELECTED]: this.getNoUserSelected,
      [this.ADMIN_USER_UI_STATES.LOADING]: this.getLoadingView
    };
    return (
      <el-scrollbar
        class={[
          "right-panel-scroll",
          `state-${this.getSidePanelUIState.toLowerCase().replaceAll("_", "-")}`
        ]}
        viewClass="right-panel-scroll-view"
        wrapClass="right-panel-scroll-wrap"
        ref="rightPanelScroll"
      >
        {uiStateToView[this.getSidePanelUIState](h)}
      </el-scrollbar>
    );
  },
  methods: {
    ...mapActions("superadmin", {
      restoreToIntialState: "restoreToIntialState"
    }),
    getSubUserAddNewView(h) {
      return (
        <adminUserDetailsForm
          editType="CREATE"
          onStatusChange={this.handleStatusChange}
          onLoading={this.handleLoading}
        />
      );
    },
    getSubUserUpdatePasswordView(h) {
      return <adminUserAccessMgmtUpdatePassword />;
    },
    getSubUserUpdateDetailsView(h) {
      return (
        <adminUserDetailsForm
          editType="UPDATE"
          onStatusChange={this.handleStatusChange}
          onLoading={this.handleLoading}
        />
      );
    },
    getUserDetailsView(h) {
      return <adminUserDetails />;
    },
    getNoUserSelected(h) {
      return <adminNoUserSelected></adminNoUserSelected>;
    },
    async handleStatusChange(statusPayload) {
      if (statusPayload.status === "SUCCESS") {
        await this.restoreToIntialState({
          sidePanelUserId: statusPayload.userId,
          sidePanelUIState: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS
        });
      }
      this.handleLoading(false);
    },
    handleLoading(value) {
      if (value) {
        this.loading = this.$loading({
          target: this.$refs.rightPanelScroll.$el,
          lock: true,
          text: this.$t("Comn_loading")
        });
      } else {
        this.loading.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.right-panel-scroll {
  position: relative;
  @include responsiveProperty(height, 74vh, 78vh, 82vh);
  &::v-deep .right-panel-scroll-wrap {
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }
  .right-panel-scroll-view {
    padding-right: 10px;
  }
  &.state-no-user-selected::v-deep .right-panel-scroll-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &::v-deep .right-panel-content-alignment {
    padding-top: 15px;
    @include responsiveProperty(padding-left, 55px, 58px, 61px);
    @include responsiveProperty(padding-right, 30px, 50px, 80px);
  }
}
</style>
