<template>
  <el-scrollbar
    class="shrimp-talk-tab shrimp-talk-tab-scroll"
    viewClass="shrimp-talk-tab-scroll-view"
    wrapClass="shrimp-talk-tab-scroll-wrap"
  >
    <el-tabs v-model="activeLogsTab">
      <el-tab-pane label="Sensor Logs" name="sensor-logs">
        <!-- <sensor-logs></sensor-logs> -->
        <pm-super-admin-card-header
          @changedDateQuery="handleDateRangeChange($event, 'feed_logs')"
          :dateQuery="true"
          :timeQuery="false"
          :downloadOption="true"
          v-if="!refreshDates"
          @downloadZIPQuery="handleDownloadZIPQuery($event, 'feed_logs')"
        ></pm-super-admin-card-header>
        <st-super-admin-battery-voltage-graph
          v-loading="loading"
        ></st-super-admin-battery-voltage-graph>
        <stSuperAdminWifiSignalRSSIGraph v-loading="loading">
        </stSuperAdminWifiSignalRSSIGraph>
      </el-tab-pane>
      <el-tab-pane label="ST Raw Data" name="st-raw-data">
        <!-- <sensor-logs></sensor-logs> -->
        <pm-super-admin-card-header
          @changedDateQuery="handleDateRangeChange($event, 'st-raw-data')"
          :dateQuery="true"
          :timeQuery="false"
          :StRawData="true"
          :downloadOption="true"
          v-if="!refreshDates"
          @downloadZIPQuery="handleDownloadZIPQuery($event, 'st-raw-data')"
        ></pm-super-admin-card-header>
        <st-super-admin-raw-data-graph
          v-loading="getStFeedLogsDataLoading"
        ></st-super-admin-raw-data-graph>
        <st-super-admin-raw-data-signature-graph
          v-loading="getStFeedLogsDataLoading"
        ></st-super-admin-raw-data-signature-graph>
      </el-tab-pane>
      <el-tab-pane label="ST PM Logs" name="st-pm-logs">
        <st-pm-logs
          v-loading="loading"
          :shrimpTalkId="shrimpTalkId"
          :customer-details="customerDetails"
        ></st-pm-logs>
      </el-tab-pane>
      <el-tab-pane label="Networks" name="networks">
        <shrimpTalkDeviceNetworks
          v-loading="loading"
          element-loading-background="white"
          class="shrimp-talk-device-networkd"
          :deviceDetails="deviceDetails"
          :customer-details="customerDetails"
        ></shrimpTalkDeviceNetworks>
      </el-tab-pane>
      <el-tab-pane label="Communication Stats" name="communication-stats">
        <communication-stats></communication-stats>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-footer></el-footer> -->
  </el-scrollbar>
</template>
<script>
import stPmLogs from "./stPmLogs.vue";
import shrimpTalkDeviceNetworks from "./shrimpTalkDeviceNetworks.vue";
import communicationStats from "./communicationStats.vue";
import { mapActions, mapGetters } from "vuex";
import pmSuperAdminCardHeader from "@/components/superadmin/pond-mother/feed-logs/pmSuperAdminCardHeader";
import stSuperAdminBatteryVoltageGraph from "@/components/superadmin/shrimp-talk/feed-logs/stSuperAdminBatteryVoltageGraph";

import stSuperAdminWifiSignalRSSIGraph from "@/components/superadmin/shrimp-talk/feed-logs/stSuperAdminWifiSignalRSSIGraph";
import stSuperAdminRawDataGraph from "@/components/superadmin/shrimp-talk/feed-logs/stSuperAdminRawDataGraph";
import stSuperAdminRawDataSignatureGraph from "@/components/superadmin/shrimp-talk/feed-logs/stSuperAdminRawDataSignatureGraph";
import exportFileDownload from "@/mixins/exportFileDownload";
export default {
  props: ["loading", "customerDetails", "deviceDetails", "shrimpTalkId"],
  components: {
    stPmLogs,
    shrimpTalkDeviceNetworks,
    communicationStats,
    pmSuperAdminCardHeader,
    stSuperAdminBatteryVoltageGraph,
    stSuperAdminWifiSignalRSSIGraph,
    stSuperAdminRawDataGraph,
    stSuperAdminRawDataSignatureGraph
  },
  mixins: [exportFileDownload],
  data() {
    return {
      activeLogsTab: "sensor-logs",
      refreshDates: false,
      STloading: false,
    };
  },
  computed: {
    ...mapGetters("superadmin", {
    getSTRawDataArray: "getSTRawDataArray",
    getStFeedLogsDataLoading: "getStFeedLogsDataLoading"
    }),
    computedComponentName: {
      get() {
        return this.$route.query.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    },
    downloadColumns() {
      return [
        {
          header: "Timestamp",
          dataKey: "timestamp",
          emptyValue: "--",
          name: "timestamp",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: "Sound Intensity",
          dataKey: "sound_intensity",
          emptyValue: "--",
          name: "sound_intensity",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: "Signature",
          dataKey: "signature",
          emptyValue: "--",
          name: "signature",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    },
  },
  methods: {
    ...mapActions("superadmin", {
      fetchSTFeedLogsGraphData: "fetchSTFeedLogsGraphData",
      fetchSTFeedLogsDownloadCSV: "fetchSTFeedLogsDownloadCSV",
      fetchSTRawDataGraphData: "fetchSTRawDataGraphData"
    }),
    async handleDownloadZIPQuery(changedDate, parameter) {
      if (parameter === "feed_logs") {
        await this.callStoreOnFeedLogsDownloadZIP(changedDate, parameter);
      } else {
        this.xceldownload();
      }
    },

    async handleDateRangeChange(changedDateValue, parameter) {
      if (parameter === "feed_logs") {
        await this.callStoreOnFeedLogsData(changedDateValue, parameter);
      } else {
        await this.callStoreOnSTRawData(changedDateValue);
      }
    },
    async callStoreOnSTRawData(changedDateValue) {
      const params = {};
      params.from_date = new Date(new Date(new Date(changedDateValue.from_date).setSeconds(0)).setMilliseconds(0)).toISOString();
      params.to_date = new Date(new Date(new Date(changedDateValue.to_date).setSeconds(0)).setMilliseconds(0)).toISOString();
      if (this.deviceDetails._id) {
        params.device_code = this.deviceDetails.code;
        params.get_all = true;
        params.limit = 50000;
        params.offset = 0;
        await this.fetchSTRawDataGraphData({
          params,
        });
        // this.STloading = false;
      }
    },
    async callStoreOnFeedLogsData(changedDateValue, parameter) {
      const params = {};
      params.from_date = changedDateValue.from_date;
      params.to_date = changedDateValue.to_date;
      if (this.deviceDetails._id) {
        this.loading = true;
        await this.fetchSTFeedLogsGraphData({
          shrimp_talk_code: this.deviceDetails.code,
          params,
          graphParameter: parameter
        });
        this.loading = false;
      }
    },

    refreshDatePicker() {
      this.refreshDates = true;
      setTimeout(() => {
        this.refreshDates = false;
      });
    },

    async callStoreOnFeedLogsDownloadZIP(changedDateValue, parameter) {
      try {
        const params = {};
        params.from_date = changedDateValue.from_date;
        params.to_date = changedDateValue.to_date;
        if (this.deviceDetails._id) {
          this.loading = true;
          const response = await this.fetchSTFeedLogsDownloadCSV({
            shrimp_talk_code: this.deviceDetails.code,
            params,
            responseType: "blob"
          });
          if (
            !response.data ||
            !response.data.type ||
            response.data.type !== "application/zip"
          ) {
            this.$message.warning("No Data Found in Selected Dates.");
          } else {
            let fileName = "";
            if (this.deviceDetails.code && params.from_date && params.to_date) {
              fileName =
                "ST_" +
                this.deviceDetails.code +
                "_" +
                params.from_date +
                "_" +
                params.to_date +
                ".zip";
            } else {
              fileName = "FeedLogs.zip";
            }
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.nofm__notifySuccess({
              msgLangkey: {
                title: "Comn_download_success_msg",
                count: 0
              }
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    xceldownload() {
      this.exp__Download(this.getSTRawDataArray, this.downloadColumns, "rawData_xcel", 'ST_Raw_Data_' + this.deviceDetails.code);
    },
  },

  watch: {
    "deviceDetails.code": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.refreshDatePicker();
        }
      },
      deep: true
    },
  }
};
</script>
<style lang="scss">
.shrimp-talk-tab {
  margin-top: 20px;
  padding-left: 12px;
  &.shrimp-talk-tab-scroll {
    position: relative;
    height: calc(100vh - 200px);
  }
  .shrimp-talk-tab-scroll-view {
    padding-right: 10px;
    // display: grid;
    // grid-template-columns: repeat(2, 34em);
  }
  .shrimp-talk-tab-scroll-wrap {
    height: calc(100vh - 180px);
    overflow-x: auto;
  }
  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 0px !important;
  }
}
</style>
