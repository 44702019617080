<template>
    <el-row>
      <el-row>
        <el-col :span="4">
          <item-list-side-bar
            :item-list="getSalesManagers"
            :init-item-id="salesmanager_id"
            item-type="salesmanagers"
            class="side-list-menu"
            :showProperty="['first_name', 'last_name']"
            :search="true"
            :totalItemsCount="totalItemsCount"
            search-property="user_name"
            :dataFetchMethod="AccountManagerData"
            @loadData="AccountManagerData"
            @cleared="AccountManagerData"
            @itemClicked="handlePmSelected"
          ></item-list-side-bar>
        </el-col>
        <el-col :span="20">
          <div>
          <el-row>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="Sales Executives" name="salesExixutives">
                <salesManagerIndividualExecutives v-bind:salesmanager_id="salesmanager_id" />
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </div>
        </el-col>
      </el-row>
    </el-row>
  </template>

  <script>
  import { mapActions, mapGetters } from "vuex";
  import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
  import salesManagerIndividualExecutives from "@/views/superadmin/eruvakaConnect/salesManagerIndividualExecutives";
  export default {
    props: ["salesmanager_id"],
    components: {
      itemListSideBar,
      salesManagerIndividualExecutives
    },

    data: function () {
      return {
        activeName: "salesExixutives",
        dialogVisible: false,
        loading: false,
        state2: "",
        tableData: [],
        totalItemsCount: 0,
      };
    },
    computed: {
      ...mapGetters("superadmin/salesManager", {
        getSalesManagers: "getSalesManagers"
      }),
    },

    methods: {
      ...mapActions("superadmin/salesManager", {
        fetchAllSalesManagers: "fetchAllSalesManagers",
      }),

      handleClick(tab, event) {
        console.log(tab, event);
      },

      async AccountManagerData({ query, infinite_scroll = false }) {
        try {
          const response = await this.fetchAllSalesManagers({
            infinite_scroll: infinite_scroll,
            params: query
          });
          this.totalItemsCount = response.total;
        } catch (err) {
          this.ehm__errorMessages(err, true);
        }
      },
      async handlePmSelected(salesmanager_id) {
        this.$router.replace(salesmanager_id);
      },

      handleSelect(item) {
        console.log(item);
      },

      async initComponent() {
        try {
          this.loading = true;
          await this.fetchAllSalesManagers({
            infinite_scroll: false,
            params: {
              page: 1,
              limit: 19,
              order_by: "first_name",
              order_type: "asc"
            }
          });
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
    },
    async mounted() {
      await this.initComponent();
    }
  };
  </script>

  <style lang="scss"></style>
