var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"customer-details-table",attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('div',{staticClass:"page_loading"}):_vm._e(),_vm._v(" "),_c('layout-account-manager-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"search-property":"device_code","showDownloadDropdown":true,"toolbarLayout":"search","queryParams":_vm.queryParams,"placeHolderForSearch":"Device ID"},on:{"add-item-click":_vm.handleAddDealerClick,"loadData":_vm.handleLoadData,"download-excel":_vm.handleDownloadExcel},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"main_header"},[_c('img',{attrs:{"src":require("@/assets/dealer/devices.svg"),"atl":"down arrow"}}),_vm._v(" "),_c('p',{staticStyle:{"margin-left":"10px"}},[_vm._v("\n          "+_vm._s(_vm.$t("Comn_pond_guard"))+"\n        ")])])]},proxy:true}])},[_vm._v(" "),_c('template',{slot:"table"},[_c('defaultTable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"add_PondMother",attrs:{"tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"search-property":"device_code","addPadingToSearch":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddDealerClick},scopedSlots:_vm._u([{key:"email_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"first_name_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"actions_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"status_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"code_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"added_date_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.getStatus(row.data, "POND_GUARD"))+"\n        ")]}},{key:"first_assigned_date_header",fn:function(ref){
var header = ref.header;
return [_c('el-tooltip',{attrs:{"content":_vm.$t(header.sub_label),"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(_vm.$t(header.label)))])])]}},{key:"last_assigned_date_header",fn:function(ref){
var header = ref.header;
return [_c('el-tooltip',{attrs:{"content":_vm.$t(header.sub_label),"placement":"top","effect":"light"}},[_c('p',[_vm._v(_vm._s(_vm.$t(header.label)))])])]}},{key:"first_user_assigned_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.first_user_assigned_date)))+"\n        ")]}},{key:"last_user_assigned_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.last_user_assigned_date)))+"\n        ")]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.code)+"\n        ")]}},{key:"first_name",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_details
              ? row.data.user_details.first_name
              : _vm.getDealerUsername.first_name)+"\n\n          "+_vm._s(row.data.user_details
              ? row.data.user_details.last_name
              : _vm.getDealerUsername.last_name)+"\n          ")]}},{key:"email",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_details
              ? row.data.user_details.email
              : _vm.getDealerUsername.email)+"\n        ")]}},{key:"added_date",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.added_date)))+"\n        ")]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }