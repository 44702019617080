/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartUtils.js
Description: Contians stateless helper functions used in charts
*/
export const padDateRanges = function(arrDateRanges, intervalGap = 3600000) {
  const startOfDay1 = function(value) {
    return +value - (+value % 86400000);
  };
  if (arrDateRanges.length === 0) return [];
  if (arrDateRanges.length === 24) return arrDateRanges;
  let arrayDates1 = arrDateRanges.map(x => +x);
  const start = +startOfDay1(arrayDates1[0]);
  const endOfDay = start + 86400000;
  const length1 = arrayDates1.length;
  const lastItem1 = arrayDates1[length1 - 1];
  if (!endOfDay || !lastItem1) return [];
  if (arrayDates1[0] - start > intervalGap) {
    arrayDates1 = [start, ...arrayDates1];
  }
  if (endOfDay - lastItem1 >= intervalGap) {
    arrayDates1 = [...arrayDates1, endOfDay - 60000];
  }
  const newArray = [];
  for (let i = 0; i < arrayDates1.length - 1; i++) {
    let k = 0;
    while (
      arrayDates1[i + 1] - (arrayDates1[i] + k * intervalGap) >=
      intervalGap
    ) {
      newArray.push(arrayDates1[i] + k * intervalGap);
      k++;
    }
    const value = arrayDates1[i + 1] - (arrayDates1[i] + k * intervalGap);
    if (value > 0 && value < intervalGap) {
      newArray.push(arrayDates1[i + 1]);
    }
  }
  return newArray;
};
export default {
  padDateRanges
};
