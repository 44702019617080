<template>
  <el-row class="users-management">
    <loader v-if="loading"></loader>
    <template v-else>
      <el-row
        element-loading-background="white"
        class="user-access-container"
      >
        <el-row type="flex" class="user-access-container__header">
          <el-col :span="10" class="panel-toolbar">
            <admin-user-left-panel-toolbar @user_search="user_search"></admin-user-left-panel-toolbar>
          </el-col>
          <el-col :span="14" class="panel-toolbar">
            <admin-user-right-panel-toolbar></admin-user-right-panel-toolbar>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" style="border-right: 1px solid #e4e4e4">
            <adminUserListTable :userSearch="userSearch"></adminUserListTable>
          </el-col>
          <el-col :span="14">
            <adminUserRightPanel :loading="loading"></adminUserRightPanel>
          </el-col>
        </el-row>
      </el-row>
    </template>
  </el-row>
</template>

<script>
import adminUserRightPanel from "./adminUsersRightPanel.vue";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import { mapActions } from "vuex";
import adminUserListTable from "./adminUserListTable";
import adminUserRightPanelToolbar from "./adminUsersRightPanelToolbar.vue";
import adminUserLeftPanelToolbar from "./adminUsersLeftPanelToolbar.vue";
import { ADMIN_USER_UI_STATES } from "@/constants/uiStates";
import Loader from "@/components/base/Loader";
// import { customerUserTypeToLangKey } from "@/middleware/pageAccessManager";
export default {
  mixins: [errorHandlerMixin],
  provide() {
    return {
      parentConfig: this.$data
    };
  },
  props: {
    // tabData: {
    //   default: {}
    // }
  },
  components: {
    adminUserRightPanel,
    adminUserListTable,
    adminUserRightPanelToolbar,
    adminUserLeftPanelToolbar,
    Loader
  },
  data: function () {
    return {
    //   USER_TYPE: customerUserTypeToLangKey,
    //   uamTabData: this.tabData,
      loading: false,
      userSearch: ""
    };
  },
//   computed: {
//     ...mapGetters("user", {
//       isReadOnly: "isReadOnly"
//     }),
//     ...mapGetters("userAccessManagement", {
//       getFilteredUAMList: "getFilteredUAMList"
//     }),
//     getCurrUserLocation() {
//       return this.$store.getters["user/getCurrUserLocation"];
//     },
//     isLocationAvailable() {
//       return !(
//         Object.keys(this.getCurrUserLocation).length === 0 &&
//         this.getCurrUserLocation.constructor === Object
//       );
//     },
//     isReadOnly() {
//       return this.$store.getters["user/isReadOnly"];
//     }
//   },
  async created() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      clearStoreState: "clearStoreState",
      restoreToIntialState: "restoreToIntialState"
    }),
    async initComponent() {
      try {
        this.loading = true;
        await this.restoreToIntialState({
          sidePanelUIState: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    user_search(event) {
      this.userSearch = event;
    }
  },
//   beforeDestroy() {
//     this.clearStoreState();
//   }
};
</script>

<style lang="scss">
.users-management {
  .user-access-container {
    flex: 1 1 auto;
    .user-access-container__header {
      border-bottom: 1px solid #dddddd;
      .panel-toolbar {
        padding: 12px 14px;
        & + .panel-toolbar {
          border-left: 1px solid #dddddd;
        }
      }
    }

    .left-container {
      border-left: 1px solid #dddddd;
      border-bottom: 1px solid rgb(221, 221, 221);
    }
    .name-container {
      display: flex;
      align-items: center;
    }
    .name-container * {
      margin-right: 15px;
      color: #0a2463 !important;
    }
    .add-container {
      display: flex;
      align-items: center;
      // @include responsiveProperty(padding, 8px, 8px, 1.5px);
    }
    .add-container * {
      margin-right: 15px;
      color: #0a2463 !important;
    }
    .el-table {
      .el-table__header th {
        background-color: #f4f2ff !important;
        color: #0a2463 !important;
      }
      .table-main-text {
        color: #0a2463;
      }
      .table-header-text {
        color: #25213b;
        @include responsiveProperty(font-size, 13px, 16px, 19px);
      }
    }

    .selected-row {
      background: aliceblue;
    }
  }
}
</style>
