<template>
  <div class="dealer-warranty">
    <el-card class="layout-dealer-card">
      <warranty-data-view
        :columnsObject="columnsObject"
        :tableData="tableData"
        :total="total"
        :loading="loading"
        :height="'calc(100vh - 140px)'"
        @handleLoadData="handleLoadData"
      />
    </el-card>
  </div>
</template>

<script>
  import errorHandlerMixin from "@/mixins/errorHandlerMixin";
  import warrantyDataView from "../../components/base/warrantyDataView.vue";
  // import layout from "@/components/superadmin/shared/layout";
  import { mapActions, mapGetters } from "vuex";

  export default {
    mixins: [errorHandlerMixin],
    data: function () {
      return {
        tableData: [],
        total: 0,
        saveLoading: false,
        loading: false
      }
    },
    components: {
      warrantyDataView
    },
    computed: {
      ...mapGetters("dealer", {
        getAllclaimsData: "getAllclaimsData"
      }),
      elTableProps() {
        return {
          height: "calc(100vh - 122px)",
        };
      },
      columnsObject: function () {
        return [
          {
            label: "Claim Date",
            prop: "claim_date",
            required: true,
            minWidth: 100,
          },
          {
            label: "Customer",
            prop: "user_id.full_name",
            required: true,
            minWidth: 150,
          },
          {
            label: "Product Type",
            prop: "device_type",
            required: true,
            minWidth: 120,
          },
          {
            label: "Device ID",
            prop: "device_id.code",
            required: true,
            minWidth: 100,
          },
          {
            label: "Motor ID",
            prop: "motor_id.motor_id",
            required: true,
            minWidth: 100,
          },
          {
            label: "Issue Description",
            prop: 'issue',
            required: true,
            minWidth: 200,
          },
          {
            label: "Failure Part No.",
            prop: 'part_id.part_id',
            required: true,
            minWidth: 100,
          },
          {
            label: "Part Name",
            prop: 'part_id.part_name',
            required: true,
            minWidth: 100,
          },
          {
            label: "Product Failure Date",
            prop: 'failure_date',
            required: true,
            minWidth: 130,
          },
          {
            label: "L1 Reviewer",
            prop: 'created_by.full_name',
            required: true,
            minWidth: 150,
          },
          {
            label: "L1 Review Date",
            prop: 'created_at',
            required: true,
            minWidth: 100,
          },
          {
            label: "L2 Reviewer",
            prop: 'cs_reviewed_by.full_name',
            required: true,
            minWidth: 150,
          },
          {
            label: "L2 Review Date",
            prop: 'cs_reviewed_date',
            required: true,
            minWidth: 100,
          },
          {
            label: "Status",
            prop: 'claim_status',
            required: true,
            minWidth: 100,
          },
          {
            label: "Actions",
            required: true,
            minWidth: 100,
          }
        ];
      }
    },
    methods: {
      ...mapActions("dealer", {
        getClaimData: "getClaimData",
      }),
      async handleLoadData(query) {
        try {
          this.loading = true;
          const response = await this.getClaimData({ page: query.page, limit: query.limit, order_by: 'created_at' });
          this.total = response.total;
          this.tableData = await this.getAllclaimsData;
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },
      // handleSaveClaim(claimData) {
      //     console.log("claimData", claimData);
      // },
    }
  }
</script>
<style lang="scss">
.dealer-warranty {
  .settings-table {
    padding-top: 10px;
  }
  .layout {
    width: 100%;
    position: unset;
  }
  .el-card__body {
    padding: 0px;
  }
}
</style>
<style lang="scss" scoped>
.layout-dealer-card {
  border-radius: 7px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
