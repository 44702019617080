var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"add-devices-to-user",attrs:{"width":_vm.dialogWidth(),"visible":_vm.dialogVisible,"close-on-click-modal":false,"append-to-body":true,"title":((_vm.$t(_vm.action === 'ADD_DEVICES' ? 'Comn_add' : 'Comn_delete')) + " Devices"),"custom-class":"add-devices-to-user-dialog","element-loading-background":"white"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":_vm.handleCloseDialog,"open":_vm.initComponent}},[(_vm.action === 'ADD_DEVICES')?_c('ValidationObserver',{ref:"dialogListForm"},[_c('tab-layout',{attrs:{"componentList":_vm.componentList,"showComponent":_vm.tabName,"tab-click-handler":_vm.handleTabClick},on:{"selectedComponent":_vm.handleSelectedComponent}}),_vm._v(" "),_c('el-form',{attrs:{"size":"small"}},[_c('el-form-item',{attrs:{"label-width":"30px","label":"Customer Name"}},[_c('el-col',[_vm._v("\n          "+_vm._s(this.customer_details.first_name +
              " " +
              this.customer_details.last_name)+"\n        ")])],1),_vm._v(" "),(_vm.tabName === 'pondMother')?[_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fileLoading),expression:"fileLoading"}],key:_vm.$i18n.locale,attrs:{"tableData":_vm.tableData,"columns":_vm.columnsObject,"elTableProps":_vm.elTableProps,"actionColumn":false,"pagination-props":_vm.$constants.table['pagination-props'],"type":"white-header-table","uniqueId":"warranty_table"},on:{"pageSizeChange":_vm.handlePageSizeChange,"currentPageChange":_vm.handleCurrentPageChange},scopedSlots:_vm._u([{key:"device_id",fn:function(ref){
              var row = ref.row;
return [_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":false,"reserve-keyword":true,"value-key":"code","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchPondMotherByCode,"loading":_vm.sLoading === 'POND_MOTHER',"placeholder":"Enter PondMother Code","no-data-text":_vm.$t('Comn_no_pondmothers')},model:{value:(row.data.pm_id),callback:function ($$v) {_vm.$set(row.data, "pm_id", $$v)},expression:"row.data.pm_id"}},_vm._l((_vm.pond_mothers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item._id}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)]}},{key:"motor_id",fn:function(ref){
              var row = ref.row;
return [_c('er-select',{staticClass:"select-devices",attrs:{"value-key":"code","reserve-keyword":true,"filterable":"","placeholder":"Enter Motor ID"},model:{value:(row.data.motor_id),callback:function ($$v) {_vm.$set(row.data, "motor_id", $$v)},expression:"row.data.motor_id"}},_vm._l((_vm.getAllMotorsData),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.motor_id,"value":item._id}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.motor_id))])])}),1)]}},{key:"action",fn:function(ref){
              var row = ref.row;
return [((row.$index === _vm.tableData.length - 1) || (_vm.currentPage > 1 && (_vm.pageSizeData + row.$index) === _vm.tableData.length - 1))?[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-plus"},on:{"click":function($event){return _vm.handleAddRow()}}})]:[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDeleteRow(row.$index)}}})]]}}],null,false,861194870)})]:[_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"PondGuards"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_pond_guard'),"error":errors[0]}},[_c('el-col',[_c('er-select',{staticClass:"select-devices",attrs:{"reserve-keyword":true,"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"loading":_vm.sLoading === 'POND_GUARD',"remote-method":_vm.fetchPondGuardByCode,"placeholder":"Enter PondGuard Code","no-data-text":_vm.$t('Comn_no_pondguards')},model:{value:(_vm.selectedPGs),callback:function ($$v) {_vm.selectedPGs=$$v},expression:"selectedPGs"}},_vm._l((_vm.pond_guards),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                    "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}],null,false,85593216)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"Shrimptalk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_shrimptalk'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchShrimpTalkByCode,"loading":_vm.sLoading === 'SHRIMP_TALK',"placeholder":"Enter ShrimpTalk Code","no-data-text":_vm.$t('Comn_no_shrimptalks')},model:{value:(_vm.selectedSTs),callback:function ($$v) {_vm.selectedSTs=$$v},expression:"selectedSTs"}},_vm._l((_vm.shrimp_talks),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                    "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}],null,false,1187204905)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Gateway"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_gateway'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchGatewayByCode,"loading":_vm.sLoading === 'GATEWAY',"placeholder":"Enter Gateway Code","no-data-text":"No Gateways"},model:{value:(_vm.selectedGWs),callback:function ($$v) {_vm.selectedGWs=$$v},expression:"selectedGWs"}},_vm._l((_vm.gateways),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                    "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}],null,false,372158688)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Feedblower"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('devices.feed-blower'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchFeedBlowerByCode,"loading":_vm.sLoading === 'FEEDBLOWER',"placeholder":"Enter Feed Blower Code","no-data-text":"No Feed Blowers"},model:{value:(_vm.selectedFBs),callback:function ($$v) {_vm.selectedFBs=$$v},expression:"selectedFBs"}},_vm._l((_vm.feed_blowers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n                ")])}),1)],1)],1)]}}],null,false,1051030857)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"ShrimpSnap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":"ShrimpSnap","error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchShrimpSnapByCode,"loading":_vm.sLoading === 'SHRIMP_SNAP',"placeholder":"Enter ShrimpSnap Code","no-data-text":"No ShrimpSnap"},model:{value:(_vm.selectedSSs),callback:function ($$v) {_vm.selectedSSs=$$v},expression:"selectedSSs"}},_vm._l((_vm.shrimp_snaps),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n                ")])}),1)],1)],1)]}}],null,false,1121450232)})]],2)],1):_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"small"}},[_c('el-form-item',{attrs:{"label-width":"30px","label":"Customer Name"}},[_c('el-col',[_vm._v("\n          "+_vm._s(this.customer_details.first_name +
              " " +
              this.customer_details.last_name)+"\n        ")])],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"PondGuards"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_pond_guard'),"error":errors[0]}},[_c('el-col',[_c('er-select',{staticClass:"select-devices",attrs:{"reserve-keyword":true,"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"loading":_vm.sLoading === 'POND_GUARD',"remote-method":_vm.fetchPondGuardByCode,"placeholder":"Enter PondGuard Code","no-data-text":_vm.$t('Comn_no_pondguards')},model:{value:(_vm.selectedPGs),callback:function ($$v) {_vm.selectedPGs=$$v},expression:"selectedPGs"}},_vm._l((_vm.pond_guards),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"PondMothers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_pond_mother'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"reserve-keyword":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchPondMotherByCode,"loading":_vm.sLoading === 'POND_MOTHER',"placeholder":"Enter PondMother Code","no-data-text":_vm.$t('Comn_no_pondmothers')},model:{value:(_vm.selectedPMs),callback:function ($$v) {_vm.selectedPMs=$$v},expression:"selectedPMs"}},_vm._l((_vm.pond_mothers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-item-select",attrs:{"name":"Shrimptalk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_shrimptalk'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchShrimpTalkByCode,"loading":_vm.sLoading === 'SHRIMP_TALK',"placeholder":"Enter ShrimpTalk Code","no-data-text":_vm.$t('Comn_no_shrimptalks')},model:{value:(_vm.selectedSTs),callback:function ($$v) {_vm.selectedSTs=$$v},expression:"selectedSTs"}},_vm._l((_vm.shrimp_talks),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Gateway"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('Comn_gateway'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","reserve-keyword":true,"collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"remote-method":_vm.fetchGatewayByCode,"loading":_vm.sLoading === 'GATEWAY',"placeholder":"Enter Gateway Code","no-data-text":"No Gateways"},model:{value:(_vm.selectedGWs),callback:function ($$v) {_vm.selectedGWs=$$v},expression:"selectedGWs"}},_vm._l((_vm.gateways),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v("\n                  "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")")])])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Feedblower"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":_vm.$t('devices.feed-blower'),"error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchFeedBlowerByCode,"loading":_vm.sLoading === 'FEEDBLOWER',"placeholder":"Enter Feed Blower Code","no-data-text":"No Feed Blowers"},model:{value:(_vm.selectedFBs),callback:function ($$v) {_vm.selectedFBs=$$v},expression:"selectedFBs"}},_vm._l((_vm.feed_blowers),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n              ")])}),1)],1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"ShrimpSnap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label-width":"30px","label":"ShrimpSnap","error":errors[0]}},[_c('el-col',{attrs:{"span":24}},[_c('er-select',{staticClass:"select-devices",attrs:{"showSelectAll":true,"value-key":"code","collapse-tags":"","multiple":"","filterable":"","remote":_vm.isAddAction,"reserve-keyword":true,"remote-method":_vm.fetchShrimpSnapByCode,"loading":_vm.sLoading === 'SHRIMP_SNAP',"placeholder":"Enter ShrimpSnap Code","no-data-text":"No ShrimpSnap"},model:{value:(_vm.selectedSSs),callback:function ($$v) {_vm.selectedSSs=$$v},expression:"selectedSSs"}},_vm._l((_vm.shrimp_snaps),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.code,"value":item}},[_vm._v("\n                "+_vm._s(item.code)+" ("+_vm._s(item.v1_code)+")\n              ")])}),1)],1)],1)]}}])})],1)],1),_vm._v(" "),_c('layout-toolbar',{attrs:{"slot":"footer","justify":"center"},slot:"footer"},[(_vm.tabName === 'pondMother' && _vm.action === 'ADD_DEVICES')?_c('label',{staticClass:"file-upload-button",attrs:{"for":"fileInput"}},[_c('span',[_c('i',{staticClass:"fas fa-cloud-upload"}),_vm._v("Upload Excel")]),_vm._v(" "),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"fileInput","accept":".xlsx, .csv"},on:{"change":function($event){return _vm.handleFEBImageAdded($event)}}})]):_vm._e(),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading},on:{"click":_vm.submitDeviceDetails}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true},on:{"click":_vm.handleCloseDialog}}),_vm._v(" "),(_vm.tabName === 'pondMother' && _vm.action === 'ADD_DEVICES')?_c('a',{staticClass:"download-excel-btn",attrs:{"href":"/PM-MotorId-Template.xlsx","download":""}},[_vm._v("Download Excel Template")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }