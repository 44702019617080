var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"careers-table-sa",attrs:{"no-aside":true}},[_c('layout-toolbar',{staticClass:"post-header-right",attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":24}},[_c('p',{staticClass:"layout-title-careers"},[_vm._v("\n            Applicants\n          ")])])],1),_vm._v(" "),_c('er-data-tables',{key:_vm.$i18n.locale,attrs:{"tableData":_vm.tableData,"columns":_vm.columnsObject1,"elTableProps":_vm.elTableProps1,"actionColumn":false,"type":"white-header-table","uniqueId":"carrers_table"},scopedSlots:_vm._u([{key:"resume_link",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"resume-link"},[_c('a',{attrs:{"href":row.data.resume_link,"target":"_blank"}},[_vm._v("Resume Link")])])]}},{key:"appl_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.created_at))+"\n      ")]}}])}),_vm._v(" "),_c('er-dialog',{attrs:{"title":_vm.clientActionDialogTitle,"visible":_vm.careerActionDialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"40%","custom-class":"careerAction_dialog"},on:{"update:visible":function($event){_vm.careerActionDialogVisible=$event}}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"small","model":_vm.formCareers,"inline":true}},[_c('ValidationProvider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-name",attrs:{"label":"Role","error":errors[0]}},[_c('el-input',{attrs:{"placeholder":"Enter Role"},model:{value:(_vm.formCareers.role),callback:function ($$v) {_vm.$set(_vm.formCareers, "role", $$v)},expression:"formCareers.role"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"label":"Location","error":errors[0]}},[_c('el-select',{attrs:{"placeholder":"Select Location"},model:{value:(_vm.formCareers.location),callback:function ($$v) {_vm.$set(_vm.formCareers, "location", $$v)},expression:"formCareers.location"}},_vm._l((_vm.locationOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Exp Required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"error":errors[0],"label":"Exp Required"}},[_c('el-input',{attrs:{"type":"number","placeholder":"Enter Exp Required","min":0},on:{"change":function($event){return _vm.handleChangeInExp($event)}},model:{value:(_vm.formCareers.experience),callback:function ($$v) {_vm.$set(_vm.formCareers, "experience", $$v)},expression:"formCareers.experience"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Job Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"error":errors[0],"label":"Job Description"}},[_c('el-input',{staticClass:"careersPageTextarea",attrs:{"type":"textarea","rows":4,"size":"mini","autocomplete":"off","placeholder":"Enter Job Description"},model:{value:(_vm.formCareers.description),callback:function ($$v) {_vm.$set(_vm.formCareers, "description", $$v)},expression:"formCareers.description"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{staticClass:"input-feed-type",attrs:{"error":errors[0],"label":"Department"}},[_c('el-select',{attrs:{"placeholder":"Select Department"},model:{value:(_vm.formCareers.department),callback:function ($$v) {_vm.$set(_vm.formCareers, "department", $$v)},expression:"formCareers.department"}},_vm._l((_vm.departmentOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])}),_vm._v(" "),_c('el-form-item',{staticClass:"input-feed-type",attrs:{"label":"Status"}},[_c('el-select',{attrs:{"placeholder":"Select Status"},model:{value:(_vm.formCareers.status),callback:function ($$v) {_vm.$set(_vm.formCareers, "status", $$v)},expression:"formCareers.status"}},_vm._l((_vm.statusListOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('el-row',{attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.handleSaveCareer}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"loading":_vm.saveLoading},on:{"click":_vm.closeAddClientDialog}})],1)],1)],2),_vm._v(" "),_c('layout-toolbar',{staticClass:"post-header-right",attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":24}},[_c('p',{staticClass:"layout-title-careers"},[_vm._v("\n          Careers\n        ")])]),_vm._v(" "),_c('er-button',{attrs:{"btnType":"add","showIcon":true,"showLabel":true},on:{"click":_vm.handleAddClientClick}},[_vm._v("Add New")])],1),_vm._v(" "),_c('er-data-tables',{key:_vm.$i18n.locale,attrs:{"tableData":_vm.jobsTableData,"columns":_vm.columnsObject,"elTableProps":_vm.elTableProps2,"actionColumn":false,"type":"white-header-table","uniqueId":"carrers_table"},scopedSlots:_vm._u([{key:"posted_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.formatDate(row.data.created_at))+"\n      ")]}},{key:"closed_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.status === 'CLOSED' ? _vm.formatDate(row.data.updated_at) : '--')+"\n      ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.Status[row.data.status])+"\n      ")]}},{key:"job_description",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{staticClass:"job-description-styles",staticStyle:{"font-size":"12px"},attrs:{"placement":"top-start","width":"300","trigger":"hover","content":row.data.description}},[_c('span',{staticClass:"hiding-extra-reply-text",attrs:{"slot":"reference","type":"text"},slot:"reference"},[_vm._v(_vm._s(row.data.description)+"\n            ")])])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleChangeStatus($event, 'EDIT', row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" edit ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleChangeStatus($event, 'RESET', row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" delete ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }