<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkTableSA.vue
Description:This file contains  ItemListSideBar(list of shrimptalks), Basic shrimpTalk details , ShrimpTalkDeviceNetworks
-->
<template>
  <el-container class="shrimp-talk-logs">
    <el-aside>
      <portal-target name="outsideAsideScroll"></portal-target>
      <item-list-side-bar
        :item-list="shrimptaTalkList"
        :init-item-id="deviceId"
        item-type="shrimpTalks"
        class="side-list-menu"
        showProperty="code"
        search-str-query-param="device_code"
        :search="true"
        :dataFetchMethod="loadShrimpTalkData"
        @loadData="loadShrimpTalkData"
        @cleared="loadShrimpTalkData"
        @itemClicked="handleShrimpTalkSelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
      <!-- :dataFetchMethod="loadShrimpTalkData" -->
    </el-aside>
    <el-main>
      <basic-customer-details
        :device-details="deviceDetails"
        :user-details="customerDetails"
        :showCustomerDetails="true"
        :allowAddCustomer="false"
      ></basic-customer-details>
      <shrimp-talk-tab
        v-loading="!isShrimpTalkAvailable"
        v-show="isShrimpTalkAvailable"
        :shrimpTalkId="shrimp_talk_id"
        :loading="loading"
        :deviceDetails="deviceDetails"
        :customerDetails="customerDetails"
      ></shrimp-talk-tab>
      <!-- <shrimpTalkDeviceNetworks
        v-loading="loading"
        element-loading-background="white"
        class="shrimp-talk-device-networkd"
        :deviceDetails="deviceDetails"
        :customer-details="customerDetails"
      ></shrimpTalkDeviceNetworks> -->
    </el-main>
  </el-container>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";

import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
// import shrimpTalkDeviceNetworks from "./shrimpTalkDeviceNetworks";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import urlParamsProvideMixin from "@/mixins/urlParamsProvideMixin";

import shrimpTalkTab from "./shrimpTalkTab.vue";
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import eventBus from "./eventBus.vue";
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin, urlParamsProvideMixin],
  props: ["shrimp_talk_id"],
  emits: ["handleShrimpTalkSelected"],
  components: {
    itemListSideBar,
    basicCustomerDetails,
    shrimpTalkTab,
    // shrimpTalkDeviceNetworks
  },
  data: function () {
    return {
      data: { gateway_code: "" },
      loading: false,
      shrimp_talk_data: {},
      totalItemsCount: 100,
      emptyStr: "--",
      isStIdAvailable: false,
      bus: new Vue(),
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getShrimpTalks: "getShrimpTalks",
    }),
    isShrimpTalkAvailable() {
      if (this.getShrimpTalks && this.getShrimpTalks.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    deviceDetails() {
      // return this.getGatewaysObj[this.gateway_id];
      return this.shrimp_talk_data;
    },
    deviceId() {
      return (this.deviceDetails || { _id: "No Data" })._id;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
        };
      }
    },
    shrimptaTalkList() {
      return this.getShrimpTalks;
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAPondGuardLatestSensorData: "fetchAPondGuardLatestSensorData",
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchADeviceData: "fetchADeviceData",
      fetchAllUsers: "fetchAllUsers",
      setShrimpTalkId: "setShrimpTalkId",
      fetchSTFeedLogsGraphData: "fetchSTFeedLogsGraphData",
      fetchSTRawDataGraphData: "fetchSTRawDataGraphData"
    }),
    async initComponent() {
      try {
        this.loading = true;
        const shrimpTalkResponse = await this.fetchAllDeviceDetails({
          device_type: "shrimp_talk",
          infinite_scroll: false,
          params: { page: 1, limit: 19 },
        });
        this.totalItemsCount = shrimpTalkResponse.total;
        const shrimp_talk_id = (this.shrimptaTalkList[0] || { _id: "No PM" })
          ._id;
        // const userTimeZone = (this.shrimptaTalkList[0] || { _id: "No PM" })
        //   .user_data.timezone;
        if (shrimp_talk_id) this.isStIdAvailable = true;
        this.setShrimpTalkId(shrimp_talk_id);
        // this.setUserTimeZoneString(userTimeZone);
        console.log("this.shrimptaTalkList[0]", this.shrimptaTalkList);
        await this.callStoreOnShrimpTalkData(shrimp_talk_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadShrimpTalkData({ query, infinite_scroll = false }) {
      if (
        query.device_code &&
        query.device_code.toUpperCase().startsWith("ST")
      ) {
        query.device_code = query.device_code.substr(2).trim();
      }
      try {
        const response = await this.fetchAllDeviceDetails({
          device_type: "shrimp_talk",
          infinite_scroll: infinite_scroll,
          params: query,
        });

        this.totalItemsCount = response.total;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async callStoreOnShrimpTalkData(shrimp_talk_id) {
      try {
        this.shrimp_talk_data = await this.fetchADeviceData({
          device_type: "shrimp_talk",
          device_id: shrimp_talk_id,
        });

        const startOfToday = this.adm__dateUtilsLib.startOfDay(
          this.adm__getTodayInUserTZ(this.deviceTimeZoneStr)
        );

        const params = {
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            "yyyy-MM-dd"
          ),
          to_date: this.adm__dateUtilsLib.formatDate(
            startOfToday,
            "yyyy-MM-dd"
          ),
        };

        await this.fetchSTFeedLogsGraphData({
          shrimp_talk_code: this.shrimp_talk_data.code,
          params,
          graphParameter: "feed-logs",
        });

        this.uppm__setUrlParams(
        "from_date",
        this.adm__dateUtilsLib.formatDate(
          this.adm__dateUtilsLib.subtract(new Date(), { minutes: 30 }),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      this.uppm__setUrlParams(
        "to_date",
        this.adm__dateUtilsLib.formatDate(
          new Date(),
          this.adm__dateUtilsLib.isoFormatString
        )
      );
      params.from_date = new Date(new Date(new Date(this.uppm__urlParams.from_date).setSeconds(0)).setMilliseconds(0)).toISOString();
      params.to_date = new Date(new Date(new Date(this.uppm__urlParams.to_date).setSeconds(0)).setMilliseconds(0)).toISOString();
      params.device_code = this.shrimp_talk_data.code;
      params.get_all = true;
      params.limit = 50000;
      params.offset = 0;
      this.fetchSTRawDataGraphData({
        params
      });
        // this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleShrimpTalkSelected(shrimp_talk_id) {
      try {
        this.loading = true;
        // const userTimeZone = this.shrimptaTalkList.filter((item, i) => item)
        this.setShrimpTalkId(shrimp_talk_id);
        await this.callStoreOnShrimpTalkData(shrimp_talk_id);
        eventBus.$emit("handleShrimpTalkSelected");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.shrimp-talk-logs {
  .item-list-menu {
    height: 82vh;
  }
  .side-list-menu {
    width: 200px;
  }
}
</style>
