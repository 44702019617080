var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"client-table-sa",attrs:{"no-aside":true}},[_c('div',[_c('er-dialog',{attrs:{"title":_vm.clientActionDialogTitle,"visible":_vm.clientActionDialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"30%","custom-class":"clientAction_dialog"},on:{"update:visible":function($event){_vm.clientActionDialogVisible=$event},"open":_vm.handleAddClientDialogOpen}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"small","model":_vm.selectedClient}},[_c('ValidationProvider',{attrs:{"name":"Client Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":"Client Name","error":errors[0]}},[_c('el-input',{attrs:{"disabled":['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(_vm.action)},model:{value:(_vm.selectedClient.title),callback:function ($$v) {_vm.$set(_vm.selectedClient, "title", $$v)},expression:"selectedClient.title"}})],1)]}}])}),_vm._v(" "),(
              ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(_vm.action)
            )?_c('ValidationProvider',{attrs:{"name":"Users","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('el-form-item',{attrs:{"label":"Users","error":errors[0]}},[_c('er-select',{attrs:{"loading":_vm.userListLoading,"multiple":"","filterable":"","collapse-tags":"","remote":"","remote-method":_vm.fetchUsersToAssign,"value-key":"_id"},model:{value:(_vm.selectedClient.users),callback:function ($$v) {_vm.$set(_vm.selectedClient, "users", $$v)},expression:"selectedClient.users"}},_vm._l((_vm.usersList),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.first_name,"value":item}},[_vm._v("\n                  "+_vm._s(item.last_name)+" "+_vm._s(item.first_name)+" ("+_vm._s(item.email)+")\n                ")])}),1)],1)]}}],null,false,2443832140)}):_vm._e()],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('el-row',{attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.handleSaveClient}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"loading":_vm.saveLoading},on:{"click":_vm.closeAddClientDialog}})],1)],1)],2),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"tableProps":_vm.elTableProps,"total":_vm.total,"button":true,"columnselector_require":false,"placeHolderForSearch":"Search_username","search-property":"user_name","addPadingToSearch":true},on:{"cell-click":_vm.handleCellClick,"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClientClick},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEditClientClick(row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" edit ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleAddUsersToClient(row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" person_add ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","disabled":row.data.users.length === 0},on:{"click":function($event){return _vm.handleDeleteUsersToClient(row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" person_remove ")])])],1)]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add Client")])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }