<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AddAccountCustomers.vue
Description:This file contains the form  where the details of the customers  filled and associated with account manager  by superadmin
-->
<template>
  <div class="add-AccountCustomers">
    <er-dialog
      width="30%"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      @open="handleOpenDialog"
      :title="$t('add-user')"
      class="action-on-dealer"
      custom-class="Add_Users_To_Account_Manager"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form size="large">
          <ValidationProvider
            name="User Name"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item :error="errors[0]">
              <er-select
                :placeholder="$t('Comn_search')"
                :loading="userListLoading"
                class="select-devices"
                v-model="selectedUsers"
                value-key="_id"
                collapse-tags
                multiple
                filterable
                remote
                :remote-method="fetchUsersToAssign"
              >
                <el-option
                  v-for="item in usersList"
                  :key="item._id"
                  :label="
                    item.first_name +
                      '\xa0\xa0' +
                      item.last_name.concat('(' + item.email + ')')
                  "
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>

      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="saveLoading"
          @click="submitUserDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :loading="saveLoading"
          @click="handleCloseDialog"
        ></er-button>
      </template>
    </er-dialog>

    <settings-table
      v-loading="loading"
      element-loading-background="white"
      :tableData="filterTableData"
      :columns="columnsObject"
      :total="total"
      :button="true"
      :key="subuser_id"
      class="add_PondMother"
      placeHolderForSearch="Search_username"
      :columnselector_require="false"
      search-property="full_name"
      :addPadingToSearch="true"
      @loadData="handleLoadData"
      @reload="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddDealerClick"
    >
      <template slot="add-buttton">{{$t('add-user')}}</template>

      <template v-slot:name="{ row }">
        {{ row.data.first_name }} {{ row.data.last_name }}
      </template>

      <template v-slot:actions="{ row }">
        <el-button-group>
          <er-button size="mini" @click="handleUserDelete(row.data)">
            <span class="material-icons-round"> delete </span>
          </er-button>
        </el-button-group>
      </template>
    </settings-table>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
  props: ["subuser_id"],
  data: function() {
    return {
      searchString: '',
      tableData: [],
      selectedUsers: [],
      total: 0,
      usersList: [],
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      dialogVisible: false,
      userListLoading: false,
      loading: false,
      selectedClient: {},
      userSearchQuery: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      }
      // subuser_id: ''
    };
  },
  components: {
    settingsTable
  },
  computed: {
    ...mapGetters("accountManager", {
      getAccountManagerUsers: "getAccountManagerUsers",
      getSkrettingTechniciansUsers: "getSkrettingTechniciansUsers"
    }),
      filterTableData() {
      if (!this.searchString) return this.tableData;
      return this.tableData.filter((data) => {
        return data.full_name.toLowerCase().includes(this.searchString);
      });
    },
    columnsObject: function() {
      return [
        {
          prop: "first_name",
          label: "Name",
          required: true,
          sortable: true
        },
        {
          label: "Email",
          prop: "email",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Phone",
          required: true,
          sortable: true
        },
        {
          prop: "captializeCountryName",
          label: "Country",
          required: true,
          sortable: true
        },
        {
          label: "Actions",
          required: true
        }
      ];
    }
  },
  // created() {
  //   console.log(' this.$route.params.subuser_id', this.$route.params.subuser_id);
  //   this.subuser_id = this.$route.params.subuser_id;
  // },
  methods: {
     ...mapActions("accountManager", {
      fetchAlreadyAssingedUsersFromSubuser: "fetchAlreadyAssingedUsersFromSubuser",
      fetchAllAssingedAccountUsers: "fetchAllAssingedAccountUsers",
      addUsersToSkrettingTechnician: "addUsersToSkrettingTechnician",
      removeAssignedUsersFromSkrettingTechnician:
        "removeAssignedUsersFromSkrettingTechnician"
    }),
    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.userListLoading = true;
        this.userSearchQuery.user_name = queryString;
        this.userSearchQuery.email_not_verified_users_only = false;
        await this.fetchAllAssingedAccountUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        const myArrayFiltered = this.getAccountManagerUsers.filter(
          entry1 => !this.tableData.some(entry2 => entry1._id === entry2._id)
        );
        this.usersList = myArrayFiltered;
      }
    },
    getNewClient() {
      return {
        title: undefined,
        users: []
      };
    },
    async handleUserDelete(removeuserDetails) {
      const payload = {
        account_manager_sub_user_id: this.subuser_id,
        user_ids: [removeuserDetails._id]
      };
      console.log(payload);
      try {
        const confirm_message = removeuserDetails.first_name;
        await this.nofm__deleteConfirmation(confirm_message, "table");
        this.loading = true;
        const response = await this.removeAssignedUsersFromSkrettingTechnician(payload);
        console.log('response', response);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t('technician-user-removed'),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        const errors = [];
        if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });

          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;

        await this.handleLoadData(this.queryParams);
        // this.dealer_Users();
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        console.log('this.subuser_id', this.subuser_id);
        const response = await this.fetchAlreadyAssingedUsersFromSubuser({
          subuser_id: this.subuser_id,
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.getSkrettingTechniciansUsers;
        if (this.tableData?.length > 0) {
          this.tableData.map((item, index) => {
            const { name } = item.country;
            if (name) {
              item.captializeCountryName = name.charAt(0).toUpperCase() + name.slice(1);
            }
            const { first_name, last_name } = item;
            if (first_name && last_name) {
              item.full_name = first_name + ' ' + last_name;
            }
          });
        }
        this.total = response.total;
        console.log('this.total', this.total);
        this.searchString = query?.full_name;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },

    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleOpenDialog(event) {
      this.usersList = [];
      this.selectedUsers = "";
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    async submitUserDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      const payload = {
        account_manager_sub_user_id: this.subuser_id,
        user_ids: this.selectedUsers.map(x => x._id)
      };
      try {
        await this.addUsersToSkrettingTechnician(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t('technician-user-added'),
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.dialogVisible = false;
        await this.handleLoadData(this.queryParams);
      }
    }
  }
};
</script>

<style lang="scss">
.el-dialog__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px !important;
    margin-left: 10px;
    // margin: 0 10px;
    .er-button.el-button--default:not(.is-circle) {
    padding: 2px 15px;
}
.er-button.el-button--default:not(.is-circle) {
    padding: 2px 15px;
}
}
.add-AccountCustomers {
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .el-button {
        padding: 0 12px !important;
  }

  .settings-table .settings-table-scroll {
    position: relative;
    // @include responsiveProperty(height, 75vh, 79vh, 85vh);
    @include responsiveProperty(height, 83vh, 85vh, 88vh);
  }
  .settings-table .padding-10-0 {
    padding: 10px 10px;
  }
}
</style>
