var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"pond-mother-logs"},[_c('el-aside',[_c('portal-target',{attrs:{"name":"outsideAsideScroll"}}),_vm._v(" "),_c('item-list-side-bar',{staticClass:"side-list-menu",attrs:{"item-list":_vm.pmList,"init-item-id":_vm.deviceId,"item-type":"pondmotherlogs","showProperty":"code","search":true,"dataFetchMethod":_vm.loadPmData,"searchStrQueryParam":"device_code","totalItemsCount":_vm.totalItemsCount},on:{"loadData":_vm.loadPmData,"cleared":_vm.loadPmData,"itemClicked":_vm.handlePmSelected}})],1),_vm._v(" "),_c('el-main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white"}},[_c('div',{staticClass:"sub-main"},[_c('basic-customer-details',{attrs:{"device-details":_vm.deviceDetails,"user-details":_vm.customerDetails,"showCustomerDetails":false,"allowAddCustomer":false,"allowCustomerLogin":true}},[_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Total Feed")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v(_vm._s(_vm.ftm__formatEmpty(
                _vm.ftm__digitPrecision(_vm.getTotalFeedAndConsumedFeed.total_feed)
              ) +
                " " +
                _vm.getFeedUnit))])],1),_vm._v(" "),_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Consumed Total")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v(_vm._s(_vm.ftm__formatEmpty(
                _vm.ftm__digitPrecision(_vm.getTotalFeedAndConsumedFeed.consumed_feed)
              ) +
                " " +
                _vm.getFeedUnit))])],1)],1)],2),_vm._v(" \n      "),_c('pm-feed-graphs',{attrs:{"customerDetails":_vm.customerDetails,"pmId":_vm.pm_id,"loading":_vm.loading},on:{"changedDate":_vm.handleDateRangeChange,"downloadZIP":_vm.handleDownloadZIP}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }