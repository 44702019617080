var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{key:_vm.selectedUser._id,staticClass:"st-feed-details"},[_c('el-row',{staticClass:"user-details"},[_c('el-col',{attrs:{"span":17}},[_vm._v("\n      "+_vm._s(_vm.selectedUser.first_name)+" "+_vm._s(_vm.selectedUser.last_name)+"\n    ")]),_vm._v(" "),_c('el-col',{attrs:{"span":3}},[_c('er-single-day-picker',{attrs:{"value":_vm.feedDate,"value-format":"yyyy-MM-dd","format":"dd-MMM-yy"},on:{"change-prev-day":_vm.prevDayClick,"change-next-day":_vm.nextDayClick,"change-day":_vm.handlePresentDateChange}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":4}},[_c('er-select',{attrs:{"size":"mini","value":_vm.selectedLocation,"value-key":"_id"},on:{"input":_vm.handleChangeInLocation}},_vm._l((_vm.listOfAllLocations),function(location,key){return _c('el-option',{key:("location-" + key),attrs:{"value":location,"label":location.name}},[_vm._v(_vm._s(location.name)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('el-row',{key:_vm.feedDate},[_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.$i18n.locale,ref:"shrimpTalkFeedDetails",attrs:{"type":"white-header-table-inner","layout":"table,pagination","element-loading-background":"white","tableData":_vm.shrimpTalkSummary,"columns":_vm.columnsObject,"el-table-props":_vm.firsttableProps,"sortMethodForColumns":_vm.sortMethodForColumns,"expand-column":true,"action-column":false,"unique-id":"shrimp_talk_feed_table","page-size":_vm.$constants.table['page-size']},on:{"expand-change":_vm.handleExpandChange},scopedSlots:_vm._u([{key:"ratio",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.getRatio(row.data.dispensed_feed, row.data.ciba_model_feed))+"\n      ")]}},{key:"feed_limit_(kg)",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.feed, 2)))+"\n      ")]}},{key:"dispensed_feed_(kg)",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.dispensed_feed, 2)))+"\n      ")]}},{key:"abw_(g)",fn:function(ref){
var row = ref.row;
return [_c('p',{style:(("color: " + (_vm.getABWColor(row.data.wg)) + ";"))},[_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(row.data.abw))+"\n        ")])]}},{key:"expandColumn",fn:function(ref){
var row = ref.row;
return [_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.expandloading === row.data._id),expression:"expandloading === row.data._id"}],attrs:{"element-loading-background":"white","tableData":_vm.getInnerRowData(row.data),"columns":_vm.expandedTableColumns,"el-table-props":_vm.tableProps,"merge":{ startColumnNumber: 1, endColumnNumber: 2 },"action-column":false,"unique-id":("shrimp-talk-feed-inner-table-" + (row._id)),"type":"white-header-table-inner","layout":"table"},scopedSlots:_vm._u([{key:"st_v1_code",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"popperClass":"admin-st-feed-details-el-tooltip__popper","effect":"light","content":("Last Communicated: " + (row.data.st_last_communicated_at)),"placement":"top"}},[_c('p',[_vm._v(_vm._s(row.data.st_v1_code))])]),_vm._v(" "),(!row.data.comm_status)?_c('span',{staticClass:"dot-small",style:("background-color: red;")}):_vm._e()],1)]}},{key:"pm_title",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"popperClass":"admin-st-feed-details-el-tooltip__popper","effect":"light","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v("\n                  "+_vm._s(("Last Communicated: " + (row.data.pm_last_communicated_at)))),_c('br'),_vm._v(_vm._s(("Gateway Code: " + (row.data.pm_gateway_code)))+"\n                ")]),_vm._v(" "),(row.data.pm_managed_by === 'SHRIMP_TALK')?_c('p',{style:(("font-weight: " + (row.data.pm_master_id === row.data.pm_id
                        ? '900'
                        : 'normal') + ";"))},[_vm._v("\n                  "+_vm._s(row.data.pm_title)+"\n                ")]):_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("\n                  "+_vm._s(row.data.pm_title))])],1),_vm._v(" "),(!row.data.comm_status)?_c('span',{staticClass:"dot-small",style:("background-color: red;")}):_vm._e()],1)]}},{key:"pm_ratio",fn:function(ref){
                    var row = ref.row;
return [_c('p',{style:(("color: " + (_vm.getRatioColor(row.data.pm_ratio)) + ";"))},[_vm._v("\n              "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.pm_ratio, 2)))+"\n            ")])]}},{key:"st_ratio",fn:function(ref){
                    var row = ref.row;
return [_c('p',{style:(("color: " + (_vm.getRatioColor(row.data.st_ratio)) + ";"))},[_vm._v("\n              "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.st_ratio, 2)))+"\n            ")])]}},{key:"st_df",fn:function(ref){
                    var row = ref.row;
return [_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(
                _vm.ftm__digitPrecision(row.data.st_disp_feed_limit, 0)
              ))+"\n            /\n            "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.st_feed_limit, 0)))+"\n          ")]}},{key:"pm_df",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(
                _vm.ftm__digitPrecision(row.data.pm_disp_feed_limit, 0)
              ))+"\n            /\n            "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.pm_feed_limit, 0)))+"\n          ")]}},{key:"latest_intake",fn:function(ref){
              var row = ref.row;
return [(row.data.latest_intake !== '-')?_c('el-row',[_c('span',{staticClass:"dot",style:(("background-color: " + (_vm.colorCode[row.data.latest_intake].color) + ";"))})]):_c('el-row',[_vm._v("-")])]}},{key:"intake",fn:function(ref){
                  var row = ref.row;
return [(row.data.intake !== '-')?_c('el-row',[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"progress-bar",style:(("\n                background-color: " + (row.data.intake[0].color) + ";\n                height: 20px;\n                width: " + (2.5 * Math.round(row.data.intake[0].percent)) + "px;\n              ")),attrs:{"role":"progressbar","title":"'shrimptalk no intake percent'"}},[_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v("\n                    "+_vm._s(_vm.ftm__formatEmpty(
                        _vm.ftm__digitPrecision(row.data.intake[0].percent, 1)
                      ))+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"progress-bar",style:(("\n                background-color: " + (row.data.intake[1].color) + ";\n                height: 20px;\n                width: " + (2.5 * Math.round(row.data.intake[1].percent)) + "px;\n              ")),attrs:{"role":"progressbar","title":"'shrimptalk poor intake percent'"}},[_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v("\n                    "+_vm._s(_vm.ftm__formatEmpty(
                        _vm.ftm__digitPrecision(row.data.intake[1].percent, 0)
                      ))+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"progress-bar",style:(("\n                background-color: " + (row.data.intake[2].color) + ";\n                height: 20px;\n                width: " + (2.5 * Math.round(row.data.intake[2].percent)) + "px;\n              ")),attrs:{"role":"progressbar","title":"'shrimptalk no intake percent'"}},[_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v("\n                    "+_vm._s(_vm.ftm__formatEmpty(
                        _vm.ftm__digitPrecision(row.data.intake[2].percent, 1)
                      ))+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"progress-bar",style:(("\n                background-color:  " + (row.data.intake[3].color) + ";\n                height: 20px;\n                width: " + (2.5 * Math.round(row.data.intake[3].percent)) + "px;\n              ")),attrs:{"role":"progressbar","title":"'shrimptalk noise percent'"}},[_c('p',{staticStyle:{"font-size":"9px"}},[_vm._v("\n                    "+_vm._s(_vm.ftm__formatEmpty(
                        _vm.ftm__digitPrecision(row.data.intake[3].percent, 1)
                      ))+"\n                  ")])])])]):_c('el-row',[_vm._v("-")])]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }