import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import XLSX from "xlsx";
import notificationMixin from "@/mixins/notificationMixin";
import fitersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
export default function(component) {
  return {
    mixins: [
      errorHandlerMixin,
      errorKeyMapMixin,
      notificationMixin,
      fitersMixin,
      adminDatesHandlerMixin
    ],
    computed: {
      ...mapGetters("accountManager", {})
    },
    methods: {
      ...mapActions("accountManager", {}),
      getStatus(input, device_type) {
        const mapDeviceTypeToStatus = {
          GATEWAY: input => [
            input.status === "ACTIVE" && Boolean(input.user_details),
            input.status === "INACTIVE" || Boolean(input.user_details),
            true
          ],
          POND_GUARD: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ],
          POND_MOTHER: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ],
          SHRIMP_TALK: input => [
            Boolean(input.user_details) && Boolean(input.pond_id),
            Boolean(input.user_details) && input.pond_id === undefined,
            true
          ]
        };
        const status = [
          this.ftm__capitalize(this.$t("dealer.active")),
          this.ftm__capitalize(this.$t("dealer.inactive")),
          this.ftm__capitalize(this.$t("dealer.not_assigned"))
        ];
        return status[mapDeviceTypeToStatus[device_type](input).indexOf(true)];
      },
      handleDownloadExcelMixin(tableData, columnsObject, label) {
        let xlData = [];
        xlData = tableData.reduce((acc, currentObj) => {
          const formatData = {};
          columnsObject.map(item => {
            if (item.prop === "first_name" || item.prop === "email") {
              formatData[this.$t(`${item.label}`)] =
                item.prop === "first_name"
                  ? currentObj.user_details.first_name +
                    " " +
                    currentObj.user_details.last_name
                  : currentObj.user_details[item.prop];
            } else if (item.prop === "status") {
              formatData[this.$t(`${item.label}`)] = this.getStatus(
                currentObj,
                label
              );
            } else if (item.prop === "updated_at") {
              formatData[this.$t(`${item.label}`)] = this.ftm__formatEmpty(
                this.adm__filterToFormatDate(currentObj.updated_at)
              );
            } else {
              formatData[this.$t(`${item.label}`)] = currentObj[item.prop];
            }
          });
          acc.push(formatData);
          return acc;
        }, []);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(xlData);
        XLSX.utils.book_append_sheet(wb, ws);
        const keyMapping = {
          POND_MOTHER: "PondMother.xlsx",
          POND_GUARD: "PondGuard.xlsx",
          SHRIMP_TALK: "ShrimpTalk.xlsx",
          GATEWAY: "Gateway.xlsx"
        };
        XLSX.writeFile(wb, keyMapping[label], {
          type: "binary",
          cellDates: true
        });
      }
    }
  };
}
