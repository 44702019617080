import Vue from "vue";
import { versionCompare } from "@/utils/comparators.js";
export default Vue.extend({
  render(h) {
    return (
      <div style="padding: 4px; width: 200px;">
        <div>
          <el-select
            value={this.selectedFilter}
            on-input={$event => this.handleFilterTypeChanged($event)}
            popper-class="ag-custom-component-popup"
          >
            <el-option value="greater_than" label="Greater than"></el-option>
            <el-option value="less_than" label="Less than"></el-option>
            <el-option value="equals" label="equal"></el-option>
          </el-select>
        </div>
        <div>
          <el-input
            value={this.filterText}
            on-input={$event => this.handleFilterTextChanged($event)}
          ></el-input>
        </div>
        <div>
          <el-button on-click={this.handleApplyFilter}>Apply</el-button>
          <el-button on-click={this.handleClearFilter}>Clear</el-button>
        </div>
      </div>
    );
  },

  data: function() {
    return {
      filterText: null,
      selectedFilter: "greater_than",
      filterMap: {
        greater_than: -1,
        less_than: 1,
        equals: 0
      }
    };
  },
  methods: {
    handleApplyFilter() {
      this.params.filterChangedCallback();
    },
    handleClearFilter() {
      this.filterText = null;
      this.selectedFilter = "greater_than";
      this.params.filterChangedCallback();
    },
    handleFilterTypeChanged(value) {
      this.selectedFilter = value;
    },
    handleFilterTextChanged(value) {
      this.filterText = value;
    },
    doesFilterPass(params) {
      if (!this.filterText) {
        return true;
      }
      return (
        versionCompare(
          this.filterText,
          (params.data.settings || { software_version: "0.0.0" })
            .software_version || "0.0.0"
        ) === this.filterMap[this.selectedFilter]
      );
    },
    isFilterActive() {
      return this.filterText != null && this.filterText !== "";
    }
  }
});
