var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"customers-view",attrs:{"no-aside":true}},[_c('div',[_c('tab-layout',{attrs:{"componentList":_vm.componentList,"showComponent":_vm.tabName,"tab-click-handler":_vm.handleTabClick},on:{"selectedComponent":_vm.handleSelectedComponent}})],1),_vm._v(" "),_c('div',[_c('create-new-customer',{attrs:{"user-type":"CUSTOMER","action":"ADD","visible":_vm.addCustomerDialogVisible},on:{"close":_vm.closeAddCustomerDialog}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.tabName,ref:"erDataTables",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"tableProps":_vm.elTableProps,"total":_vm.total,"button":true,"columns":_vm.columnsFilterByTab,"columnselector_require":true,"selectedColumnHeadersSortable":true,"placeHolderForSearch":"Search_username","search-property":"user_name","addPadingToSearch":true},on:{"cell-click":_vm.handleCellClick,"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddCustomerClick},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s((((row.data || { first_name: "--" }).first_name) + " " + ((row.data || { last_name: "--" }).last_name)))+"\n      ")]}},{key:"login",fn:function(ref){
          var row = ref.row;
return [_c('er-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleLoginAsUser($event, row.data._id)}}},[_vm._v("Login as User")])]}},{key:"prev_day_schedule",fn:function(ref){
          var row = ref.row;
return [_c('el-switch',{attrs:{"value":(row.data.device_config || {}).retain_previous_day_schedule,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"input":function($event){return _vm.handleEnableFeature(
              $event,
              row.data,
              'retain_previous_day_schedule'
            )}}})]}},{key:"ui_edit",fn:function(ref){
            var row = ref.row;
return [_c('el-switch',{attrs:{"value":(row.data.device_config || {}).device_ui_edit,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"input":function($event){return _vm.handleEnableFeature($event, row.data, 'device_ui_edit')}}})]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('el-button-group',[(_vm.tabName === 'unverified')?[_c('er-button',{attrs:{"size":"mini","disabled":row.data.email_verified,"title":"Mark User Email as Verified"},on:{"click":function($event){return _vm.handleActions(row.data, 'CONFIRM_EMAIL')}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v("mark_email_read")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","disabled":row.data.email_verified,"title":"Resend Email to user for verification"},on:{"click":function($event){return _vm.handleActions(row.data, 'RESEND_EMAIL')}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v("reply_all")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","disabled":row.data.email_verified,"title":"Remove User"},on:{"click":function($event){return _vm.handleActions(row.data, 'REMOVE_USER')}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v("person_remove")])])]:[_c('el-button',{attrs:{"size":"mini","disabled":row.data.user_type !== 'ADMIN',"icon":"el-icon-plus"},on:{"click":function($event){return _vm.handleAddDevice(row.data)}}}),_vm._v(" "),_c('el-button',{attrs:{"size":"mini","disabled":row.data.user_type !== 'ADMIN',"icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDeleteDevice(row.data)}}})]],2)]}},{key:"signup_date",fn:function(ref){
            var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.created_at)))+"\n      ")]}},{key:"v1_password_synced",fn:function(ref){
            var row = ref.row;
return [_c('center',[(row.data.v1_password_synced)?_c('span',{staticClass:"material-icons-round",staticStyle:{"color":"green"}},[_vm._v("\n            vpn_key\n          ")]):_c('span',{staticClass:"material-icons-round",staticStyle:{"color":"#bdbdbdc7"}},[_vm._v("\n            vpn_key\n          ")])])]}},{key:"user_type",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v("\n            "+_vm._s(_vm.ftm__userTypeToText(row.data.user_type) || "-")+"\n          ")])]}},{key:"total_devicesNestedColumn",fn:function(){return [_c('el-table-column',{attrs:{"label":"Gateways","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.gateways_count))]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Pondguards","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.pond_guards_count))]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"PondMothers","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.pond_mothers_count))]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"ShrimpTalks","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.shrimp_talks_count))]}}])})]},proxy:true}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add Customer")])],2),_vm._v(" "),_c('div',[_c(_vm.component,{tag:"component",attrs:{"showDialog":_vm.dialogVisible,"action":_vm.action,"customer_details":_vm.selectedCustomer},on:{"close_dialog":_vm.closeDialog}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }