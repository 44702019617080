/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Super Admin
*/
import { $superAdminAxios } from "../config/axios";

export default {
  fetchShrimpTalkSummary(user_id, params) {
    console.log(params);
    return $superAdminAxios.get(
      `admin/${user_id}/ponds/shrimp-talk-feed-summary`,
      { params }
    );
  },
  fetchDeviceLatestCommDetails(pond_id) {
    return $superAdminAxios.get(
      `admin/ponds/${pond_id}/devices-communication-and-gateway-details`
    );
  },
  fetchIndividualFeedsPMandST(pond_id, params) {
    return $superAdminAxios.get(
      `admin/ponds/${pond_id}/individual-feeds-of-pond-mothers-and-shrimp-talks-in-pond`,
      { params }
    );
  },
  fetchSTActiveHours(pond_id, params) {
    return $superAdminAxios.get(
      `admin/ponds/${pond_id}/statistics-active-hours-and-latest-data`,
      { params }
    );
  },
  fetchSTIntakeSummary(shrimpTalkId, params) {
    return $superAdminAxios.get(
      `admin/shrimp-talks/${shrimpTalkId}/in-take-summary`,
      { params }
    );
  },
  fetchPondScheduleSlotFeed(pondId, params) {
    return $superAdminAxios.get(`admin/ponds/${pondId}/slotwise-feed`, {
      params
    });
  },
  fetchPondScheduleHourlyFeed(pondId, params) {
    return $superAdminAxios.get(`admin/ponds/${pondId}/hourly-feed`, {
      params
    });
  }
};
