<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stListIndividualDetailsSA.vue
Description:this file contains the list of shrimptalks basic details of individual shrimptalks and  pond mother code and stDetails and Latest Sensor Values
-->
<template>
  <layout
    v-loading="loading"
    element-loading-background="white"
    class="ss-individual-details-sa"
  >
    &nbsp;
    <template slot="deviceListMenu">
      <item-list-side-bar
        :item-list="ssList"
        :init-item-id="ss_id"
        item-type="shrimpsnap"
        :showProperty="['code', 'v1_code']"
        :property-seperator="(v1, v2) => `${v1} (SS${v2})`"
        :dataFetchMethod="loadSSData"
        search-str-query-param="device_code"
        @loadData="loadSSData"
        @cleared="loadSSData"
        @itemClicked="handleSsSelected"
        :totalItemsCount="totalItemsCount"
      ></item-list-side-bar>
    </template>
    <portal to="outsideMainScroll">
      <basic-customer-details
        :device-details="deviceDetails"
        :user-details="customerDetails"
        :showCustomerDetails="true"
        @change-edit="handleEditChange"
      >
        <template slot="deviceCode.value">
          {{ `SS${deviceDetails.v1_code}` }}
        </template>
        <template slot="moreActions">
          <i
            class="material-icons-round"
            :title="syncStatus.message"
            :style="`color:${syncStatus.color};`"
          >
            sync
          </i>
          <er-button
            v-if="userDevicePermissionCheck"
            btn-type="save"
            size="mini"
            @click="handleRemoveCustomer"
            :disabled="!customerDetails"
          >
            <span class="material-icons-round"> person_remove </span>
          </er-button>
        </template>
      </basic-customer-details>
    </portal>
    <el-scrollbar
      class="ss-details ss-details-scroll"
      viewClass="ss-details-scroll-view"
      wrapClass="ss-details-scroll-wrap"
    >
      <ss-details
        :device-details="deviceDetails"
        :customer-details="customerDetails"
        :isDisabled="isDisabled"
        @refresh="handleSsSelected"
      ></ss-details>
    </el-scrollbar>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import layout from "@/components/superadmin/shared/layout";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
import ssDetails from "@/components/superadmin/shrimp-snap/ssDetails";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  props: ["ss_id"],
  components: {
    layout,
    basicCustomerDetails,
    itemListSideBar,
    ssDetails
  },
  data: function () {
    return {
      data: { ss_code: "" },
      loading: false,
      shrimp_snap_data: {},
      totalItemsCount: 100,
      isDisabled: true
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getShrimpSnaps: "getShrimpSnaps",
      getSSsObj: "getSSsObj",
      getUsersObj: "getUsersObj",
      getPondsObj: "getPondsObj"
    }),
    syncStatus() {
      return this.deviceDetails.settings_changed
        ? { message: "Settings not updated to device", color: "#E31A1C" }
        : { message: "Settings updated to device", color: "#33A02C" };
    },
    deviceDetails() {
      // return this.getStsObj[this.st_id];
      return this.shrimp_snap_data;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: ""
        };
      }
    },
    ssList() {
      return this.getShrimpSnaps;
    },
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userDevicePermissionCheck() {
      return this.allowCustomerTab.includes("ADD/REMOVE_DEVICES_FROM_CUSTOMER");
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      deleteDevicesToUser: "deleteDevicesToUser"
    }),
    handleEditChange(changedEditStatus) {
      this.isDisabled = changedEditStatus;
    },
    async handleRemoveCustomer() {
      try {
        await this.$confirm(
          "Your are going to unassign the user from the device, Are you sure ?",
          "Warning",
          {
            confirmButtonText: "ok",
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [this.ss_id],
          device_type: "SHRIMP_SNAP"
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        await this.initComponent();
      } catch (err) {
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    async initComponent() {
      try {
        // console.log(parameter);
        this.loading = true;
        // { get_all: true }
        await this.fetchAllDeviceDetails({
          device_type: "shrimp_snap",
          infinite_scroll: true,
          params: { page: 1, limit: 19, order_by: "code", order_type: "asc" }
        });
        // await this.fetchAllUsers({ get_all: true });
        await this.callStoreOnSsData(this.ss_id);
        // await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadSSData({ query, infinite_scroll = false }) {
      if (
        query.device_code &&
        query.device_code.toUpperCase().startsWith("SS")
      ) {
        query.device_code = query.device_code.substr(2).trim();
      }
      const response = await this.fetchAllDeviceDetails({
        device_type: "shrimp_snap",
        infinite_scroll: infinite_scroll,
        params: query
      });

      this.totalItemsCount = response.total;
    },
    async callStoreOnSsData(st_id) {
      try {
        this.shrimp_snap_data = await this.fetchADeviceData({
          device_type: "shrimp_snap",
          device_id: st_id
        });
        console.log(this.shrimp_snap_data);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async handleSsSelected(ss_id) {
      try {
        this.loading = true;
        this.$router.replace(ss_id);
        await this.callStoreOnSsData(ss_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.ss-individual-details-sa {
  .ss-details {
    &.ss-details-scroll {
      position: relative;
      height: 81vh;
    }
    .ss-details-scroll-view {
      padding-right: 10px;
    }
    .ss-details-scroll-wrap {
      height: 100%;
      overflow-x: auto;
    }
  }
}
</style>
