<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Solar Disconnected"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { solarDisconnectedGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: solarDisconnectedGraphConfig,
      oxygenData: []
    };
  },
  computed: {
    getLocaleObj: function () {
      return { locale: this.$i18n.locale };
    }
  },

  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgSolarDisconnectedValues");
    this.acm__initParameterName("solar_disconnected");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Solar Disconnected", "line");
    },
  }
};
</script>

<style lang="scss"></style>
