<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="TDS"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { tdsGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function() {
    return {
      chartOptions: tdsGraphConfig
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgTdsValues");
    this.acm__initParameterName("tds");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("TDS", "line");
    }
  }
};
</script>

<style lang="scss"></style>
