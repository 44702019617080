var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"gateway-device-networks",attrs:{"element-loading-background":"white"}},[_c('basic-customer-details',{attrs:{"device-details":_vm.deviceDetails,"user-details":_vm.customerDetails,"showCustomerDetails":false,"allowAddCustomer":false,"allowCustomerLogin":false}},[_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Name")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v("\n          "+_vm._s(this.customerDetails.first_name
              ? this.customerDetails.first_name
              : "--")+"\n          "+_vm._s(this.customerDetails.last_name
              ? this.customerDetails.last_name
              : "--")+"\n        ")])],1)],1),_vm._v(" "),_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Email")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v("\n          "+_vm._s(this.customerDetails.email ? this.customerDetails.email : "--")+"\n        ")])],1)],1),_vm._v(" "),_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Mobile")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v("\n          "+_vm._s(this.customerDetails.phone ? this.customerDetails.phone : "--")+"\n        ")])],1)],1),_vm._v(" "),_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Location")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v("\n          "+_vm._s(this.customerDetails.country
              ? this.customerDetails.country.name
              : "--")+"\n        ")])],1)],1),_vm._v(" "),_c('template',{slot:"moreDetails"},[_c('center',[_c('el-row',{staticClass:"details__heading"},[_vm._v("Latitude - Longitude")]),_vm._v(" "),_c('el-row',{staticClass:"details__heading__value"},[_vm._v("\n          "+_vm._s(typeof this.deviceDetails.settings.latitude !== "undefined"
              ? this.deviceDetails.settings.latitude +
                "-" +
                this.deviceDetails.settings.longitude
              : "--")+"\n        ")])],1)],1)],2),_vm._v(" "),_c('el-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"Sensor Logs","name":"SensorLogs"}},[_c('gateway-voltage',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white"},on:{"changedDateQuery":_vm.handleChangedDateQuery}})],1),_vm._v(" "),_c('el-tab-pane',{attrs:{"label":"Networks","name":"Networks"}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":11}},[_c('small-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableData":_vm.wifi_table_data,"search-on-property":"wifi_network","columns":_vm.wifi_table_columns,"addPadingToSearch":true,"tableName":"Available Networks"}})],1)],1)],1),_vm._v(" "),_c('el-tab-pane',{staticClass:"CommunicatationStats-css",attrs:{"label":"Communicatation Stats","name":"CommunicatationStats"}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":11}},[_c('small-table-server-side',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableName":"PondMother Communication Stats","tableData":_vm.pond_mother_data,"total":_vm.pond_mother_total,"columns":_vm.statsColumn,"addPadingToSearch":true,"search-on-property":"code","device-type":"POND_MOTHER","tableProps":_vm.smallTableServerSideTableProps},on:{"loadData":_vm.getGatewayCommunicationStats,"reloadData":_vm.getGatewayCommunicationStats},scopedSlots:_vm._u([{key:"code",fn:function(ref){
              var row = ref.row;
return [_c('el-link',{on:{"click":function($event){$event.preventDefault();return _vm.handlePmClick($event, row.data.code)}}},[_vm._v(_vm._s(row.data.code))])]}}])})],1),_vm._v(" "),_c('el-col',{attrs:{"span":2}}),_vm._v(" "),_c('el-col',{attrs:{"span":11}},[_c('small-table-server-side',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableName":"PondGuard Communication Stats","tableData":_vm.pond_guard_data,"total":_vm.pond_guard_total,"columns":_vm.statsColumn,"addPadingToSearch":true,"search-on-property":"code","device-type":"POND_GUARD","tableProps":_vm.smallTableServerSideTableProps},on:{"loadData":_vm.getGatewayCommunicationStats,"reloadData":_vm.getGatewayCommunicationStats},scopedSlots:_vm._u([{key:"code",fn:function(ref){
              var row = ref.row;
return [_c('el-link',{on:{"click":function($event){$event.preventDefault();return _vm.handlePgClick($event, row.data.code)}}},[_vm._v(_vm._s(row.data.code))])]}}])})],1)],1),_vm._v(" "),_c('el-divider',{attrs:{"direction":"horizontal"}}),_vm._v(" "),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":11}},[_c('small-table-server-side',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableName":"ShrimpTalk Communication Stats","tableData":_vm.shrimp_talk_data,"total":_vm.shrimp_talk_total,"columns":_vm.statsColumn,"addPadingToSearch":true,"search-on-property":"code","device-type":"SHRIMP_TALK","tableProps":_vm.smallTableServerSideTableProps},on:{"loadData":_vm.getGatewayCommunicationStats,"reloadData":_vm.getGatewayCommunicationStats},scopedSlots:_vm._u([{key:"code",fn:function(ref){
              var row = ref.row;
return [_c('el-link',{on:{"click":function($event){$event.preventDefault();return _vm.handleStClick($event, row.data.code)}}},[_vm._v(_vm._s(row.data.code))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }