<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmTabs.vue
Description: This file contains a tab component that displays pondmother logs and a table  that displays the details of all pondmothers.
-->
<template>
  <layout :no-aside="true" class="pond-mother-table-sa">
    <!-- <portal to="outsideMainScroll"> -->
    <!-- <router-view></router-view> -->
    <!-- <tab-layout
      :componentList="componentList"
      :showComponent="computedComponentName"
      :tab-click-handler="handleTabClick"
      @selectedComponent="handleSelectedComponent"
    >
      <template slot="logs">
        <pond-mother-logs></pond-mother-logs>
      </template>
      <template slot="details">
        <pondMotherTableSA></pondMotherTableSA>
      </template>
      <template slot="filters">
        <pondMotherFilters></pondMotherFilters>
      </template>
    </tab-layout> -->
    <er-top-route-nav type="tabs" :items="computedTabItems"></er-top-route-nav>
    <!-- </simplebar> -->
    <component
      :is="computedComponentName"
      @change-tab="handleChangeTabTo"
      :tabData="componentList[computedComponentName]"
    ></component>
    <!-- </portal> -->
  </layout>
</template>

<script>
import layout from "@/components/superadmin/shared/layout";
import tabLayout from "@/components/superadmin/shared/tabLayout";
import pondMotherLogs from "@/components/superadmin/pond-mother/pondMotherLogs";
import pondMotherDetails from "@/components/superadmin/pond-mother/pondMotherTableSA";
import pondMotherFilters from "@/components/superadmin/pond-mother/pondMotherFilters";
import pmStats from "@/components/superadmin/pond-mother/pmStats";
import { mapActions } from "vuex";
const tabComponents = [
  { name: "pondMotherDetails", label: "PondMother Details" },
  { name: "pondMotherLogs", label: "PondMother Data" },
  { name: "pondMotherFilters", label: "PondMother Filters" },
  { name: "pmStats", label: "PondMother Stats" }
];
export default {
  components: {
    layout,
    pondMotherLogs,
    pondMotherDetails,
    tabLayout,
    pondMotherFilters,
    pmStats
  },
  data: function() {
    return {
      componentName: "details",
      componentList: {
        pondMotherDetails: {
          name: "details",
          label: "PondMother Details"
        },
        pondMotherLogs: {
          name: "logs",
          label: "PondMother Logs"
        },
        pondMotherFilters: {
          name: "filters",
          label: "PondMother Filters"
        },
        pmStats: {
          name: "pmStats",
          label: "PondMother Stats"
        }
      }
    };
  },
  computed: {
    computedTabItems: function() {
      return tabComponents;
    },
    computedComponentName: {
      get() {
        return this.$route.query.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    }
  },
  created() {
    if (typeof this.$route.query.tab === "undefined") {
      this.$router.replace({ path: "pondmothers", query: { tab: "details" } });
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails"
    }),
    handleChangeTabTo: function(tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    },
    handleTabClick(tab) {
      this.clearDeviceDetails({
        device_type: "pond_mother"
      });
      this.$router.replace({
        path: "pondmothers",
        query: { tab: this.componentName }
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
    }
  }
};
</script>

<style lang="scss" scoped>
// .el-menu-item {
//   height: 38px !important;
// }
.el-menu--horizontal {
  // position: absolute;
  z-index: 1;
}
</style>

<!-- <style lang="scss"></style> -->
