<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name:  gatewayVoltage.vue
Description: This file is the chart component that display gateway voltage in superadmin site
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="Lithium Voltage"
      :time-query="false"
      :date-query="true"
      @dateChanged="acm__handleDateQueryChange"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { lithiumBatteryGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard,
  },
  data: function () {
    return {
      chartOptions: lithiumBatteryGraphConfig,
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath(
      "superadmin/getGatewayLithiumVoltageValues"
    );
    this.acm__initParameterName("battery_voltage");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("Battery Voltage", "spline");
    },
  },
};
</script>

<style lang="scss"></style>
