var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-accountmanager-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"account-table-sa",attrs:{"toolbar-layout":"","no-aside":true},on:{"loadData":_vm.handleLoadData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{attrs:{"src":require("@/assets/dealer/peoplegroup.svg"),"atl":"down arrow"}}),_vm._v(" "),_c('p',{staticStyle:{"margin-left":"20px"}},[_vm._v("\n        "+_vm._s(("" + (_vm.$t("skretting-technician"))))+"\n      ")])])]},proxy:true}])},[_vm._v(" "),_c('template',{slot:"table"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"},{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"skretting-technician-container"},[_c('create-new-customer',{attrs:{"user-type":"SKRETTING_TECHNICIAN","model":_vm.formAccountManager,"action":_vm.actionToPerform,"visible":_vm.actionDialogVisible},on:{"close":_vm.closeActionDialog}}),_vm._v(" "),_c('skrettingTechnicianDefaultTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white","tableData":_vm.filterTableData,"columns":_vm.columnsObject,"total":_vm.total,"button":true,"searchable":true,"tableProps":_vm.tableProps,"addPadingToSearch":true,"columnselector_require":false,"placeHolderForSearch":"Search_by_technician_name","search-property":"full_name"},on:{"cell-click":_vm.handleCellClick,"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClientClick},scopedSlots:_vm._u([{key:"technician-name_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"comn_email_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"customer-count_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"phone_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"status_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"comn_actions_header",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.label)))])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.first_name)+" "+_vm._s(row.data.last_name)+"\n        ")]}},{key:"first_name_header",fn:function(ref){
var header = ref.header;
return [_vm._v("\n          "+_vm._s(_vm.$t(header.label))+"\n        ")]}},{key:"email_verified",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.email_verified)+"\n        ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{class:[
              'user-status-tag',
              _vm.getStatus(row.data.status).status === 'ACTIVE' && 'is-active'
            ]},[_c('p',[_vm._v(_vm._s(_vm.getStatus(row.data.status).lang))])])]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEditClientClick(row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" edit ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleInactiveClientClick(row.data)}}},[_c('span',{staticClass:"material-icons-minimize"},[_c('i',{staticClass:"el-icon-minus"})])])],1)]}}])},[_c('template',{slot:"add-buttton"},[_vm._v(_vm._s(_vm.$t("add-technician")))])],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }