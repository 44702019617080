var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-table",class:{ 'padding-10-0': _vm.addPadingToSearch }},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('h3',{staticClass:"small-table-title"},[_vm._v(_vm._s(_vm.tableName))])]),_vm._v(" "),_c('el-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"size":"mini","placeholder":("Search by " + (_vm.searchOnProperty.replace('_', ' ')))},model:{value:(_vm.filters[0].value),callback:function ($$v) {_vm.$set(_vm.filters[0], "value", $$v)},expression:"filters[0].value"}})],1)],1),_vm._v(" "),_c('el-row',{staticClass:"padding-10-0"},[_c('data-tables',{attrs:{"data":_vm.tableData,"tableProps":_vm.computedTableProps,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['small-table-pagination-props'],"filters":_vm.filters},on:{"sort-change":_vm.handleSortChange}},[_vm._l((_vm.columns),function(column,index){return [(column.required)?_c('el-table-column',{key:index,attrs:{"label":column.label,"prop":column.prop,"min-width":column.minWidth,"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t(column.label
                  .toLowerCase()
                  .split(' ')
                  .join('_'),[(_vm.getRowValue(scope.row, column).type === 'array')?_vm._l((_vm.getRowValue(scope.row, column).value),function(val,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(val))])}):(column.prop === 'utc_last_communicated_at')?[(
                    _vm.getStatusBasedOnDate(
                      scope.row.utc_last_communicated_at,
                      column.device_type
                    )
                  )?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v(_vm._s(_vm.getRowValue(scope.row, column).value))]):[_vm._v(_vm._s(_vm.getRowValue(scope.row, column).value))]]:[_vm._v(_vm._s(_vm.getRowValue(scope.row, column).value))]],{"row":{ data: scope.row, path: column.prop }})]}}],null,true)},[_vm._v(" "),_vm._t(((column.label
                .toLowerCase()
                .split(' ')
                .join('_')) + "NestedColumn"))],2):_vm._e()]}),_vm._v(" "),_vm._t("actionColumn")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }