var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-table-server-side",class:{ 'padding-10-0': _vm.addPadingToSearch }},[_c('el-row',{attrs:{"type":"flex","gutter":12}},[_c('el-col',[_c('h3',{staticClass:"small-table-title"},[_vm._v(_vm._s(_vm.tableName))])]),_vm._v(" "),_c('el-col',{attrs:{"span":3}},[_c('er-button',{staticStyle:{"padding":"6px 7px","font-weight":"700"},attrs:{"size":"mini","icon":"el-icon-refresh","type":"primary","square":""},on:{"click":_vm.handleReloadData}})],1)],1),_vm._v(" "),_c('el-row',{staticClass:"padding-10-0"},[_c('data-tables-server',{attrs:{"data":_vm.tableData,"tableProps":_vm.computedTableProps,"page-size":_vm.$constants.table['page-size-5'],"pagination-props":_vm.$constants.table['small-table-pagination-props'],"filters":_vm.filters,"total":_vm.total},on:{"query-change":_vm.onQueryChange}},[_vm._l((_vm.columns),function(column,index){return [_c('el-table-column',{key:index,attrs:{"label":column.label,"prop":column.prop,"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t(column.label
                  .toLowerCase()
                  .split(' ')
                  .join('_'),[(_vm.getRowValue(scope.row, column).type === 'array')?_vm._l((_vm.getRowValue(scope.row, column).value),function(val,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(val))])}):(column.prop === 'utc_last_communicated_at')?[(
                    _vm.getStatusBasedOnDate(
                      scope.row.utc_last_communicated_at,
                      _vm.deviceType
                    )
                  )?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v(_vm._s(_vm.getRowValue(scope.row, column).value))]):[_vm._v("\n                  "+_vm._s(_vm.getRowValue(scope.row, column).value)+"\n                ")]]:[_vm._v("\n                "+_vm._s(_vm.getRowValue(scope.row, column).value)+"\n              ")]],{"row":{ data: scope.row, path: column.prop }})]}}],null,true)},[_vm._v(" "),_vm._t(((column.label
                .toLowerCase()
                .split(' ')
                .join('_')) + "NestedColumn"))],2)]}),_vm._v(" "),_vm._t("actionColumn")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }