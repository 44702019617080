<template>
  <div>
    <div class="date-container">
      <el-date-picker
        v-model="dateRangePicked"
        type="daterange"
        format="dd-MMM-yy"
        value-format="dd-MM-yyyy"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        size="mini"
        @change="handleChangeDate"
      ></el-date-picker>
    </div>
    <settings-table
      ref="erDataTables"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :tableProps="elTableProps"
      :addPadingToSearch="true"
      :total="total"
      :button="false"
      :columnselector_require="false"
      :selectedColumnHeadersSortable="true"
      searchProperty="pond_mother_code"
      placeHolderForSearch="Search_pond_mother_code"
      @reload="handleLoadData"
      @loadData="handleLoadData"
      @cleared="handleLoadData"
    >
      <template v-slot:pond_mother_code="{ row }">
        {{ row.data.pond_mother_code }}
      </template>
      <template v-slot:utc_added_date="{ row }">
        {{ row.data.utc_added_date_user_local }}
      </template>
      <template v-slot:utc_removed_date="{ row }">
        {{ row.data.utc_removed_date_user_local }}
      </template>
      <template v-slot:utc_added_date_ist="{ row }">
        {{ row.data.utc_added_date_ist }}
      </template>
      <template v-slot:utc_removed_date_ist="{ row }">
        {{ row.data.utc_removed_date_ist }}
      </template>
    </settings-table>
  </div>
</template>

<script>
import { objectIdGenerator } from "@/utils/commonUtils";
import settingsTable from "@/components/base/settingsTable";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";

import dateUtils from "@/utils/dateUtils";
import filtersMixin from "@/mixins/filtersMixin";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@/components/shared/eventBus";
const timeIDs = [];
for (let i = 0; i < 10; i++) {
  timeIDs.push(objectIdGenerator());
}

export default {
  props: {
    timeQuery: { default: true },
    dateQuery: { default: false },
    pmId: String,
    customerDetails: Object
  },
  components: {
    settingsTable
  },
  data: function() {
    return {
      selectedTime: { num: 1, units: "days", id: timeIDs[6] },
      dateRangePicked: ["", ""],
      loading: false,
      tableData: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null
      },
      total: 0
    };
  },
  mixins: [filtersMixin, adminDatesHandlerMixin],
  created() {
    eventBus.$on("pm-item-clicked", () => {
      this.dateRangePicked = [
        dateUtils.formatDate(
          dateUtils.subtract(new Date(), { weeks: 1 }),
          "dd-MM-yyyy"
        ),
        dateUtils.formatDate(new Date(), "dd-MM-yyyy")
      ];
      this.params.from_date = this.getReverseDateString(
        this.dateRangePicked[0]
      );
      this.params.to_date = this.getReverseDateString(this.dateRangePicked[1]);
      setTimeout(() => {
        this.handleLoadData(this.queryParams);
      }, 1000);
    });
    this.dateRangePicked = [
      dateUtils.formatDate(
        dateUtils.subtract(new Date(), { weeks: 1 }),
        "dd-MM-yyyy"
      ),
      dateUtils.formatDate(new Date(), "dd-MM-yyyy")
    ];
    console.log("this.dateRangePicked", this.dateRangePicked);
    this.params.from_date = this.getReverseDateString(this.dateRangePicked[0]);
    this.params.to_date = this.getReverseDateString(this.dateRangePicked[1]);
  },
  mounted() {
    console.log("getShrimpTalks", this.getShrimpTalks);
    setTimeout(() => {
      this.handleLoadData(this.queryParams);
    }, 2000);
  },
  computed: {
    columnsObject: function() {
      return [
        {
          prop: "shrimp_talk_code",
          label: "ShrimpTalk Code",
          required: true,
          sortable: true,
          minWidth: 150,
          fixed: "fixed"
        },
        {
          prop: "utc_added_date_user_local",
          label: "Start Date",
          required: true,
          minWidth: 120
        },
        {
          prop: "utc_removed_date_user_local",
          label: "End Date",
          required: true,
          minWidth: 200
        },
        {
          prop: "utc_added_date_ist",
          label: "Start Date (IST)",
          required: true,
          minWidth: 120
        },
        {
          prop: "utc_removed_date_ist",
          label: "End Date (IST)",
          required: true,
          minWidth: 200
        }
      ];
    },
    ...mapGetters("superadmin", {
      getPmId: "getPmId",
      getShrimpTalks: "getShrimpTalks"
    }),
    ...mapGetters("user", {
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchPmAndStLogs: "fetchPmAndStLogs"
    }),
    getReverseDateString(date) {
      return date
        .split("-")
        .reverse()
        .join("-");
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    handleChangeTime(changedTimeValue) {
      console.log(changedTimeValue);
      this.$emit("timeChanged", changedTimeValue);
    },
    handleChangeDate(changedDateValue) {
      console.log("this.getPmId", this.getPmId);
      this.params.from_date = this.getReverseDateString(changedDateValue[0]);
      this.params.to_date = this.getReverseDateString(changedDateValue[1]);
      console.log("changedDateValue", changedDateValue, this.params);
      this.$emit("dateChanged", changedDateValue);
      this.handleLoadData(this.queryParams);
    },
    async handleLoadData(query) {
      console.log("query", query);
      try {
        this.loading = true;
        this.queryParams = query;
        // this.queryData = query;
        // query.order_by = "code";
        // query.order_type = "asc";
        // const response = await this.fetchAllDeviceDetails({
        //   device_type: "pond_mother",
        //   infinite_scroll: false,
        //   params: query
        // });
        setTimeout(async () => {
          console.log("this.getPmId", this.getPmId);
          if (this.getPmId) {
            try {
              console.log("this.params-", this.params, new Date());
              // this.params.from_date = dateUtils.formatDate(
              //   dateUtils.parse(
              //     this.params.from_date,
              //     "dd-MM-yyyy",
              //     new Date()
              //   ),
              //   "yyyy-MM-dd"
              // );
              // this.params.to_date = dateUtils.formatDate(
              //   dateUtils.parse(this.params.to_date, "dd-MM-yyyy", new Date()),
              //   "yyyy-MM-dd"
              // );
              // this.params.from_date = dateUtils.formatDate(
              //     dateUtils.zonedTimeToUtc(this.params.from_date),
              //     "dd-MM-yyyy",
              //     new Date()
              // );
              // this.params.to_date = dateUtils.formatDate(
              //  dateUtils.zonedTimeToUtc(this.params.to_date), "dd-MM-yyyy", new Date()
              // );
              // const fromDate = this.params.from_date + "T00:00:00.000Z";
              // const toDate = this.params.to_date + "T23:59:59.999Z";
              const response = await this.fetchPmAndStLogs({
                pmId: this.getPmId,
                from_date: this.params.from_date,
                // from_date: this.dhm__castUserUTCToUTCDateObj(new Date(this.params.from_date)),
                // to_date: this.dhm__castUserUTCToUTCDateObj(new Date(this.params.to_date))
                to_date: this.params.to_date,
                queryParams: this.queryParams
              });
              console.log("customerDetails", this.customerDetails);
              const IstTimeZone = "Asia/Kolkata";
              response.data.map((item) => {
                // if (item?.utc_added_date) {
                //   item.utc_added_date_ist = this.ftm__formatEmpty(
                //     this.adm__filterToFormatDate(item.utc_added_date)
                //   );
                //   item.utc_added_date = this.ftm__formatEmpty(
                //     this.adm__formatByTZOnUTCWithLocale(
                //       new Date(item.utc_added_date),
                //       "dd-MMM-yy HH:mm",
                //       this.getUserTimeZoneString
                //     )
                //   );
                // }
                // if (item?.utc_removed_date) {
                //   item.utc_removed_date_ist = this.ftm__formatEmpty(
                //     this.adm__filterToFormatDate(item.utc_removed_date)
                //   );
                //   item.utc_removed_date = this.ftm__formatEmpty(
                //     this.adm__formatByTZOnUTCWithLocale(
                //       new Date(item.utc_removed_date),
                //       "dd-MMM-yy HH:mm",
                //       this.getUserTimeZoneString
                //     )
                //   );
                // }
                if (item?.utc_added_date) {
                  item.utc_added_date_user_local = this.ftm__formatEmpty(
                    this.adm__formatByTZOnDate(
                      new Date(item.utc_added_date),
                      "dd-MMM-yy HH:mm",
                      this.customerDetails.timezone.name
                    )
                  );
                  item.utc_added_date_ist = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      item.utc_added_date,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );
                }
                if (item?.utc_removed_date) {
                  item.utc_removed_date_user_local = this.ftm__formatEmpty(
                    this.adm__formatByTZOnDate(
                      new Date(item.utc_removed_date),
                      "dd-MMM-yy HH:mm",
                      this.customerDetails.timezone.name
                    )
                  );
                  item.utc_removed_date_ist = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      item.utc_removed_date,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );
                }
              });
              this.tableData =
                response?.data !== undefined ? response.data : [];
              this.total = response.data?.length;
              console.log(response.data);
              this.loading = false;
            } catch (err) {
              console.log("st and pm logs", err);
            }
          }
        }, 2000);
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        // this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.date-container {
  position: relative;
  .el-input__inner {
    width: 271px !important;
  }
  .el-date-editor {
    position: absolute;
    top: 10px;
    z-index: 1;
  }
}
.el-table__fixed::before {
  background-color: #fff !important;
}
</style>
