<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmSuperAdminHourlyFeedGraph.vue
Description: This file is the chart component that displays the feed dispensed by the pondmother for each hour. The different pondmothers are represented with different color line graphs. Here x axis is time and y axis represents the feed dispensed value.
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="PondMother Hourly Feed Graph"
      :date-query="true"
      :time-query="false"
      @dateChanged="acm__handleDateQueryChange"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { pondHourlyGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: { ...pondHourlyGraphConfig }
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPmHourlyFeedData");
    this.acm__initParameterName("hourly_feed");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("PM Feed Schedule Graph", "line");
    },
    acm__initChartSeries(seriesName, chartType) {
      this.chartOptions.series = [
        {
          name: "Feed",
          data: this.acm__getStoreData.hourlyFeed,
          type: "column",
          visible: true,
          pointWidth: 10
        },
        {
          name: "Dispensed Feed",
          data: this.acm__getStoreData.dispensedHourlyFeed,
          type: "column",
          visible: true,
          pointWidth: 10
        }
      ];
    }
  }
};
</script>

<style lang="scss"></style>
