<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AddAccountCustomers.vue
Description:This file contains the form  where the details of the customers  filled and associated with account manager  by superadmin
-->
<template>
    <div class="add-AccountCustomers">
      <er-dialog
        width="30%"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :append-to-body="true"
        @open="handleOpenDialog"
        :title="dailogTitle"
        class="action-on-dealer"
        custom-class="Add_Users_To_Account_Manager"
      >
        <ValidationObserver ref="dialogListForm">
          <el-form size="large">
            <ValidationProvider
            v-if="dailogTitle === 'Replace Executive'"
              name="Exixsting Sales Executive"
            >
            <el-form-item>
              <er-input disabled :value=" existingExecutive.first_name +
                        '\xa0\xa0' +
                        existingExecutive.last_name + '(' + existingExecutive.email + ')'">
              </er-input>
            </el-form-item>
            </ValidationProvider>
            <ValidationProvider
              v-if="dailogTitle === 'Replace Executive'"
              name="User Name"
              rules="required"
              v-slot="{ errors }"
            >
              <el-form-item :error="errors[0]">
                <er-select
                  :placeholder="$t('Comn_search')"
                  :loading="userListLoading"
                  class="select-devices"
                  v-model="selectedExecutive"
                  value-key="_id"
                  collapse-tags
                  filterable
                  remote
                  :remote-method="fetchUsersToAssign"
                >
                  <el-option
                    v-for="item in usersList"
                    :key="item._id"
                    :label="
                      item.first_name +
                        '\xa0\xa0' +
                        item.last_name.concat('(' + item.email + ')')
                    "
                    :value="item"
                  ></el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
            <ValidationProvider
              v-else
              name="User Name"
              rules="required"
              v-slot="{ errors }"
            >
              <el-form-item :error="errors[0]">
                <er-select
                  :placeholder="$t('Comn_search')"
                  :loading="userListLoading"
                  class="select-devices"
                  v-model="selectedUsers"
                  value-key="_id"
                  collapse-tags
                  multiple
                  filterable
                  remote
                  :remote-method="fetchUsersToAssign"
                >
                  <el-option
                    v-for="item in usersList"
                    :key="item._id"
                    :label="
                      item.first_name +
                        '\xa0\xa0' +
                        item.last_name.concat('(' + item.email + ')')
                    "
                    :value="item"
                  ></el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
          </el-form>
        </ValidationObserver>
        <template slot="footer">
          <er-button
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            :loading="saveLoading"
            @click="submitUserDetails"
          ></er-button>
          <er-button
            btnType="cancel"
            :showLabel="true"
            :loading="saveLoading"
            @click="handleCloseDialog"
          ></er-button>
        </template>
      </er-dialog>
      <settings-table
        v-loading="loading"
        element-loading-background="white"
        :tableData="tableData"
        :columns="columnsObject"
        :total="total"
        :button="true"
        :key="salesmanager_id"
        class="add_PondMother"
        placeHolderForSearch="Search_username"
        :columnselector_require="false"
        search-property="user_name"
        :addPadingToSearch="true"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
        @add-item-click="handleAddDealerClick"
      >
        <template slot="add-buttton">Add Executive</template>
        <template v-slot:name="{ row }">
          {{ row.data.first_name }} {{ row.data.last_name }}
        </template>
        <template v-slot:status="{ row }">
          {{ row.data.status === 'ACTIVE' ? "Active" : "Inactive" }}
        </template>
        <template v-slot:actions="{ row }">
          <el-button-group>
            <er-button size="mini" @click="handleUserDelete(row.data)">
              <span class="material-icons-round"> delete </span>
            </er-button>
            <er-button size="mini" icon="el-icon-refresh" @click="handleReplaceExecutiveClick(row.data)">
              <!-- <span> Replace </span> -->
            </er-button>
          </el-button-group>
        </template>
      </settings-table>
    </div>
  </template>
  <script>
  import errorHandlerMixin from "@/mixins/errorHandlerMixin";
  import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
  import settingsTable from "@/components/base/settingsTable";
  import { mapActions, mapGetters } from "vuex";
  import notificationMixin from "@/mixins/notificationMixin";
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "{_field_} is required"
  });
  export default {
    mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
    props: ["salesmanager_id"],
    data: function() {
      return {
        tableData: [],
        skrtTableData: [],
        selectedUsers: [],
        selectedExecutive: '',
        selectedSkrtUsers: [],
        total: 0,
        skrtTotal: 0,
        usersList: [],
        skrtUsersList: [],
        clientActionDialogTitle: "Add",
        dialogBoxLoading: false,
        saveLoading: false,
        dialogVisible: false,
        dailogTitle: 'Add Executive',
        userListLoading: false,
        loading: false,
        selectedClient: {},
        userSearchQuery: {},
        action: "ADD_CLIENT",
        queryParams: {},
        userQuery: {
          include: ["first_name", "last_name"],
          un_assigned_only: true
        },
        actionToPerform: {
          default: ""
        },
      };
    },
    components: {
      settingsTable
    },
    computed: {
      ...mapGetters("superadmin/salesManager", {
        getSalesExecutiveUserData: "getSalesExecutiveUserData",
      }),
      columnsObject: function() {
        return [
          {
            label: "Email",
            prop: "email",
            required: true,
            sortable: true
          },
          {
            prop: "first_name",
            label: "Name",
            required: true,
            sortable: true
          },
          {
            prop: "phone",
            label: "Phone",
            required: true,
            sortable: true
          },
          {
            prop: "country.name",
            label: "Country",
            required: true,
            sortable: true
          },
          {
            prop: "status",
            label: "Status",
            required: true,
            sortable: true
          },
          {
            label: "Actions",
            required: true
          }
        ];
      },
    },
    methods: {
      ...mapActions("superadmin/salesManager", {
        fetchAlreadyAssingedExecutiveUsers: "fetchAlreadyAssingedExecutiveUsers",
        // fetchAllUsers: "fetchAllUsers",
        fetchAllSalesExecutiveUsers: "fetchAllSalesExecutiveUsers",
        addExicutiveToSalesManger: "addExicutiveToSalesManger",
        removeAssignedExecutivesFromSalesManager:
          "removeAssignedExecutivesFromSalesManager",
          replaceSalesExicutive: "replaceSalesExicutive"
      }),
      async fetchUsersToAssign(queryString) {
        if (queryString === "") return;
        try {
          this.userListLoading = true;
          this.userSearchQuery.user_name = queryString;
          this.userSearchQuery.status = 'ACTIVE';
          this.userSearchQuery.email_not_verified_users_only = false;
          await this.fetchAllSalesExecutiveUsers({
            infinite_scroll: false,
            params: this.userSearchQuery
          });
          if (this.dailogTitle !== 'Replace Executive') {
              const myArrayFiltered = this.getSalesExecutiveUserData.filter(
                entry1 => !this.tableData.some(entry2 => entry1._id === entry2._id) && entry1.allowed_account_managers.length === 0
              );
            this.usersList = myArrayFiltered;
          } else {
            this.usersList = this.getSalesExecutiveUserData.filter(user => user._id !== this.existingExecutive._id);
          }
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.userListLoading = false;
        }
      },
      handleReplaceExecutiveClick(data) {
        this.dialogVisible = true;
        this.existingExecutive = data
        this.selectedExecutive = '';
        this.dailogTitle = "Replace Executive"
      },
      getNewClient() {
        return {
          title: undefined,
          users: []
        };
      },
      async handleUserDelete(removeuserDetails) {
        try {
          let message = '';
          const payload = {
            account_manager_id: this.salesmanager_id,
            user_ids: [removeuserDetails._id]
          };
          const confirm_message = removeuserDetails.first_name;
          await this.nofm__deleteConfirmation(confirm_message, "table");
          message = "Executive deleted Successfully";
          this.loading = true;
          await this.removeAssignedExecutivesFromSalesManager(payload);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: message,
            duration: 5000,
            type: "success"
          });
        } catch (err) {
          const errors = [];
          if (err.response && err.response.data.message) {
            errors.push({ message: err.response.data.message });

            this.ehm__errorFailToSave(errors);
          } else if (err.response && err.response.data.errors != null) {
            err.response.data.errors.details.forEach((el, index) => {
              errors.push({ message: el.message });
            });
            this.ehm__errorFailToSave(errors);
          } else {
            this.ehm__errorMessages(err, true);
          }
        } finally {
          this.loading = false;
          await this.handleLoadData(this.queryParams);
          // this.dealer_Users();
        }
      },
      async handleLoadData(query) {
        try {
          this.loading = true;
          this.queryParams = query;
          if (this.salesmanager_id !== undefined) {
            const response = await this.fetchAlreadyAssingedExecutiveUsers({
              salesmanager_id: this.salesmanager_id,
              infinite_scroll: false,
              params: query
            });
            this.tableData = response.users;
            this.total = response.total;
          }
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },

      handleAddDealerClick() {
        this.dailogTitle = 'Add Executive'
        this.dialogVisible = true;
        this.selectedUsers = [];
      },
      handleCloseDialog() {
        this.dialogVisible = false;
      },
      handleOpenDialog(event) {
        this.usersList = [];
        this.skrtUsersList = [];
        this.selectedUsers = [];
        this.selectedSkrtUsers = [];
        this.$nextTick(() => {
          this.$refs.dialogListForm.reset();
        });
      },
      async submitUserDetails() {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        try {
          this.saveLoading = true;
          if (this.dailogTitle !== 'Replace Executive') {
            const payload = {
              account_manager_id: this.salesmanager_id,
              user_ids: this.selectedUsers.map(x => x._id)
            };
            await this.addExicutiveToSalesManger(payload);
          } else {
            const payload = {
              existing_user_id: this.existingExecutive._id,
              replaced_user_id: this.selectedExecutive._id
            };
            await this.replaceSalesExicutive(payload);
          }
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: "Added Successfully",
            duration: 5000,
            type: "success"
          });
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.dialogVisible = false;
          this.saveLoading = false;
          this.selectedUsers = [];
          await this.handleLoadData(this.queryParams);
        }
      }
    }
  };
  </script>

  <style lang="scss">
  .add-AccountCustomers {
    .client-table-sa {
      .material-icons-round {
        font-size: 14px;
      }
    }
    .el-table__body-wrapper {
      @include responsiveProperty(height, 400px, 500px, 600px);
      overflow-y: scroll;
    }
    .settings-table .settings-table-scroll {
      position: relative;
      // @include responsiveProperty(height, 75vh, 79vh, 85vh);
      @include responsiveProperty(height, 83vh, 85vh, 88vh);
    }
    .settings-table .padding-10-0 {
      padding: 10px 10px;
    }
    .el-icon-refresh {
      font-size: 15px;
      font-weight: 600;
    }
  }
  .Add_Users_To_Account_Manager {
    border-radius: 10px;
  }
  </style>
