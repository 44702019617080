<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgPHGraphGraph
Description:This file contains the pondguard ph level of water graphs built using highcharts
-->
<template>
  <div>
    <chart-in-card
      :updateChart="acm__updateChart"
      :chartOptions="chartOptions"
      chartTitle="pH"
      :dateQuery="false"
      :timeQuery="false"
    ></chart-in-card>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import chartInCard from "@/components/superadmin/shared/chartInCard";
import { pHGraphConfig } from "./chartOptions";
export default {
  mixins: [errorHandlerMixin, adminChartsMixin],
  components: {
    chartInCard
  },
  data: function () {
    return {
      chartOptions: pHGraphConfig
    };
  },
  mounted() {
    this.acm__initStoreDataGetterPath("superadmin/getPgPHValues");
    this.acm__initParameterName("ph");
    this.acm__initComponent();
  },
  methods: {
    acm__initComponent() {
      this.acm__initChartSeries("pH", "line");
    }
  }
};
</script>

<style lang="scss"></style>
